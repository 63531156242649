import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Constant } from '../../config';

import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StudentService {

  constructor(private httpClient: HttpClient) { }


  getprofile(profileId){
    return this.httpClient.get(Constant.API_ORIGIN_URL + '/student/viewProfile/' + profileId)
  }
  updateProfile(prof:any){
    return this.httpClient.post(Constant.API_ORIGIN_URL+'/student/editProfile/',prof)
  }

  // <----  Dashboard APIs ---->

  dashboardExternalResults(userId){    
    return this.httpClient.get( Constant.API_ORIGIN_URL+ '/parent/externalResultsSemesterWise/' + userId)
  }

  dashboardInternalResults(userId){    
    return this.httpClient.get( Constant.API_ORIGIN_URL+ '/student/viewInternalResults/' + userId)
  }

  feedbackGivenToStudent(userId){
    return this.httpClient.get( Constant.API_ORIGIN_URL+ '/student/getAverageFeedBackToStudent/' + userId)
  }
  
  //academics API

  //getsubjects API
  getAcademicSubjects(id) {
    return this.httpClient.get(Constant.API_ORIGIN_URL+ '/student/viewSubjectsForStudent/' +id);
  }

  //viewsyllabus API
  viewSyllabus(subject) {
    return this.httpClient.get(Constant.API_ORIGIN_URL+ '/student/viewSyllabus/' + subject);
  }

  //viewLectureNotes API
  lectureNotes(subjectName) {
    return this.httpClient.get(Constant.API_ORIGIN_URL+ '/student/viewLectureNotesByStudent/' + subjectName);
  }

  //viewNotifications API
  viewNotify(id) {
    return this.httpClient.get(Constant.API_ORIGIN_URL+ '/student/viewNotificationsForStudent/' + id);
  }



  //library APIs

  //getBooks API
  getBooks(id){
    return this.httpClient.get(Constant.API_ORIGIN_URL+ '/library/getBooksByBusinessId/' + id);
  }

  //student books API
  bookStatus(id){
    return this.httpClient.get(Constant.API_ORIGIN_URL+ '/student/bookStatus/' + id);
  }


  //  Feedback APIs 

  //View Feedback Details
  // getProfessorfeedbackDetails(data) {
  //   return this.httpClient.post(Constant.API_ORIGIN_URL+ '/student/viewFeedback', data)
  // }

  getProfessorfeedbackDetails(id) {
    return this.httpClient.get(Constant.API_ORIGIN_URL+ '/student/viewFeedback/' + id)
  }

  professorlst(id){
    return this.httpClient.get(Constant.API_ORIGIN_URL+ '/student/viewProfessorsBySemesterWise/'+ id)
  }
  // professorlst(data){
  //   return this.httpClient.post(Constant.API_ORIGIN_URL+ '/student/viewProfessorsBySemesterWise', data)
  // }


  // Add Feedback
  addFeedback(data){
    return this.httpClient.post(Constant.API_ORIGIN_URL+ '/student/submitFeedback', data)
  }

  // View Transport Feedback
  transportFeedback(id){
    return this.httpClient.get(Constant.API_ORIGIN_URL+ '/bus/viewTransportFeedBack/' + id)
  }
  // Add Transport Feedback
  addTransportFdbk(data){
    return this.httpClient.post(Constant.API_ORIGIN_URL+ '/bus/addTransportFeedBack', data)
  }

  // Add Hostel Feedback 
  addHostelFdbk(data){
    return this.httpClient.post(Constant.API_ORIGIN_URL+ '/hostel/submitHostelFeedback', data)
  }

  // View Hostel Feedback
  hostelFeedback(id){
    return this.httpClient.get(Constant.API_ORIGIN_URL+ '/hostel/viewHostelFeedback/' + id)
  }

  // Result

  //Internal Results

  semisterWise(id){
    return this.httpClient.get(Constant.API_ORIGIN_URL+ '/parent/viewSemeterWiseResults/' +id);
     }

  internals(exams){
    return this.httpClient.post(Constant.API_ORIGIN_URL+ '/parent/viewInternalResultsForParent' , exams)
  }

  externals(exam){
    return this.httpClient.post(Constant.API_ORIGIN_URL+ '/parent/viewExternalResultsForParent' ,exam)
  }



  // Email

  // Compose
  sendMail(mail){
    return this.httpClient.post(Constant.API_ORIGIN_URL+ '/email/compose', mail)
  }

  // sent
  mailsSent(id){
    return this.httpClient.get(Constant.API_ORIGIN_URL+ '/email/mails/' + id)
  }

  // inbox
  mailsReceive(id){
    return this.httpClient.get(Constant.API_ORIGIN_URL+ '/email/mails/' + id)
  }
  
  // delete mail
  mailsDelete(id){
    return this.httpClient.delete(Constant.API_ORIGIN_URL+ '/email/deleteMail/' + id)
  }

  // Attendance

  // Gate attendance

  monthAttnd(month){
    return this.httpClient.post(Constant.API_ORIGIN_URL+ '/attendance/getStudentMonthlyGateAttendance', month)
  }
  weekAttnd(week){
    return this.httpClient.post(Constant.API_ORIGIN_URL+ '/attendance/getStudentWeeeklyGateAttendance', week)
  }
  dateAttnd(day){
    return this.httpClient.post(Constant.API_ORIGIN_URL+ '/attendance/getStudentDayGateAttendance', day)
  }

  // Class attendance
  semList(studId){
    return this.httpClient.get(Constant.API_ORIGIN_URL+ '/attendance/getStudentSemDetails/' + studId)
  }
  subList(data){
    return this.httpClient.post(Constant.API_ORIGIN_URL+ '/attendance/getSemesterSubjects', data)
  }
  classroomMonthlyAttd(data){
    return this.httpClient.post(Constant.API_ORIGIN_URL+ '/attendance/getStudentMonthlyClassRoomAttendance', data)
  }
  classroomWeeklyAttd(data){
    return this.httpClient.post(Constant.API_ORIGIN_URL+ '/attendance/getStudentWeeeklyClassRoomAttendance', data)
  }


  viewHostelList(){
    return this.httpClient.get(Constant.API_ORIGIN_URL+ '/hostel/viewHostelBuildings')
  }


}
