import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HostelService } from '../hostel.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-student-details',
  templateUrl: './student-details.component.html',
  styleUrls: ['./student-details.component.scss']
})
export class StudentDetailsComponent implements OnInit {

  constructor(private router:Router, private hService:HostelService) { }

  search:any;
  
  viewStudentDtl=true;
  add=false;

  addDetails(){
    this.viewStudentDtl=false;
    this.add=true;
  }
  back(addFields:NgForm){
    this.viewStudentDtl=true;
    this.add=false;
    addFields.reset();
  }

  studID
  studName
  cntNo
  parntName
  PCntNo
  addrs

  bid = localStorage.getItem("busId");

  stID
  stName
  cnNo
  prtName
  PCtNo
  addr

  detailAdd(){
    let add = {
      studentId:this.studID,
      studentName:this.studName,
      phoneNumber:this.cntNo,
      parentName:this.parntName,
      parentPhoneNumber:this.PCntNo,
      address:this.addrs,
      businessId:this.bid
    }
    console.log("before", add)
    this.hService.addHostelStudent(add).subscribe((data:any) => {
      console.log("after", data);
      alert("Submitted Successfully");
      
    this.ngOnInit();
    })
    
  }
  completefields(addFields:NgForm){
    this.viewStudentDtl=true;
    this.add=false;
    addFields.reset();
  }
  editDetail(data){
    this.stID = data.studentId
    this.stName = data.studentName
    this.cnNo = data.phoneNumber
    this.prtName = data.parentName
    this.PCtNo = data.parentPhoneNumber
    this.addr = data.address
    // this.id = data.id
  }

  updateDetails(){
    let updt = {
      studentId:this.stID,
      studentName:this.stName,
      phoneNumber:this.cnNo,
      parentName:this.prtName,
      parentPhoneNumber:this.PCtNo,
      address:this.addr,
      businessId:this.bid
    }
    console.log("before", updt);
    
    this.hService.updateHostelStudent(updt).subscribe((data:any) => {
      if (data.success = true) {
      console.log("Update_Details", data);
      }
      this.ngOnInit();
    });
  }

  deleteDetail(id){
    this.hService.deleteHostelStudent(id).subscribe((data:any) => {
      console.log("Del_Details", data);
      alert("deleted");
      this.ngOnInit();
    });
  }

  viewDetails:any = [];
  
  ngOnInit() {
    this.hService.viewHostelStudent(this.bid).subscribe((data:any) => {
      this.viewDetails = data.data
      console.log("View_Student_Details", this.viewDetails) 
    });
  }

}
