import { Component, OnInit } from '@angular/core';
import { TransportService } from '../transport.service';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-drivers',
  templateUrl: './drivers.component.html',
  styleUrls: ['./drivers.component.scss']
})
export class DriversComponent implements OnInit {

  constructor(
    private service:TransportService
  ) { }

  search:any;
  
  viewDriver=true;
  addDriver=false;

  addDriverList(){
    this.viewDriver=false;
    this.addDriver=true;
  }
  back(addFields:NgForm){
    this.viewDriver=true;
    this.addDriver=false;
    addFields.reset();
  }

  drvrName
  drivngLic
  busNbr
  bustyp
  cntNo
  add

  driverAdd(){
    let add = {
      name:this.drvrName,
      drivinglisence_no:this.drivngLic,
      bus_no:this.busNbr,
      address:this.add,
      contactNumber:this.cntNo,
      busType:this.bustyp,
      businessId:this.bussId
    }
    console.log("before", add);
    this.service.addDrivers(add).subscribe((data:any) => {
      console.log("after", data);
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Submitted Successfully',
        showConfirmButton: false,
        timer: 1500
      })
      this.viewDriver=true;
    this.addDriver=false;
      this.ngOnInit();
    })
  }
  
  completefields(addFields:NgForm){
    addFields.reset();
  }

  drivrName
  drivngLicen
  busNmbr
  busType
  cntNum
  addrs
  id

  editDriver(data){
    this.drivrName = data.name
    this.drivngLicen = data.drivinglisence_no
    this.busNmbr = data.bus_no
    this.busType = data.busType
    this.cntNum = data.contactNumber
    this.addrs = data.address
    this.id = data.id
  }

  updateDriver(){
    let updt = {
      name:this.drivrName,
      drivinglisence_no:this.drivngLicen,
      bus_no:this.busNmbr,
      address:this.addrs,
      contactNumber:this.cntNum,
      busType:this.busType,
      businessId:this.bussId,
      id:this.id
    }
    console.log("before", updt);
    this.service.updateDrivers(updt).subscribe((data:any) => {
      console.log("after", data);
      this.ngOnInit();
    })
  }
  deleteDriver(id){
    this.service.deleteDrivers(id).subscribe((data:any) => {
      alert("Deleted");
      this.ngOnInit();
      console.log("Deleted",data);
   })
  }

  bussId = localStorage.getItem("busId")
  viewDriversList:any = [];
  ngOnInit() {
    this.service.viewDrivers(this.bussId).subscribe((data:any) =>{
      this.viewDriversList = data.data
      console.log("View_Drivers", this.viewDriversList);   
    })
  }
}
