import { Component, OnInit } from '@angular/core';
import { SuperAdminService } from '../super-admin.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor(private service : SuperAdminService , private router : Router) { }



  externals : any =[];
  internals : any = [];


  busId = localStorage.getItem("busId");
  externalResultsGraphInfo = {
    label:  ['1-1', '1-2', '2-1', '2-2', '3-1', '3-2', '4-1','4-2'],
    dataset:[
      { data: [0, 0, 0, 0, 0, 0, 0, 0], label:'Branch Wise'},
    ]
  };

  internalResultsGraphInfo = {
    label:  ['1-1', '1-2', '2-1', '2-2', '3-1', '3-2', '4-1','4-2'],
    dataset:[
      { data: [0, 0, 0, 0, 0, 0, 0, 0], label:'Branch Wise'},
    ]
  };

  ngOnInit() {

    this.service.externalPercentage().subscribe((data : any) => {
      this.externals = data.data["Top Students from All the Colleges "];
      console.log("external percentage" , this.externals);
      
    })

    this.service.internalPercentage().subscribe((int : any) =>{
      // console.log("internal percentage" , nn);
      // let collages = "Top Students from All the Colleges";
      this.internals = int.data["Top Students from All the Colleges "];
      console.log("internal percentage" , this.internals);
      
    })


    // GRAPHS APIS//
    //  ExternalGraph APIs    
    this.service.getexternalresult().subscribe((data:any) => {      
      let externalGraph = data.data
      let externaldata = {
        label:  [],
        dataset:[
          { data: [], label: 'Branch' },
        ]
      }

      for (let index = 0; index < externalGraph.length; index++) {
        let label = externalGraph[index].branchName;
        externaldata.label.push(label);

        let dataset = externalGraph[index].percentage          
        externaldata.dataset[0].data.push(dataset)
      }
      this.externalResultsGraphInfo = externaldata   
    })
  //  InterGraph APIs
    this.service.getInternalresult().subscribe((data:any) => {
      let internalGraph = data.data
      let internaldata = {
        label:[],
        dataset: [
          {data:[], label : 'Branch'},
        ]
      }

      for (let index = 0; index < internalGraph.length; index++) {
        let label = internalGraph[index].branchName;
        internaldata.label.push(label);
        let dataset = internalGraph[index].percentage
        internaldata.dataset[0].data.push(dataset);    
      }
      this.internalResultsGraphInfo = internaldata
    })

  }


}
