import { Component, OnInit } from '@angular/core';
import { TransportService } from '../transport.service';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-stops',
  templateUrl: './stops.component.html',
  styleUrls: ['./stops.component.scss']
})
export class StopsComponent implements OnInit {

  constructor(
    private service:TransportService
  ) { }

  search:any;
  
  viewStop=true;
  addStops=false;

  addStopsList(){
    this.viewStop=false;
    this.addStops=true;
  }
  back(addFields:NgForm){
    this.viewStop=true;
    this.addStops=false;
    addFields.reset();
  }
    
  busNo;
  drvrNo;
  strtPoint;
  endPnt;
  addfare;
  busTy;

  addStop(){
    let stopadd= {
      bus_no:this.busNo,
      start_point: this.strtPoint,
      end_point:this.endPnt,
      fare:this.addfare,
      busType:this.busTy,
      businessId:this.bussId
    }
    this.service.addBusStops(stopadd).subscribe(data => {
      console.log("stops added",data);
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Submitted Successfully',
        showConfirmButton: false,
        timer: 1500
      })
      this.viewStop=true;
      this.addStops=false;
      this.ngOnInit();
      
    })
  }

  completefields(addFields:NgForm){
    addFields.reset();
  }


  busNum;
  strtPnt;
  edPnt;
  fare;
  busTyp;
  id;

  editStop(data){
    this.busNum = data.bus_no
    this.strtPnt = data.start_point
    this.edPnt = data.end_point
    this.fare = data.fare
    this.busTyp = data.busType  
    this.id = data.id
  }
  updateStops(){
    let upstops = {
      bus_no:this.busNum,
      start_point:this.strtPnt,
      end_point:this.edPnt,
      fare:this.fare,
      busType:this.busTyp,
      id :this.id
    }
    console.log("before",upstops);  
    this.service.updateStops(upstops).subscribe(data =>{
      console.log("update stops",data);      
      this.ngOnInit();
    })
  }

  deleteStop(id){
    this.service.deleteStop(id).subscribe(data =>{
      console.log("delete stop",data);
      alert("Deleted");
      this.ngOnInit();
    })
  }

  bussId = localStorage.getItem("busId")
  allStops:any = []
  ngOnInit() {
    this.service.getAllBusStops().subscribe((data:any) => {
      this.allStops = data.data
    })
  }
}
