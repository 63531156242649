import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { StudentService } from '../student.service';
declare var $;


@Component({
  selector: 'app-library',
  templateUrl: './library.component.html',
  styleUrls: ['./library.component.scss']
})
export class LibraryComponent implements OnInit {

  searchBk:any;
  searchAvlBk:any;
  
  constructor(private service: StudentService) { }

  id = localStorage.getItem("userId");
  busId = localStorage.getItem("busId");

  books = true;
  availability = false;
  all() {
    this.books = false;
    this.availability = true;
  }
  avail() {
    this.books = true;
    this.availability = false;
  }

  libraryBooks = []
  studentBooks = []

  ngOnInit() {
    this.service.getBooks(this.busId).subscribe((books: any) => {
      this.libraryBooks = books.data;
      console.log("books", this.libraryBooks);

    })

    this.service.bookStatus(this.id).subscribe((stdBooks: any) => {
      this.studentBooks = stdBooks.data;
      console.log("yourbooks", this.studentBooks);
    })

  }
}
