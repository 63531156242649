export class Constant {
    // static API_ORIGIN_URL = location.origin + "/"
    // static API_ORIGIN_URL = "http://localhost:8080/collage-management/collage"
    // static API_ORIGIN_URL = "http://13.58.55.216:8080/collage-management/collage"
    // static API_ORIGIN_URL = "http://3.135.231.173:8080/collage-management/collage"
    // static API_ORIGIN_URL = "http://ksit.southeastasia.cloudapp.azure.com:8080/collage-management/collage"
    // static API_ORIGIN_URL = "http://13.233.93.146:8080/collage-management/collage"
    // static API_ORIGIN_URL = "http://13.233.118.247:8080/collage-management/collage"
    static API_ORIGIN_URL = "http://13.232.47.220:8080/collage-management/collage"
}

