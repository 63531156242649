import { Component, OnInit } from '@angular/core';
import { HomeService } from '../home.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2'

import { Validators, FormControl, FormGroup } from '@angular/forms';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  eMail
  password
  cPwd
  collegname

  hide = true;

  constructor(
    private homeService: HomeService,
    private router: Router) {}


  log = false;
  search = true;
  login = true;
  forgotPwd = false;

  next() {
    this.log = true;
    this.search = false;
  }
  bak() {
    this.search = true;
    this.log = false;
  }
  ftpwd() {
    this.login = false;
    this.forgotPwd = true;
  }
  backtoLog() {
    this.login = true;
    this.forgotPwd = false;
  }

  uName: any;
  pwd: any;

  
  signIn() {
    let signinFields = {
      userName: this.uName,
      password: this.pwd
    }
    console.log("before signin", signinFields);

    this.homeService.sign(signinFields).subscribe((data: any) => {
      // let roleid = data.data.roleid;
       console.log("roleId",data);
   
      if (data.message === "Success") {
        // Swal.fire({
        //   position: 'center',
        //   icon: 'success',
        //   title: 'sucessfully login',
        //   // showConfirmButton: true,
        //   // timer: 1000
        // })
        console.log("after signin", data);

        localStorage.setItem('token', data.data.authToken);
        localStorage.setItem('userId', data.data.username);
        localStorage.setItem('busId', data.data.businessId);
        // this.router.navigate(['/student']);
        // this.router.navigate(['/super-admin']);
        // this.router.navigate(['/professor']);
        // this.router.navigate(['/admin']);
        // this.router.navigate(['/parent-home']);
        // this.router.navigate(['/canteen']);
        // this.router.navigate(['/librarian']);
        // this.router.navigate(['/hostel']);
        // this.router.navigate(['/transport']);
        // this.router.navigate(['/accounts'])

        if(data.data.roleId === 6){
          this.router.navigate(['/student/dashboard']);
        } else if(data.data.roleId === 2){
          this.router.navigate(['/professor/dashboard']);
        } else if(data.data.roleId === 1){
          this.router.navigate(['/admin/dashboard']);
        }else if(data.data.roleId === 12){
          this.router.navigate(['/super-admin/dashboard']);
        }else if(data.data.roleId === 11){
          this.router.navigate(['/canteen/menu']);
        }else if(data.data.roleId === 9){
          this.router.navigate(['/hostel/hostel-list']);
        }else if(data.data.roleId === 4){
          this.router.navigate(['/transport/buses']);
        }else if(data.data.roleId === 10){
          this.router.navigate(['/accounts/student-fee']);
        }else if(data.data.roleId === 3){
          this.router.navigate(['/librarian/books']);
        }else if(data.data.roleId === 5){
          this.router.navigate(['/parent-home']);
        }
        
      }
        else {
            alert("invalid username or password")
          }
    },
    (err) => {      
      alert("Internal server Error")
      console.log('external adding Patient error:', err);
      // this.spinner = false;
      // this._alertService.openSnackBarMessage(err.error, 'Close');
    })

    // this.log = false;
  }
  
  ngOnInit() {
  }

  
}
