import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-canteen',
  templateUrl: './canteen.component.html',
  styleUrls: ['./canteen.component.scss']
})
export class CanteenComponent implements OnInit {

  constructor(private router: Router) { }

profileName = localStorage.getItem("userId")

logOut() {
  localStorage.removeItem('token');
  localStorage.removeItem('userId');
  localStorage.removeItem('busId');
  this.router.navigate(['/']);
}
  ngOnInit() {
  }

}
