import { Component, OnInit } from '@angular/core';
import { ProfessorService } from '../professor.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  constructor(private pService: ProfessorService, private router: Router) { }

  fname: any;
  lname: any;
  dob: any;
  cnum: any;
  email: any;
  gender: any;
  yearJoined: any;
  professorId = localStorage.getItem("userId")
  address: any;
  
  logOut() {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('busId');
    this.router.navigate(['/']);
  }

  
  getDetails(data) {
    this.fname = data.firstName
    this.lname = data.lastname
    this.dob = data.dob
    this.gender = data.gender
    this.email = data.email
    this.cnum = data.contactNumber
    this.address = data.address
    this.yearJoined = data.yearJoined
  }
  
  

  updateProfile() {
    let pFields = {
      userName : this.professorId,
      firstName : this.fname,
      lastName : this.lname,
      email : this.email,
      contactNumber :this.cnum,
      dob : this.dob,
      address : this.address,
      yearJoined :this.yearJoined,
      gender : this.gender
   }
    this.pService.updateProfile(pFields).subscribe((data: any) => {
      if (data.success = true) {
        this.fname = data.data.firstName
        this.lname = data.data.lastName
        this.email = data.data.email
        this.dob = data.data.dob
        this.address = data.data.address
        this.cnum = data.data.contactNumber
        this.yearJoined = data.data.yearJoined
        this.gender = data.data.gender
        console.log("Update_Profile_Data", this.fname);
      }
    })
  }

  firstName:any;
  getprofiledetails:any;
  ngOnInit() {
    this.pService.getprofile(this.professorId).subscribe((data: any) => {
      this.getprofiledetails = data.data
      this.firstName = data.data.firstName
      
      // localStorage.setItem("businessId", data.data.businessId)
      console.log("getprofiledetails", this.getprofiledetails);
      this.getDetails(this.getprofiledetails)
    })
  }

}
