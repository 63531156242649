import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-a-home',
  templateUrl: './a-home.component.html',
  styleUrls: ['./a-home.component.scss']
})
export class AHomeComponent implements OnInit {

  
  constructor(private router:Router) { }

  profileName = localStorage.getItem("userId")

  logOut() {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('busId');
    this.router.navigate(['/']);
  }

  ngOnInit() {
  }

}
