import { Component, OnInit } from '@angular/core';
import { StudentService } from '../../student.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-class-attendance',
  templateUrl: './class-attendance.component.html',
  styleUrls: ['./class-attendance.component.scss']
})
export class ClassAttendanceComponent implements OnInit {

  constructor(private service:StudentService, private router:Router) { }
  month;
  week;

  semWise = true;
  subj = false;
  monthTab = false;
  weekTab = false;
  // dayTab = false;

  back(){
    this.router.navigate(['/student/attendance']);  
  }
  backTo(){
    this.semWise=true;
    this.subj=false;
  }
  backToSub(){
    this.monthTab=false;
    this.subj=true;
  }

  backToMonth(){
    this.monthTab=true;
    this.weekTab=false;
  }

  // backToWeek(){
  //   this.dayTab=false;
  //   this.weekTab=true;
  // }

  dep = localStorage.getItem("department");
  studId = localStorage.getItem("userId");

  subjctList:any = []
  subjectList(y,s){
    this.subj=true;
    this.semWise=false;

    let sub ={
      year: y,
      semester:s,
      department:this.dep
    }
    console.log("s",sub);
    
    this.service.subList(sub).subscribe((data:any) => {
    this.subjctList = data.data;
    console.log("sub", this.subjctList);
    })
  }
subjectName;
  classroomMonthly:any = []
  monthlyAtd(sub){
    this.subj=false;
    this.monthTab=true;
this.subjectName=sub
    let s ={
      userName:this.studId,
      subject:sub
    }
    this.service.classroomMonthlyAttd(s).subscribe((data:any) =>{
      this.classroomMonthly = data.data;
    })
  }
  
  weeklyAttendance:any=[]
  newWat:any;
  weeklyAtt:any;

  weeklyAtd(month){
    this.monthTab=false;
    this.weekTab=true;
    let wkAtd = {
      userName: this.studId,
      month:month,
      subject:this.subjectName
    }
    console.log("w", wkAtd);
    
    this.service.classroomWeeklyAttd(wkAtd).subscribe((data:any) => {
      this.weeklyAttendance = data.data
      let wAt = data.data
      this.newWat = []
      console.log("newWeeks-----", this.newWat);
      console.log("present absent",this.weeklyAtt);
      
      for (let obj of wAt) {      
        for(let day in obj){
          console.log("dayyyy-------",day);  
          var weeks = day.split('');
          console.log("Only Weeksss-------", weeks);     
          this.newWat.push(day)         
        }      
      }
      console.log("weekly attendance",this.weeklyAttendance);      
    })
  }

  semesterList:any = []
  ngOnInit() {
    this.service.semList(this.studId).subscribe((data:any) => {
      this.semesterList = data.data;
      console.log("sem",this.semesterList);     
    })
  }

}
