import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { StudentService } from '../student.service';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss']
})

export class ResultComponent implements OnInit {

  searchInt:any;
  searchExt:any;
  
  semister = true;
  result = false;
  internal = false;
  external = false;


  backToExam() {
    this.result = true;
    this.internal = false;
    this.external = false;

  }
  backToSem() {
    this.semister = true;
    this.result = false;
  }
  constructor(private service: StudentService) { }

  year;
  semtr;
  id = localStorage.getItem("userId");

  semResults: any = []

  semIntExtRes(yr,sem) {
this.year=yr,
this.semtr=sem
    this.service.semisterWise(this.id).subscribe((data: any) => {
      this.semResults = data.data;
      console.log("semisterWise", this.semResults);
    })
    this.semister = false;
    this.result = true;
    this.internal = false;
    this.external = false;
  }

  intResults: any = [];
  extResults: any = [];

  sliptest(exam) {
    let datap = {
      studentId: this.id,
      year: this.year,
      semester: this.semtr,
      internalName: exam,
      externalName: exam
    }
    console.log("dddd", datap);
    if(exam=="EXTERNAL"){
      this.service.externals(datap).subscribe((extrnl: any) => {
        this.extResults = extrnl.data;
        console.log("externals", this.extResults);
        this.external = true;
        this.internal = false;
        this.result = false;
      })

    }else{
      this.service.internals(datap).subscribe((result: any) => {
        this.intResults = result.data;
        console.log("sliptestResults", this.intResults);
        this.internal = true;
        this.external = false;
        this.result = false;
      })

    }

  }

  semesterList:any =[]
  ngOnInit() {
    this.service.semList(this.id).subscribe((data:any) => {
      this.semesterList = data.data;
      console.log("sem",this.semesterList);     
    })
  }

}
