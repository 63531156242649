import { Component, OnInit } from '@angular/core';
import { LibrarianService } from '../librarian.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-lendbooks',
  templateUrl: './lendbooks.component.html',
  styleUrls: ['./lendbooks.component.scss']
})
export class LendbooksComponent implements OnInit {

  search:any;
  
  constructor(
    private lService:LibrarianService
  ) { }


  view=true;
  addLendBook=false;

  add(){
    this.view=false;
    this.addLendBook=true;
  }
  back(){
    this.view=true;
    this.addLendBook=false;
  }

  completefields(addFields:NgForm){
    addFields.reset();
  }
  cancel(addFields:NgForm){
    addFields.reset();
  }

  // editLendBook(data){
  //   this.lendStudentId = data.studentId
  //   this.lendBookName = data.bookName
  //   this.lendBookId = data.bookId
  //   this.lendBookAuthorName = data.author
  //   this.lendYear = data.publishedYear
  //   console.log("editlendbook",data)    
  // }

  // lendStudentId:any;
  // lendBookName:any;
  // lendBookId:any;
  // lendBookAuthorName:any;
  // lendYear:any;

  // updateLendBook(){

  // }

  lbkid = localStorage.getItem("busId")
  totallLendlibraryBooks:any = [];
  ngOnInit() {
   this.lService.getLendLibraryBooks(this.lbkid).subscribe((data:any) => {
     if(data.success = true){
       this.totallLendlibraryBooks = data.data;
     }
   })
    
  }

}
