import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constant }  from '../../config';

@Injectable({
  providedIn: 'root'
})
export class AccountsService {

  constructor(private httpClient: HttpClient) { }

  // Email

  // Compose
  sendMail(mail){
    return this.httpClient.post(Constant.API_ORIGIN_URL+'/email/compose', mail)
  }

  // sent
  mailsSent(id){
    return this.httpClient.get(Constant.API_ORIGIN_URL+'/email/mails/' + id)
  }

  // inbox
  mailsReceive(id){
    return this.httpClient.get(Constant.API_ORIGIN_URL+'/email/mails/' + id)
  }
  
  // delete mail
  mailsDelete(id){
    return this.httpClient.delete(Constant.API_ORIGIN_URL+'/email/deleteMail/' + id)
  }

  //School Expenses
  addSchoolExpenses(data){
    return this.httpClient.post(Constant.API_ORIGIN_URL+'/accounts/saveSchoolExpenses', data)
  }
  viewSchoolExpenses(id){
    return this.httpClient.get(Constant.API_ORIGIN_URL+'/accounts/getSchoolExpenses/' + id)
  }
  updateSchoolExpenses(data){
    return this.httpClient.post(Constant.API_ORIGIN_URL+'/accounts/updateSchoolExpensesDetails', data)
  }
  deleteSchoolExpenses(id){
    return this.httpClient.delete(Constant.API_ORIGIN_URL+'/accounts/deleteSchoolExpensesDetails/'+id)
  }
}
