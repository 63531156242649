import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from '../../home/main/main.component';
import { LoginComponent } from '../../home/login/login.component';
// import { ProfileComponent } from './profile/profile.component';
import { ConceptsComponent } from '../../home/concepts/concepts.component';
import { ParentComponent } from './parent.component';
import { HomeComponent } from './home/home.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AcademicsComponent } from './academics/academics.component';
import { AttendanceComponent } from './attendance/attendance.component';
import { FeePaymentComponent } from './fee-payment/fee-payment.component';
import { ResultComponent } from './result/result.component';
import { LiveTrackingComponent } from './live-tracking/live-tracking.component';


const routes: Routes = [
{ path:'', component: MainComponent },
{ path:'concepts',component: ConceptsComponent},
{ path:'login', component: LoginComponent },
{ path:'parent-home',component: HomeComponent},
{ path:'parent',component: ParentComponent , children:[
  { path: 'dashboard', component: DashboardComponent},
  { path: 'academics', component: AcademicsComponent},
  { path: 'attendance', component: AttendanceComponent},
  // { path: 'email', component: EmailComponent},
  { path: 'feePay', component: FeePaymentComponent},
  { path: 'results', component: ResultComponent},
  { path: 'live-tracking', component: LiveTrackingComponent}
  
]
}
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ParentRoutingModule { }
