import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constant }  from '../../config';

@Injectable({
  providedIn: 'root'
})
export class ProfessorService {

  constructor(private httpClient: HttpClient) { }

  // Dashboard

  viewProfessorFeedback(id){
    return this.httpClient.get(Constant.API_ORIGIN_URL+'/professor/getFeedBackForProfessor/' + id)
  }

  highestInternalMarks(data){
    return this.httpClient.post(Constant.API_ORIGIN_URL+'internals/getInternalMarks', data)
  }
  
  // Profile
  getprofile(id){
    return this.httpClient.get(Constant.API_ORIGIN_URL+'/professor/viewProfile/' + id)
  }
  updateProfile(id){
    return this.httpClient.post(Constant.API_ORIGIN_URL+'/professor/editProfile', id)
  }


  // Academics
  viewSubjects(id){
    return this.httpClient.get(Constant.API_ORIGIN_URL+'/professor/getSubjectsForProfessor/' + id);
  }

  viewSyllabus(id){
    return this.httpClient.get(Constant.API_ORIGIN_URL+'/professor/viewSyllabus/' + id)
  }

  addSyllabus(data){
    return this.httpClient.post(Constant.API_ORIGIN_URL+'/professor/addSyllabus', data)
  }

  updateSyllabus(data){
    return this.httpClient.post(Constant.API_ORIGIN_URL+'/professor/updateSyllabus', data);
  }

  deleteSyllabus(id){
    return this.httpClient.delete(Constant.API_ORIGIN_URL+'/professor/deleteSyllabus/'+ id);
  }

  addLectureNotes(data){
    return this.httpClient.post(Constant.API_ORIGIN_URL+'/professor/addLectureNotes', data);
  }
  
  // viewLectureNotes(data){
  //   return this.httpClient.post(Constant.API_ORIGIN_URL+'/professor/viewLectureNotes', data);
  // }

    viewLectureNotes(sub){
    return this.httpClient.get(Constant.API_ORIGIN_URL+'/professor/viewLectureNotes/' + sub);
  }

  updateLectureNotes(id){
    return this.httpClient.post(Constant.API_ORIGIN_URL+'/professor/updateLectureNotes', id);
  }
  deleteNotes(id){
    return this.httpClient.delete(Constant.API_ORIGIN_URL+'/professor/deleteLectureNotes/'+ id);
  }

  viewNotification(id){
    return this.httpClient.get(Constant.API_ORIGIN_URL+'/professor/getAllNotifications/' + id);
  }

  updateNotification(data){
    return this.httpClient.post(Constant.API_ORIGIN_URL+'/professor/editNotifications', data);
  }

  // addNotification(data){
  //   return this.httpClient.post(Constant.API_ORIGIN_URL+'/professor/addLectureNotes', data);
  // }

  deleteNotification(id){
    return this.httpClient.delete(Constant.API_ORIGIN_URL+'/professor/deleteNotifications/' + id);
  }

   //library APIs

  //getBooks API
  getBooks(id){
    return this.httpClient.get(Constant.API_ORIGIN_URL+'/library/getBooksByBusinessId/' + id);
  }

  //View books status  API
  bookStatus(id){
    return this.httpClient.get(Constant.API_ORIGIN_URL+'/student/bookStatus/' + id);
  }

  // Email

  // Compose
  sendMail(mail){
    return this.httpClient.post(Constant.API_ORIGIN_URL+'/email/compose', mail)
  }

  // sent
  // mailsSent(data){
  //   return this.httpClient.post(Constant.API_ORIGIN_URL+'/email/mails', data)
  // }

  mailsSent(id){
    return this.httpClient.get(Constant.API_ORIGIN_URL+'/email/mails/' + id)
  }

  // inbox
  // mailsReceive(data){
  //   return this.httpClient.post(Constant.API_ORIGIN_URL+'/email/mails', data)
  // }
  mailsReceive(id){
    return this.httpClient.get(Constant.API_ORIGIN_URL+'/email/mails/'+ id)
  }
  
  // delete mail
  mailsDelete(id){
    return this.httpClient.delete(Constant.API_ORIGIN_URL+'/email/deleteMail/' + id)
  }

  // Feedback 
  addStudentFeedback(data){
    return this.httpClient.post(Constant.API_ORIGIN_URL+'/professor/addStudentFeedBack', data);
  }
  viewStudentFeedback(id){
    return this.httpClient.get(Constant.API_ORIGIN_URL+'/professor/viewStudentFeedBack/' + id);
  }

  // Exams

  addExams(data){
    return this.httpClient.post(Constant.API_ORIGIN_URL+'/professor/addExams', data);
  }

  viewExams(id){
    return this.httpClient.get(Constant.API_ORIGIN_URL+'/professor/getExamsByUserName/' + id)
  }

  updateExams(data){
    return this.httpClient.post(Constant.API_ORIGIN_URL+'/professor/editExams', data);
  }

  deleteExams(id){
    return this.httpClient.delete(Constant.API_ORIGIN_URL+'/professor/deleteExams/' + id)
  }

  viewSubjectByDepartment(subject){
    return this.httpClient.get(Constant.API_ORIGIN_URL+'/professor/viewSubjectByDepartment/' + subject)
  }

  // Attendance

  //Professor Attendance

  viewProAttd(data){
    return this.httpClient.post(Constant.API_ORIGIN_URL+'/attendance/getAttendanceByEmployeeName', data)
  }
  viewStudentList(data){
    return this.httpClient.post(Constant.API_ORIGIN_URL+'/professor/getStudentListBySubject', data)
  }
  addStudentAttd(data){
    return this.httpClient.post(Constant.API_ORIGIN_URL+'/attendance/addSubjectAttendance', data)
  }
  semList(id){
    return this.httpClient.get(Constant.API_ORIGIN_URL+'/attendance/getSemestersByUserName/' + id)
  }
  studentList(data){
    return this.httpClient.post(Constant.API_ORIGIN_URL+'/attendance/getStudentList', data)
  }

  viewDepartments(){
    return this.httpClient.get(Constant.API_ORIGIN_URL+'/admin/viewDepartment');
  }

  weekAttnd(week){
    return this.httpClient.post(Constant.API_ORIGIN_URL+ '/attendance/getStudentWeeeklyGateAttendance', week)
  }

  classroomWeeklyAttd(data){
    return this.httpClient.post(Constant.API_ORIGIN_URL+ '/attendance/getStudentWeeeklyClassRoomAttendance', data)
  }

  // results

  // internals
  viewInternalMarks(data){
    return this.httpClient.post(Constant.API_ORIGIN_URL+'/internals/getAllInternalForSubjectWise', data)
  }
  
  viewStudentListBySubjectName(data){
    return this.httpClient.post(Constant.API_ORIGIN_URL+'/professor/viewStudentListBySubjectName', data)
  }

}
