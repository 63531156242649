import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-feepay',
  templateUrl: './feepay.component.html',
  styleUrls: ['./feepay.component.scss']
})
export class FeepayComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
 
}
