import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HostelListComponent } from './hostel-list/hostel-list.component';
import { AllocationComponent } from './allocation/allocation.component';
import { OutingPassComponent } from './outing-pass/outing-pass.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { MailComponent } from './mail/mail.component';
import { MatInputModule } from '@angular/material';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule } from '@angular/forms';
import { HostelRoutingModule } from './hostel-routing.module';
import { HostelHomeComponent } from './hostel-home/hostel-home.component';
import { StudentDetailsComponent } from './student-details/student-details.component';
import { FeeDetailsComponent } from './fee-details/fee-details.component';
import { DisciplinaryRuleComponent } from './disciplinary-rule/disciplinary-rule.component';
import { FineComponent } from './fine/fine.component';
import { Ng2SearchPipeModule } from "ng2-search-filter";



@NgModule({
  declarations: [
    HostelListComponent,
    AllocationComponent,
    OutingPassComponent,
    FeedbackComponent,
    MailComponent,
    HostelHomeComponent,
    StudentDetailsComponent,
    FeeDetailsComponent,
    DisciplinaryRuleComponent,
    FineComponent
    
  ],
  imports: [
    CommonModule,
    MatInputModule,
    MaterialModule,
    FormsModule,
    HostelRoutingModule,
    Ng2SearchPipeModule
  ]
})
export class HostelModule { }
