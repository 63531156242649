import { Component, OnInit } from '@angular/core';
import { ProfessorService } from '../../professor.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-student-attendance',
  templateUrl: './student-attendance.component.html',
  styleUrls: ['./student-attendance.component.scss']
})
export class StudentAttendanceComponent implements OnInit {

  constructor(private pService: ProfessorService, private router:Router) { }

  attd = true;


  gateAtd(){
    this.router.navigate(['/professor/attendance/student-attendance/gate']);   
  }

  classAtd(){
    this.router.navigate(['/professor/attendance/student-attendance/class']);   
  }
  

  back(){
    this.router.navigate(['/professor/attendance']);   
  }

  
  ngOnInit() {

  }

}
