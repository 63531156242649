import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { ChartOptions, ChartType, Chart } from 'chart.js';
import { Color, Label, SingleDataSet } from 'ng2-charts';
import 'chartjs-plugin-labels';


@Component({
  selector: 'pie-chart',
  templateUrl: './my-pie-chart.component.html',
  styleUrls: ['./my-pie-chart.component.css']
})
export class MyPieChartComponent implements OnInit, OnChanges {

  @Input() Labels: Label[];
  @Input() Datasets: SingleDataSet[];

  @Input() pieHeight: number;

  public pieChartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    showAllTooltips: true,
    legend: {
      position: 'top',
    }
  };
  public pieChartLabels: Label[] = ['Professor', 'Hostel','Transport'];
  public pieChartData: SingleDataSet = [300, 500, 100,];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [];
  public pieChartColors = [
    {
      backgroundColor: ['rgba(11,102,35,0.2)', 'rgba(0,255,0,0.3)', 'rgba(0,0,255,0.3)'],
    },
  ];


  constructor() { }

  ngOnInit() {
    Chart.plugins.register({
      afterDatasetsDraw: function(chartInstance:any, easing) {
        // To only draw at the end of animation, check for easing === 1
        var ctx = chartInstance.chart.ctx;
        chartInstance.data.datasets.forEach(function(dataset, i) {
          var meta = chartInstance.getDatasetMeta(i);
          if (!meta.hidden) {
            meta.data.forEach(function(element:any, index) {
              // Draw the text in black, with the specified font
              ctx.fillStyle = 'grey';
              var fontSize = 16;
              var fontStyle = 'normal';
              var fontFamily = 'Helvetica Neue';
              ctx.font = Chart.helpers.fontString(fontSize, fontStyle, fontFamily);
              // Just naively convert to string for now
              var dataString = dataset.data[index].toString();
              // Make sure alignment settings are correct
              ctx.textAlign = 'center';
              ctx.textBaseline = 'middle';
              var padding = 5;
              var position = element.tooltipPosition();
              // ctx.fillText(dataString + '%', position.x, position.y - (fontSize / 2) - padding);
            });
          }
        });
      }
    });

  }

  ngOnChanges(changes: SimpleChanges){
    if(changes.Info.currentValue) {
      this.pieChartLabels = changes.Info.currentValue.label;
      this.pieChartData = changes.Info.currentValue.dataset;
    }
    // if(changes.Datasets.currentValue) {
    //   this.pieChartData = changes.Datasets.currentValue;
    //   console.log("ddddd",this.pieChartData);

    // }
  }

}
