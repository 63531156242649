import { Component, OnInit} from '@angular/core';
import { StudentService } from '../student.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-attendance',  
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.scss'],
})

export class AttendanceComponent implements OnInit {

  constructor(private service:StudentService, private router:Router){}

  id = localStorage.getItem("userId");

  classAtd(){
    this.router.navigate(['/student/attendance/class-attendance']);  
  }
  
  gateAtd(){
    this.router.navigate(['/student/attendance/gate-attendance']);  
  }

  ngOnInit() {
  }
}

