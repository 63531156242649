import { Component, OnInit } from '@angular/core';
import { TransportService } from '../transport.service';
import {FormControl , Validators, NgForm} from '@angular/forms';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-buses',
  templateUrl: './buses.component.html',
  styleUrls: ['./buses.component.scss']
})
export class BusesComponent implements OnInit {

  constructor(
    private service:TransportService
  ) { }

  
  search:any;
  searchList:any;

  viewBusList=true;
  addBuses=false;
  addPrivateBuses=false;

  addBusList(){
    this.viewBusList=false;
    this.addBuses=true;
    this.addPrivateBuses=false;
  }
  back(addFields){
    this.viewBusList=true;
    this.addBuses=false;
    this.addPrivateBuses=false;
    addFields.reset();
  }

  addPrivateBusList(){
    this.viewBusList=false;
    this.addPrivateBuses=true;
    this.viewBusList=false;
  }

  busnub;
  busCap;
  bTp;

  busAdd(){
    let bus = {
      bus_no:this.busnub,
      capacity:this.busCap,
      busType:this.bTp,
      businessId:this.bussId
    }
    console.log("befor add bus",bus);
    this.service.addBus(bus).subscribe(data => {
      console.log("after bus add", data);
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Submitted Successfully',
        showConfirmButton: false,
        timer: 1500
      })
      this.viewBusList=true;
      this.addBuses=false;
      this.ngOnInit();   
    })
  }
  completefields(addFields:NgForm){
    addFields.reset();
  }

  busNbr:any
  busCapcty:any;
  bTyp:any;
  id
  editBus(data){
    this.busNbr = data.bus_no
    this.busCapcty = data.capacity
    this.bTyp = data.busType
    this.id = data.id
  }
  
  updateBus(){
    let addBus = {
      bus_no:this.busNbr,
      capacity:this.busCapcty,
      busType:this.bTyp,
      id:this.id
    }
      console.log("before update",addBus);
      this.service.updateBus(addBus).subscribe((data:any) => {
      console.log("after update bus", data);      
      this.ngOnInit(); 
      }) 
          
  }

  deleteBus(id){
   this.service.deleteBus(id).subscribe(data => {
     alert("delete")
     console.log("delete buss",data);
     this.ngOnInit();
     
   })
  }

  drName
  cntNum
  add
  busNo
  busTyp
  conStrtDt
  conEdDt
  conPrd
  amt
  
  privateBusAdd(){
    let add ={
      name:this.drName,
      contract_period:this.conPrd,
      contract_amount:this.amt,
      address:this.add,      
      contract_periodFrom:this.conStrtDt,
      contract_periodTo:this.conEdDt,
      bus_no:this.busNo,
      busType:this.busTyp,
      contactNumber:this.cntNum,
      businessId:this.bussId,
    }
    console.log("before", add);
    
    this.service.addPrivateBus(add).subscribe((data:any) => {
      console.log("after", data);
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Submitted Successfully',
        showConfirmButton: false,
        timer: 1500
      })
      this.viewBusList=true;
    this.addPrivateBuses=false;
      this.ngOnInit();
    })
  }

drvrName
cntNo
addrs
busNum
busType
conStrtDte
conEdDte
conPrid
amot
rid

editPrivateBus(data){
  this.drvrName = data.name
  this.cntNo = data.contactNumber
  this.addrs = data.address
  this.busNum = data.bus_no
  this.busType = data.busType
  this.conStrtDte = data.contract_periodFrom
  this.conEdDte = data.contract_periodTo
  this.conPrid = data.contract_period
  this.amot = data.contract_amount
  this.rid = data.id
}

updatePrivateBus(){
  let updt = {
    name:this.drvrName,
    contract_period:this.conPrid,
    contract_amount:this.amot,
    address:this.addrs,      
    contract_periodFrom:this.conStrtDte,
    contract_periodTo:this.conEdDte,
    bus_no:this.busNum,
    busType:this.busType,
    contactNumber:this.cntNo,
    businessId:this.bussId,
    id:this.rid
  }
  console.log("before", updt);
  this.service.updatePrivateBus(updt).subscribe((data:any) => {
    console.log("after", data);
    this.ngOnInit();
  })
}

  deletePrivateBus(id){
    this.service.deletePrivateBus(id).subscribe(data => {
      alert("Deleted")
      console.log("delete Private buss",data);
      this.ngOnInit();
    })
   }

  bussId = localStorage.getItem("busId")
  allbusses:any = []
  allPrivateBuses:any = []

  ngOnInit() {
    this.service.getAllBusses(this.bussId).subscribe((data:any) => {
      if(data.success = true){
        this.allbusses = data.data
        console.log("get all busses",this.allbusses);        
      }
    })

    this.service.getPrivateBusList(this.bussId).subscribe((data:any) => {
      if(data.success = true){
        this.allPrivateBuses = data.data
        console.log("get Private busses",this.allPrivateBuses);        
      }
    })
  }

}

