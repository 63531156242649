import { Injectable } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Constant }  from '../../config';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private httpClient: HttpClient) { }

  //Dash Board APIs
  getInternalGraph(id){
    return this.httpClient.get(Constant.API_ORIGIN_URL+'/internals/getInternalsPercentageGraph/' + id)
  }
  
  getExternalGraph(id){
    return this.httpClient.get(Constant.API_ORIGIN_URL+'/external/getExternalsPercentageGraph/' + id)
  }

  // feedback(id){
  //   return this.httpClient.get(Constant.API_ORIGIN_URL+'/feedback/getTotalFeedBack' + id)
  // }

  feedback(data){
    return this.httpClient.post(Constant.API_ORIGIN_URL+'/feedback/getTotalFeedBack', data)
  }
  // Profile API

  getprofile(id){
    return this.httpClient.get(Constant.API_ORIGIN_URL+'/admin/viewProfileForAdmin/' + id)
  }
  updateProfile(data){
    return this.httpClient.post(Constant.API_ORIGIN_URL+'/admin/editProfileForAdmin', data)
  }

 //Courses API's

 //view courses API
 viewCourses(){
   return this.httpClient.get(Constant.API_ORIGIN_URL+'/admin/viewCourse');
 }

 //add course API
 addCourseData(data : any){
   return this.httpClient.post(Constant.API_ORIGIN_URL+'/admin/addCourse' ,data);  
 }

 //edit course API
 editCourseData(data : any){
   return this.httpClient.post(Constant.API_ORIGIN_URL+'/admin/editCourse' , data);
 }

 //delete course API
 delCourse(id){
   return this.httpClient.delete(Constant.API_ORIGIN_URL+'/admin/deleteCourse/' + id);
 }

 //department API's

 //add department API
 addDepartments(details : any){
   return this.httpClient.post(Constant.API_ORIGIN_URL+'/admin/addDepartment' ,details);
 }

 //view departments API
 viewDepartments(){
   return this.httpClient.get(Constant.API_ORIGIN_URL+'/admin/viewDepartment');
 }

 //edit department API
 editDepartment(details){
   return this.httpClient.post(Constant.API_ORIGIN_URL+'/admin/editDepartment' ,details);
 }

 //delete department API
 delDepart(id){
   return this.httpClient.delete(Constant.API_ORIGIN_URL+'/admin/department/' +id);
 }


 //sections API's

 //view section API
 viewSections(){
     return this.httpClient.get(Constant.API_ORIGIN_URL+'/admin/viewSections');
 }

 //add section API
 addSects(data){
   return this.httpClient.post(Constant.API_ORIGIN_URL+'/admin/addSections' ,data);
 }

 //edit section API
 editSecData(data){
   return this.httpClient.post(Constant.API_ORIGIN_URL+'/admin/editSections',data);
 }

 //delete section API
 deleteSect(id){
   return this.httpClient.delete(Constant.API_ORIGIN_URL+'/admin/sections/' +id);
 }

// Holidays

viewHolidays(id){
  return this.httpClient.get(Constant.API_ORIGIN_URL+'/admin/viewHolidays/' + id)
}

addHolidays(data){
  return this.httpClient.post(Constant.API_ORIGIN_URL+'/admin/addHolidays', data)  
}

updateHoliday(data){
  return this.httpClient.post(Constant.API_ORIGIN_URL+'/admin/editHolidays', data)  
}

deleteHoliday(id){
  return this.httpClient.delete(Constant.API_ORIGIN_URL+'/admin/deleteHolidays/'+ id)
}

// Subjects

viewSubjects(data){
  return this.httpClient.post(Constant.API_ORIGIN_URL+'/admin/getAllSubjects', data)
}
viewSubjectList(id){
  return this.httpClient.get(Constant.API_ORIGIN_URL+'/admin/getSubjectsByBusinessId/' + id)  
}

addSubject(data){
  return this.httpClient.post(Constant.API_ORIGIN_URL+'/admin/addSubjects', data)
}
updateSubjects(data){
  return this.httpClient.post(Constant.API_ORIGIN_URL+'/admin/editSubjects', data)
}

deleteSubjects(id){
  return this.httpClient.delete(Constant.API_ORIGIN_URL+'/admin/deleteSubjects/' + id)
}

// Accountants

getAccountants(id){
  return this.httpClient.get(Constant.API_ORIGIN_URL+'/admin/getAccountantsByBusinessId/' + id)
}
addAccountants(data){
  return this.httpClient.post(Constant.API_ORIGIN_URL+'/admin/addAccountant', data)
}
updateAccountants(data){
  return this.httpClient.post(Constant.API_ORIGIN_URL+'/admin/editAccountant', data)
}
deleteAccountants(id){
  return this.httpClient.post(Constant.API_ORIGIN_URL+'/admin/removeAccountants/',id)
}

// Librarian
viewLibrarian(id){
  return this.httpClient.get(Constant.API_ORIGIN_URL+'/admin/viewLibrarians/' + id)
}
addLibrarian(data){
  return this.httpClient.post(Constant.API_ORIGIN_URL+'/admin/addLibrarians', data)
}
updateLibrarian(data){
  return this.httpClient.post(Constant.API_ORIGIN_URL+'/admin/editLibrarians', data)
}
deleteLibrarian(id){
  return this.httpClient.delete(Constant.API_ORIGIN_URL+'/admin/removeLibrarians/' + id)
}

  // Email

  // Compose
  sendMail(mail){
    return this.httpClient.post(Constant.API_ORIGIN_URL+'/email/compose', mail)
  }

  // sent
  mailsSent(id){
    return this.httpClient.get(Constant.API_ORIGIN_URL+'/email/mails/' + id)
  }

  // inbox
  mailsReceive(id){
    return this.httpClient.get(Constant.API_ORIGIN_URL+'/email/mails/' + id)
  }
  
  // delete mail
  mailsDelete(id){
    return this.httpClient.delete(Constant.API_ORIGIN_URL+'/email/deleteMail/' + id)
  }

  // Professor 

  viewProfessorList(id){
    return this.httpClient.get(Constant.API_ORIGIN_URL+'/admin/getProfessorsByBusinessId/' + id)
  }
  addProfessorList(data){
    return this.httpClient.post(Constant.API_ORIGIN_URL+'/admin/addProfessor', data)
  }
  updateProfessorList(data){
    return this.httpClient.post(Constant.API_ORIGIN_URL+'/admin/editProfessor', data)
  }
  
  deleteProfessorList(id){
    return this.httpClient.delete(Constant.API_ORIGIN_URL+'/admin/removeProfessor/' + id)
  }

  importProfessorList(formData){
    return this.httpClient.post(Constant.API_ORIGIN_URL+'/excel/saveProfessor', formData, {
      reportProgress: true,
      observe: 'events'
    })
  }

  // Student
  viewStudentList(id){
    return this.httpClient.get(Constant.API_ORIGIN_URL+'/admin/getStudentByBusinessId/' + id)
  }
  addStudentList(data){
    return this.httpClient.post(Constant.API_ORIGIN_URL+'/admin/addStudent', data)
  }
  updateStudentList(data){
    return this.httpClient.post(Constant.API_ORIGIN_URL+'/admin/editStudent', data)
  }
  deleteStudentList(id){
    return this.httpClient.delete(Constant.API_ORIGIN_URL+'/admin/removeStudent/' +id)
  }
 
  uploadfile(formData){
    return this.httpClient.post(Constant.API_ORIGIN_URL+'/excel/saveStudent', formData, {
      reportProgress: true,
      observe: 'events'
    })
  }
    // Infrastructure
    viewInfrastructure(id){
      return this.httpClient.get(Constant.API_ORIGIN_URL+'/admin/getInfraStructure/' + id)
    }
    // addInfrastructure(data){
    //   return this.httpClient.post(Constant.API_ORIGIN_URL+'/admin/addInfraStructure', data)
    // }
    // updateInfrastructure(data){
    //   return this.httpClient.post(Constant.API_ORIGIN_URL+'/admin/editInfraStructure', data)
    // }
    deleteInfrastructure(id){
      return this.httpClient.post(Constant.API_ORIGIN_URL+'/admin/removeInfrastructure/' ,id)
    }

    // Lab Assistants
    viewLabAssitnt(id){
      return this.httpClient.get(Constant.API_ORIGIN_URL+'/admin/viewLabassistants/' + id)
    }
    addLabAssitnt(data){
      return this.httpClient.post(Constant.API_ORIGIN_URL+'/admin/addLabAssistants', data)
    }
    updateLabAssitnt(data){
      return this.httpClient.post(Constant.API_ORIGIN_URL+'/admin/editLabAssistants', data)
    }
    deleteLabAssitnt(id){
      return this.httpClient.delete(Constant.API_ORIGIN_URL+'/admin/removeLabAssistants/' +id)
    }

  // Staff Mapping
  viewStaffList(id){
    return this.httpClient.post(Constant.API_ORIGIN_URL+'/admin/viewStaffMapping', id)
  }
  addStaffList(data){
    return this.httpClient.post(Constant.API_ORIGIN_URL+'/admin/addStaffMapping', data)
  }
  updateStaffList(data){
    return this.httpClient.post(Constant.API_ORIGIN_URL+'/admin/editStaffMapping', data)
  }
  deleteStaffList(id){
    return this.httpClient.delete(Constant.API_ORIGIN_URL+'/admin/deleteStaffMapping/' +id)
  }

  // Employees
  viewEmployeesList(id){
    return this.httpClient.get(Constant.API_ORIGIN_URL+'/admin/viewOtherEmployees/' + id)
  }
  addEmployeesList(data){
    return this.httpClient.post(Constant.API_ORIGIN_URL+'/admin/addOtherEmployees', data)
  }
  updateEmployeesList(data){
    return this.httpClient.post(Constant.API_ORIGIN_URL+'/admin/editOtherEmployees', data)
  }
  deleteEmployeesList(id){
    return this.httpClient.delete(Constant.API_ORIGIN_URL+'/admin/removeOtherEmployees/' +id)
  }
}
