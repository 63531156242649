import { Component, OnInit } from '@angular/core';
import { AdminService } from '../admin.service';
import Swal from 'sweetalert2'
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-mail',
  templateUrl: './mail.component.html',
  styleUrls: ['./mail.component.scss']
})
export class MailComponent implements OnInit {

  constructor(private service : AdminService) { }

  mesgReceive=false;
  mesgSent=false;
  
    sender:any;
    receiver:any;
    subject:any;
    message:any;
  
    sentTo:any;
    receiveFrom:any;
    sentSubject:any;
    sentMessage:any;
  
  
  receiveMesg(data){
  this.mesgReceive=true;
  this.mesgSent=false;
  
  this.sender = data.sender
    this.receiver = data.receiver
    this.subject = data.subject
    this.message = data.message
  }
  
  sendMesg(data){
    this.mesgReceive=false;
    this.mesgSent=true; 
    this.sentTo = data.sender
      this.receiveFrom = data.receiver
      this.sentSubject = data.subject
      this.sentMessage = data.message
  }
  
  sendTo
  sentFrom
  sub
  msg
  
  id = localStorage.getItem("userId");
  // bid = localStorage.getItem("busId");
  
  sendMail(){
    let mail = {
      sender:this.sendTo,
      receiver:this.sentFrom,
      subject:this.sub,
      message:this.msg,
      // time:"",
      // label:"",
      // businessId:this.bid,
      userName:this.id
    }
    this.service.sendMail(mail).subscribe((data:any) =>  {
      if(data.success = true){
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Mail Sent Successfully',
          showConfirmButton: false,
          timer: 1500
        })
        this.ngOnInit();
        console.log("mail", data)
      };
    });
   
  }
  
  mailsSent(){
    // this.inbox=false;
    //   this.sent=true;
      this.mesgReceive=false;
      this.mesgSent=false;
  }
  
  mailsReceive(){
      // this.inbox=true;
      // this.sent=false;
      this.mesgReceive=false;
      this.mesgSent=false;
  
  }
  
  deleteSentMail(id){
    this.service.mailsDelete(id).subscribe((data:any) => {
      if(data.success = true){
        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.value) {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
          }
          this.ngOnInit();
        })
        console.log("del", data);
      }
    })
  }
  
  // completefields(addFields:NgForm){
  //   addFields.reset();
  // }
  
  deleteRecMail(id){
    this.service.mailsDelete(id).subscribe((data:any) => {
      if(data.success = true){
        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.value) {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
          }
          this.ngOnInit();
        })
        console.log("del", data);
      }
    })
  }
  
  
  receiveMail:any=[]
  sentMail:any=[]
  
    ngOnInit() {
  
      this.service.mailsSent(this.id).subscribe((data:any) => {
        this.sentMail = data.data.sentBox
        console.log("sent_mails", this.sentMail)
        this.sendMesg(this.sentMail);
      });
  
       
    this.service.mailsReceive(this.id).subscribe((data:any) => {
      this.receiveMail = data.data.inbox
      console.log("receive_mails", this.receiveMail)
      this.receiveMesg(this.receiveMail)
      })
      
    }
  }
