import { Component, OnInit } from '@angular/core';
import { HostelService } from '../hostel.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {

  constructor(private hService:HostelService) { }

  search:any;
  
  bid = localStorage.getItem("busId");

  viewFeedbk:any = [];
  ngOnInit() {
    this.hService.viewFeedback(this.bid).subscribe((data:any) => {
      this.viewFeedbk = data.data
      console.log("View_Feedback", this.viewFeedbk);
    })
  }

}
