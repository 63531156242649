import { Component, OnInit } from '@angular/core';
import { HostelService } from '../hostel.service';
import { DatePipe } from '@angular/common';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-allocation',
  templateUrl: './allocation.component.html',
  styleUrls: ['./allocation.component.scss'],
  providers: [
    DatePipe
  ]
})
export class AllocationComponent implements OnInit {

  constructor(private hService:HostelService, private datePipe: DatePipe) { }

  search:any;
  
  viewAllocate=true;
  addAlloctn=false;

  addAllocate(){
    this.viewAllocate=false;
    this.addAlloctn=true;
  }
  back(addFields:NgForm){
    this.viewAllocate=true;
    this.addAlloctn=false;
    addFields.reset();
  }

  studID
  studName
  cntNo
  parntName
  pCntNo
  add
  cour
  dept
  sem
  yr
  bName
  rNum
  bnum
  fee
  term
  mFee
  pFee
  paymntDate
  nxtPaymntDate

  bid = localStorage.getItem("busId");

  stdId
  stdName
  cntNum
  prntName
  prtCntNo
  addrs
  cours
  deprt
  semtr
  year
  bdName
  rmNum
  bdnum
  fees
  terms
  msFee
  pdFee
  paymtDate
  nxtPaymtDate

  allocteAdd(){
    let add = {
      studentId:this.studID,
      studentName:this.studName,
      phoneNumber:this.cntNo,
      parentName:this.parntName,
      parentPhoneNumber:this.pCntNo,
      address:this.add,
      course:this.cour,
      department:this.dept,
      year:this.yr,
      semester:this.sem,
      buildingName:this.bName,
      roomNo:this.rNum,
      bedNo:this.bnum,
      fees:this.fee,
      noOfTerms:this.term,
      messFees:this.mFee,
      paidFees:this.pFee,
      paymentDate:this.formatedDate,
      nextPaymentDate:this.nxtPaymntDate,
      businessId:this.bid
    }
    console.log("before", add);
    
    this.hService.addStudentAllotmnt(add).subscribe((data:any) => {
      console.log("after", data);
      alert("Submitted Successfully");
      
      this.ngOnInit();
    });
  }

  
  completefields(addFields:NgForm){
    this.viewAllocate=true;
    this.addAlloctn=false;
    addFields.reset();
  }
  
  formatedDate:any;
  onSubmit(){
    this.formatedDate =  this.datePipe.transform(this.paymntDate, "dd/MM/yyyy");
   }
  
   editAllot(data){
    this.stdId = data.studentId
    this.stdName = data.studentName
    this.cntNum = data.phoneNumber
    this.prntName = data.parentName
    this.prtCntNo = data.parentPhoneNumber
    this.addrs = data.address
    this.cours = data.course
    this.deprt = data.department
    this.semtr = data.semester
    this.year = data.year
    this.bdName = data.buildingName
    this.rmNum = data.roomNo
    this.bdnum = data.bedNo
    this.fees = data.fees
    this.terms = data.noOfTerms
    this.msFee = data.messFees
    this.pdFee = data.paidFees
    this.paymtDate = data.paymentDate
    this.nxtPaymtDate = data.nextPaymentDate
  }

  updateAllot(){
    let updte = {
      studentId:this.stdId,
      studentName:this.stdName,
      phoneNumber:this.cntNum,
      parentName:this.prntName,
      parentPhoneNumber:this.prtCntNo,
      address:this.addrs,
      course:this.cours,
      department:this.deprt,
      year:this.year,
      semester:this.semtr,
      buildingName:this.bdName,
      roomNo:this.rmNum,
      bedNo:this.bdnum,
      fees:this.fees,
      noOfTerms:this.terms,
      messFees:this.msFee,
      paidFees:this.pdFee,
      paymentDate:this.paymtDate,
      nextPaymentDate:this.nxtPaymtDate,
      businessId:this.bid
    }
    console.log("before", updte);
    this.hService.updateStudentAllotmnt(updte).subscribe((data:any) => {
      if (data.success = true) {
        console.log("Update_Allotment", data);
        this.ngOnInit();
        }
    })
    
  }
  
  deleteAllot(id){
    this.hService.deleteStudentAllotmnt(id).subscribe((data:any) => {
      console.log("Del_Allotment", data);
      alert("deleted");
      this.ngOnInit();
    });
  }

  viewStudAllot:any =[];
  ngOnInit() {
    this.hService.viewStudentAllotmnt(this.bid).subscribe((data:any) => {
      this.viewStudAllot = data.data
      console.log("View_Student_Allotments", this.viewStudAllot) 
    });
  }

}
