import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-hostel-home',
  templateUrl: './hostel-home.component.html',
  styleUrls: ['./hostel-home.component.scss']
})
export class HostelHomeComponent implements OnInit {

  constructor(private router:Router) { }

  profileName = localStorage.getItem("userId")

  logOut() {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('busId');
    this.router.navigate(['/']);
  }

  ngOnInit() {
  }

}
