import { Component, OnInit } from '@angular/core';
import { AdminService } from '../admin.service';
import Swal from 'sweetalert2'
import { NgForm, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-lab-assistant',
  templateUrl: './lab-assistant.component.html',
  styleUrls: ['./lab-assistant.component.scss']
})
export class LabAssistantComponent implements OnInit {
  
  hide = true;
  hide1 = true;

  constructor(private adminService : AdminService) { }

  search:any;

  emailId = new FormControl('', [Validators.required, Validators.email]);
  
  getErrorMessage() {
    return this.emailId.hasError('required') ? 'Enter a valid Email' :
        this.emailId.hasError('email') ? 'Not a valid email' :
            '';
  }
  
  bId = localStorage.getItem("busId")

  view=true;
  addLabAss=false;

  add(){
    this.view=false;
    this.addLabAss=true;
  }
  back(){
    this.view=true;
    this.addLabAss=false;
  }

  fName:any;
  lName:any;
  email:any;
  gender:any;
  dob:any;
  adrs:any;
  con:any;
  empId:any;
  pass:any;
  cPass:any;
  sub:any;
  des:any;
  yrJn:any;
  sal:any;
  amtPd:any;

  addLabAssistant(){
    let add = {
      employeeId:this.empId,
      firstName:this.fName,
      lastName:this.lName,
      gender:this.gender,
      email:this.email,
      contactNumber:this.con,
      // dob:this.dob,
      address:this.adrs,
      password:this.pass,
      confirmPassword:this.cPass,
      designation:this.des,
      amountPaid:this.amtPd,
      taughtSubjects:this.sub,
      yearJoined:this.yrJn,
      totalSalary:this.sal,
      businessId:this.bId,
      userName:this.empId
    }
    console.log("before", add);
    if(this.pass === this.cPass){

    this.adminService.addLabAssitnt(add).subscribe((data:any) => {
      if(data.success = true){
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Submitted Successfully',
          showConfirmButton: false,
          timer: 1500
        })
        
        this.ngOnInit();
        console.log("after", data);
      };
    })
  }else{
    alert("password not matching")
  }
  }

  completefields(addFields:NgForm){
    this.view=true;
        this.addLabAss=false;
    addFields.reset();
  }

  ftName:any;
  ltName:any;
  ema:any;
  gen:any;
  adrss:any;
  num:any;
  emplyId:any;
  subj:any;
  desgn:any;
  yrJnd:any;
  saly:any;
  amtPaid:any;
  id:any;


  editList(assistant){
    this.emplyId = assistant.employeeId
    this.ftName = assistant.firstName
    this.ltName = assistant.lastName
    this.gen = assistant.gender
    this.ema = assistant.email
    this.adrss = assistant.address
    this.num = assistant.contactNumber
    this.desgn = assistant.designation
    this.saly = assistant.totalSalary
    this.yrJnd = assistant.yearJoined
    this.subj = assistant.taughtSubjects
    this.amtPaid = assistant.amountPaid
    this.id = assistant.id
  }
  
  updateLabAssitnt(){
    let updt = {
      employeeId:this.emplyId,
      firstName: this.ftName,
      lastName: this.ltName,
      contactNumber: this.num,
      email: this.ema,
      address: this.adrss,
      gender: this.gen,
      totalSalary: this.saly,
      yearJoined: this.yrJnd,
      taughtSubjects: this.subj,
      // designation:this.desgn,
      // businessId:this.bId,
      amountPaid:this.amtPaid,
      id:this.id
    }
    console.log("before", updt);
    this.adminService.updateLabAssitnt(updt).subscribe((data:any) =>{
      this.ngOnInit();
      console.log("after", data);
    })
  }

  deleteList(id){
    this.adminService.deleteLabAssitnt(id).subscribe((data:any) =>{
      if(data.success = true){
        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.value) {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
          }
          this.ngOnInit();
        })
        console.log("Deleted", data);
      }
    })
  }

  viewList:any =[];
  subList:any =[]
  ngOnInit() {
    this.adminService.viewLabAssitnt(this.bId).subscribe((data:any) => {
      this.viewList = data.data
      console.log("View Lab Assistant list", this.viewList);      
    })

    this.adminService.viewSubjectList(this.bId).subscribe((data : any) =>{
      this.subList = data.data;
  })
  }

}
