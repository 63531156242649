import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from '../../../home/main/main.component';
import { LoginComponent } from '../../../home/login/login.component';
import { AdminComponent } from '../admin.component';
import { ProfileComponent } from '../profile/profile.component';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { StudentsComponent } from '../students/students.component';
import { ProfessorsComponent } from '../professors/professors.component';
import { StaffMappingComponent } from '../staff-mapping/staff-mapping.component';
import { AccountantsComponent } from '../accountants/accountants.component';
import { CoursesComponent } from '../courses/courses.component';
import { DepartmentsComponent } from '../departments/departments.component';
import { SectionsComponent } from '../sections/sections.component';
import { SubjectsComponent } from '../subjects/subjects.component';
import { EmployeesComponent } from '../employees/employees.component';
import { HolidaysComponent } from '../holidays/holidays.component';
import { LibraryComponent } from '../library/library.component';
import { InfrastructureComponent } from '../infrastructure/infrastructure.component';
import { LabAssistantComponent } from '../lab-assistant/lab-assistant.component';
import { MailComponent } from '../mail/mail.component';

const routes: Routes = [
  { path:'', component: MainComponent },
  { path:'login', component: LoginComponent },
  { path:'admin', component: AdminComponent, 
    children:[
              { path:'dashboard', component: DashboardComponent},
              { path:'students', component: StudentsComponent},
              { path:'professors', component: ProfessorsComponent},
              { path:'staff-mapping', component: StaffMappingComponent},
              { path:'accountants', component: AccountantsComponent},
              { path:'courses', component: CoursesComponent},
              { path:'departments', component: DepartmentsComponent},
              { path:'sections', component: SectionsComponent},
              { path:'subjects', component: SubjectsComponent},
              { path:'staff', component: EmployeesComponent},
              { path:'holidays', component: HolidaysComponent},
              { path:'librarian', component: LibraryComponent},
              { path:'infrastructure', component: InfrastructureComponent},
              { path:'lab-assistants', component: LabAssistantComponent},
              { path:'email', component: MailComponent},
             ]
  },
  { path:'adminProfile', component: ProfileComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingRoutingModule { }
