import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SuperAdminComponent } from '../super-admin.component';
import { MainComponent } from '../../../home/main/main.component';
import { LoginComponent } from '../../../home/login/login.component';
import { SchoolsComponent } from '../schools/schools.component';
import { CollegesComponent } from '../colleges/colleges.component';
import { EmailComponent } from '../email/email.component';
import { AdminsComponent } from '../admins/admins.component';
import { DashboardComponent } from '../dashboard/dashboard.component';

const routes: Routes = [
  { path:'', component: MainComponent },
  { path:'login', component: LoginComponent },
  { path:'super-admin', component: SuperAdminComponent, children:[
    { path: 'dashboard', component: DashboardComponent},
    { path: 'schools', component: SchoolsComponent},
    { path: 'colleges', component: CollegesComponent},
    { path: 'admins', component: AdminsComponent},
    { path: 'email', component: EmailComponent},
  ]
   
}


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SuperAdminRoutingRoutingModule { }
