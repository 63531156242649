import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProfessorService } from '../../../professor.service';

@Component({
  selector: 'app-gate',
  templateUrl: './gate.component.html',
  styleUrls: ['./gate.component.scss']
})
export class GateComponent implements OnInit {

  constructor(private pService: ProfessorService, private router:Router) { }

  monthlyAtd;
  
  gate = true;
  stdList = false;
  month=false;
  date=false;
  monthTable=false;
  
  bId = localStorage.getItem("busId")
  professorId = localStorage.getItem("userId")

  backTo(){
    this.router.navigate(['/professor/attendance/student-attendance']);   
  }
  backToSem(){
    this.gate=true;
    this.stdList=false;
  }
  mon(){
    this.month = true
    this.date = false
    this.monthTable=false
  }
  dt(){
    this.month = false
    this.date = true
    this.monthTable=false
  }

  studList:any=[]
  studentList(yr,sm,sec,dp,cr){
    this.gate=false;
    this.stdList=true;
    
    let view = {
      year:yr,
      semester:sm,
      section:sec,
      department:dp,
      businessId:this.bId,
      course:cr
    }
    console.log("b", view);
    this.pService.studentList(view).subscribe((data:any) => {
      this.studList = data.data
      console.log("studlist", this.studList);  
    })
  }

  monthName=[
    "January","February","March","April","May","June","July ","August","September","October","November","December"
  ]

  std;
  mont;

  newWat:any;
  weeklyAtt:any;
  weeklyAttendance: any =[]

  mTab(){
  this.monthTable=true
    let a ={
      userName: this.std,
      month:this.mont
    }
    console.log("b", a);
     this.pService.weekAttnd(a).subscribe((data:any) => {
      this.weeklyAttendance = data.data
      let wAt = data.data
      this.newWat = []
      // this.weeklyAtt = []
      console.log("newWeeks-----", this.newWat);
      console.log("present absent",this.weeklyAtt);
      
      for (let obj of wAt) {      
        // console.log("weekkkk----------", obj);    
        // this.weeklyAtt.push(obj)
        for(let day in obj){
          console.log("dayyyy-------",day);  
          var weeks = day.split('');
          console.log("Only Weeksss-------", weeks);     
          this.newWat.push(day)         
        }      
      }
      console.log("weekly attendance",this.weeklyAttendance);      
    })
  }


  viewList:any = []
  ngOnInit() {
    this.pService.semList(this.professorId).subscribe((sub:any) => {
      this.viewList = sub.data
      console.log("view", this.viewList)
    });
  }
  
}
