import { Component, OnInit } from '@angular/core';
import { ProfessorService } from '../professor.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {

  constructor(private pService:ProfessorService) { }
  
  search:any;
  
  feedback=true;
  addFeedback=false;

  feedbk(){
    this.feedback=false;
    this.addFeedback=true;
    }
    back(){
      this.feedback=true;
     this.addFeedback=false;
    }

    
    studId:any;
    sub:any;
    comt:any;
    rating:any;
    date:any;
    id = localStorage.getItem("userId");
    bid = localStorage.getItem("busId");

    submitFeedbk(addFields:NgForm){
      let fb = {
        studentId:this.studId,
        comments:this.comt,
        commentsgivenby:this.id,
        subject:this.sub,
        rating:this.rating,
        businessId:this.bid,
        date:this.date
      }
     
    this.pService.addStudentFeedback(fb).subscribe((data:any) => {
      console.log("feedback", data)
      alert("submitted");
      addFields.reset();
      this.feedback=true;
      this.addFeedback=false;
      this.ngOnInit();
    })
  }
  viewFeedbk:any =[];
  ngOnInit() {
    this.pService.viewStudentFeedback(this.id).subscribe((data:any) => {
      this.viewFeedbk = data.data
      console.log("View_Student_Feedback", this.viewFeedbk);
      
    })
  }

}
