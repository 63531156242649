import { Injectable } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Constant } from '../../config';

@Injectable({
  providedIn: 'root'
})
export class LibrarianService {

  constructor(
    private httpClient: HttpClient
  ) { }


  addBook(data){
    // return this.httpClient.post("http://13.58.55.216:8080/collage-management/collage/library/addBook",data)
    return this.httpClient.post(Constant.API_ORIGIN_URL+ "/library/addBook",data)
  }

  getAllLibraryBooks(id){
    // return this.httpClient.get("http://13.58.55.216:8080/collage-management/collage/library/getBooksByBusinessId/"+id)
    return this.httpClient.get(Constant.API_ORIGIN_URL+ "/library/getBooksByBusinessId/"+id)
  }


  deleteLibraryBook(id){
    // return this.httpClient.delete("http://13.58.55.216:8080/collage-management/collage/library/returnBooks/ "+id)
    return this.httpClient.delete(Constant.API_ORIGIN_URL+ "/library/removeBooks/"+id)
  }


  getLendLibraryBooks(id){
    return this.httpClient.get(Constant.API_ORIGIN_URL+ "/library/getAllLendBooks/"+id)
    // return this.httpClient.get("http://13.58.55.216:8080/collage-management/collage/library/getAllLendBooks/"+id)
  }
 
  updateLibraryBooks(data){
    return this.httpClient.post(Constant.API_ORIGIN_URL+ "/library/uploadBooks",data)
  }

  //Profile

  getLprofile(id){
    // return this.httpClient.get("http://13.58.55.216:8080/collage-management/collage/library/viewProfile/"+id)
    return this.httpClient.get(Constant.API_ORIGIN_URL+ "/library/viewProfile/"+id)
  }

  updateLprofile(data){
    // return this.httpClient.get("http://13.58.55.216:8080/collage-management/collage/library/editProfile",id)
    return this.httpClient.get(Constant.API_ORIGIN_URL+ "/library/editProfile",data)
  }

  
  // Email
  
  // Compose
  sendMail(mail){
    return this.httpClient.post(Constant.API_ORIGIN_URL+ "/email/compose", mail)
  }

  // sent
  mailsSent(id){
    return this.httpClient.get(Constant.API_ORIGIN_URL+ "/email/mails/" + id)
  }

  // inbox
  mailsReceive(id){
    return this.httpClient.get(Constant.API_ORIGIN_URL+ "/email/mails/" + id)
  }
  
  // delete mail
  mailsDelete(id){
    return this.httpClient.delete(Constant.API_ORIGIN_URL+ "/email/deleteMail/" + id)
  }
}
