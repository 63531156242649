import { Component, OnInit } from '@angular/core';
import { HostelService } from '../hostel.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-outing-pass',
  templateUrl: './outing-pass.component.html',
  styleUrls: ['./outing-pass.component.scss']
})
export class OutingPassComponent implements OnInit {

  constructor(private hService:HostelService) { }

  search:any;
  
  viewPass=true;
  addPass=false;

  addOutgPass(){
    this.viewPass=false;
    this.addPass=true;
  }
  back(addFields:NgForm){
    this.viewPass=true;
    this.addPass=false;
    addFields.reset();
  }

  studID
  bName
  rmNum
  chInTm
  chOtTm
  chItDt
  chOtDt
  resn

  bid = localStorage.getItem("busId");
  
  stdId
  bdngName
  romNum
  chInTim
  chOtTim
  chItDte
  chOtDte
  reson
  
  passAdd(){
    let pass = {
      studentId:this.studID,
      buildingName:this.bName,
      roomNo:this.rmNum,
      checkInTime:this.chInTm,
      checkOutTime:this.chOtTm,
      checkInDate:this.chItDt,
      checkOutDate:this.chOtDt,
      reason:this.resn,
      businessId:this.bid
    }
    console.log("before", pass);
    this.hService.addOutingPass(pass).subscribe((data:any) => {
      console.log("after", data);
      alert("Submitted Successfully");
      
      this.ngOnInit();
    })
  }
  completefields(addFields:NgForm){
    this.viewPass=true;
      this.addPass=false;
    addFields.reset();
  }

  editOutgPass(data){
    this.stdId = data.studentId
    this.bdngName = data.buildingName
    this.romNum = data.roomNo
    this.chInTim = data.checkInTime
    this.chOtTim = data.checkOutTime
    this.chItDte = data.checkInDate
    this.chOtDte = data.checkOutDate
    this.reson = data.reason
  }

  updatePass(){
    let Updt = {
      studentId:this.stdId,
      buildingName:this.bdngName,
      roomNo:this.romNum,
      checkInTime:this.chInTim,
      checkOutTime:this.chOtTim,
      checkInDate:this.chItDte,
      checkOutDate:this.chOtDte,
      reason:this.reson,
      businessId:this.bid
    }
    console.log("before", Updt);
    this.hService.updateOutingPass(Updt).subscribe((data:any) => {
      console.log("after", data);
      this.ngOnInit();
    })

  }
  deleteOtPass(id){
    this.hService.deleteOutingPass(id).subscribe((data:any) => {
      console.log("deleted", data);
      alert("Deleted");
      this.ngOnInit();
    })
  }
  viewOutngPass:any = []; 
  ngOnInit() {
    this.hService.viewOutingPass(this.bid).subscribe((data:any) => {
      this.viewOutngPass = data.data
      console.log("view_outing_pass_list", this.viewOutngPass);
      
    })
  }

}
