import { Component, OnInit } from '@angular/core';
import { NgStyle } from '@angular/common';
import { SuperAdminService } from '../super-admin.service';
import { Router } from '@angular/router';
import {FormControl , Validators, NgForm} from '@angular/forms';
import Swal from 'sweetalert2'


@Component({
  selector: 'app-admins',
  templateUrl: './admins.component.html',
  styleUrls: ['./admins.component.scss']
})
export class AdminsComponent implements OnInit {

  search:any;


  email = new FormControl('', [Validators.required, Validators.email]);
  constructor(private service : SuperAdminService , private router : Router) {  }


  getErrorMessage() {
    return this.email.hasError('required') ? 'You must enter a value' :
        this.email.hasError('email') ? 'Not a valid email' :
            '';
  }

  empId : any
  busId : any
  nam : any
  des : any
  phone : any
  ema : any
  unam : any
  adrs : any
  gender : any
  sal : any
  qual : any
  yearJoin : any
  dob : Date
  branch : any
  pass : any
  cPass : any

    // emplId = localStorage.getItem("emp"); 
    // b = localStorage.getItem("b");
   
  

  na : any
  desn : any
  phn : any
  e : any
  una : any
  ad : any
  slry : any
  qu : any
  yj : any
  db : any
  br : any
  pwd : any
  emId : any
  bId : any

   


  addAdmin(){    
    // var dob: any = new Date(this.data.nextPaymentDate),
    //   longFormat: any = dob * 1;
         let  admData ={
          employeeId : this.empId,
          businessId : this.busId,
          name : this.nam,
          designation : this.des,
          phoneNumber : this.phone,
          email : this.ema,
          userName : this.empId,
          gender : this.gender,
          salary : this.sal,
          address : this.adrs,
          qualification : this.qual,
          yearJoined : this.yearJoin,
          dob : this.dob,
          branchName : this.branch,
          password : this.pass,
          confirmPassword : this.cPass
         }
         console.log("add" ,admData);         
        this.service.addAdmin(admData).subscribe((admin :any)=>{            
             console.log("admins" ,admin);
            //  localStorage.setItem("emp" ,admin.data.employeeId);
            if(admin.success = true){
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Submitted Successfully',
                showConfirmButton: false,
                timer: 1500
              })
              console.log("admins" ,admin);
              this.addAdmins = false;
            this.viewAdmins = true;  
            this.ngOnInit();
            };                    
            
            //  localStorage.setItem("emp" ,admin.data.employeeId);
            //  localStorage.setItem("b" ,admin.data.businessId);
            //  document.getElementById("myForm").reset();
            // alert("Admin added successfully...");
        } ) 
  }
  
  completefields(addFields:NgForm){
    addFields.reset();
  }
  cancel(addFields:NgForm){
    addFields.reset();
  }  

  updateAdmin(){
      let adDetails = {   
        name : this.na,
        designation : this.desn,
        phoneNumber : this.phn,
        email : this.e,
        userName : this.una,
        address : this.ad,
        salary : this.slry,
        qualification : this.qu,
        yearJoined : this.yj,
        dob : this.db,
        branchName : this.br,
        password : this.pwd,
        employeeId : this.emId,
        businessId : this.bId
      }      
      this.service.editAdmin(adDetails).subscribe((update : any) =>{
           console.log("edit admins" , update);  
           this.ngOnInit();         
        if(update.success = true){
          this.na = update.data.name
          this.desn = update.data.designation
          this.phn = update.data.phoneNumber
          this.e = update.data.email
          this.una = update.data.userName
          this.ad = update.data.address
          this.slry = update.data.salary
          this.qu = update.data.qualification
          this.yj = update.data.yearJoined
          this.db = update.data.dob
          this.br = update.data.branchName
          this.pwd = update.data.password
          // this.emplId = update.data.employeeId
          this.busId = update.data.businessId
        }
      })
  }

  delAdmin(emplId){
     console.log("employe" , emplId);     
    this.service.deleteAdmin(emplId).subscribe((data: any) =>{
      if(data.success = true){
        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.value) {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
          }
          this.ngOnInit();
        })
        console.log("deleted admin" , data);
      }
    })
  }

 
  addAdmins = false; 
  viewAdmins = true;

  admins(){ 
    this.viewAdmins = false;  
    this.addAdmins = true;
  }

  backA(){
    this.addAdmins = false;
    this.viewAdmins = true;
  }

  editAdmin(data){
    this.na = data.name
    this.desn = data.designation
    this.phn = data.phoneNumber
    this.e = data.email
    this.una = data.userName
    this.ad = data.address
    this.slry = data.salary
    this.qu = data.qualification
    this.yj = data.yearJoined
    this.db = data.dob
    this.br = data.branchName
    this.pwd = data.password
    this.emId = data.employeeId
    this.bId = data.businessId
  }

 
  allAdmins : any =[];
  genderList;
  ngOnInit() {
    this.service.viewAdmins().subscribe((admins : any) =>{
         this.allAdmins = admins.data;
         console.log("all admins" ,this.allAdmins);
        //  localStorage.setItem("emp" ,admins.data.employeeId);
    })


    // this.dob = new Date().toISOString().split('T')[0];

    // this.genderList = [
    //   {Name:"Male"},
    //   {Name :"Female"}
    // ]
  }

}
