import { Component, OnInit } from '@angular/core';
import { LibrarianService } from './librarian.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-librarian',
  templateUrl: './librarian.component.html',
  styleUrls: ['./librarian.component.scss']
})
export class LibrarianComponent implements OnInit {

  constructor(
    private lService: LibrarianService,
    private router: Router

  ) { }
  
  books = true;
  availability = false;
  backBtnFalse = false;
  allBtnTrue = true;
  all() {
    this.books = false;
    this.availability = true;
    this.backBtnFalse = true;
    this.allBtnTrue = false;
  }
  avail() {
    this.books = true;
    this.availability = false;
    this.backBtnFalse = false;
    this.allBtnTrue = true;
  }

  logOut(){
    localStorage.removeItem('token');
    localStorage.removeItem('busId');
    localStorage.removeItem('userId');
    this.router.navigate(['/']);
  }

  
  profileId = localStorage.getItem("userId");
  firstName:any;
  ngOnInit() {
    this.lService.getLprofile(this.profileId).subscribe((data:any) => {
      if(data.success = true){
          this.firstName = data.data.firstName   
      }
    })
  }

}
