import { Component, OnInit } from '@angular/core';
import { ProfessorService } from '../professor.service';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-academics',
  templateUrl: './academics.component.html',
  styleUrls: ['./academics.component.scss']
})
export class AcademicsComponent implements OnInit {

  constructor(private pService: ProfessorService, private router:Router) { }
  
  searchPro:any;
  searchLec:any;
  searchNoti:any;

  dep:any;
  yr:any;
  sem:any;
  cour:any;
  sec:any;
  subj:any;
  sDate:any;
  eDate:any;
  les:any;
  topic:any;
  time:any;

  id;
  editCour:any;
  editDep:any;
  editYr:any;
  editSem:any;
  editSec:any;
  editSub:any;
  editStDate:any;
  editEdDate:any;
  editLes:any;
  editTopic:any;
  editTime:any;

  lecCour:any;
  lecDep:any;
  lecYear:any;
  lecSem:any;
  lecSec:any;
  lecSub:any;
  lesLec:any;
  lecTopic:any;
  desc:any;
  
  rowId;
  editLecCour
  editLecDep
  editLecYear
  editLecSem
  editLecSec
  editLecSub
  editLesLec
  editLecTopic
  editDesc

  notiCour:any;
  notiDep:any;
  notiYear:any;
  notiSem:any;
  notiSec:any;
  notiSub:any;
  date:any;
  notify:any;
 
  editNotiCour
  editNotiDep
  editNotiYear
  editNotiSem
  editNotiSec
  editNotiSub
  editChpt
  editNotify

  bId = localStorage.getItem("busId")
  professorId = localStorage.getItem("userId")
  
  subjects=true;
  view=false;
  addSyl=false;
  addLect=false;
  addNoti=false;
 
  add(){
    this.view=false;
    this.addSyl=true;
  }
  addLec(){
    this.view=false;
    this.addLect=true;
  }
  addNotif(){
    this.view=false;
    this.addNoti=true;
    this.addLect=false;
  }
  back(){
    this.view=true;
    this.addSyl=false;
    this.addNoti=false;
    this.addLect=false;
  }
  backTo(){
    this.view=false;
    this.subjects=true;
  }

   viewSyllb:any=[]
   viewLecNote:any=[]
   viewList:any=[]

   viewDetails(subject){
    this.subjects=false;
    this.view=true;
    this.subj=subject
    this.lecSub=subject
    this.notiSub=subject
    console.log("sub", this.subj);
    
    this.pService.viewSyllabus(subject).subscribe((syllabus:any) => {
      this.viewSyllb = syllabus.data
      console.log("View_Syllabus", this.viewSyllb)
      this.getSyllabus(this.viewSyllb)
    });
    
    this.pService.viewLectureNotes(subject).subscribe((note:any) => {
      this.viewLecNote = note.data
      console.log("View_Lecture_Notes", this.viewLecNote)
      this.getLecNote(this.viewLecNote)
    });

    this.pService.viewSubjectByDepartment(subject).subscribe((subj:any) => {
      this.viewList = subj.data
      console.log("View_Subjects_list", this.viewList)
    });

 
   }
  
  syllAdd(){
    
    let details = {
    department:this.dep,
    year:this.yr,
    semester:this.sem,
    course:this.cour,
    subject:this.subj,
    startDate:this.sDate,
    endDate:this.eDate,
    topic:this.topic,
    section:this.sec,
    time:this.time,
    lession:this.les,
    businessId:this.bId
  }

  console.log("before", details)
  this.pService.addSyllabus(details).subscribe((data:any) => {
    alert("submitted Successfully");
    this.ngOnInit();  
    console.log("Add_Syllabus", data)
    
    // this.viewDetails(data);
  });
  }

  syllabus(addSyllFields:NgForm){
    this.addSyl=false;
    this.view=true;
    addSyllFields.reset();
  }
  
  getSyllabus(data){
    this.editCour = data.course
    this.editDep = data.department
    this.editYr = data.year
    this.editSem = data.semester
    this.editSec = data.section
    this.editSub = data.subject
    this.editStDate = data.startDate
    this.editEdDate = data.endDate
    this.editLes = data.lession
    this.editTopic = data.topic 
    this.editTime = data.time
    this.id = data.id
  }

  updateSyllabus(){
    let syllabus = {
      id : this.id,
      course : this.editCour,
      department : this.editDep,
      year : this.editYr,
      semester : this.editSem,
      section : this.editSec,
      subject : this.editSub,
      startDate : this.editStDate,
      endDate : this.editEdDate,
      topic : this.editTopic,
      lession : this.editLes,
      time : this.editTime,
      businessId :this.bId
    }
    console.log("before", syllabus);
    
    this.pService.updateSyllabus(syllabus).subscribe((data:any) => {
      this.ngOnInit();
    });
  }

  deleteSyllabus(id){
  this.pService.deleteSyllabus(id).subscribe((data:any) => {
      console.log("del", data);
      alert("deleted");
      this.ngOnInit();
    })
  }

  addLectureNotes(){
    let lDetails = {
      department:this.lecDep,
      course:this.lecCour,
      year:this.lecYear,
      subject:this.lecSub,
      topic:this.lecTopic,
      description:this.desc,
      professorId:this.professorId,   
      semester:this.lecSem,
      section:this.lecSec,
      lesson:this.lesLec,
      businessId:this.bId
    }

  console.log("before", lDetails)
  this.pService.addLectureNotes(lDetails).subscribe((data:any) => {
  alert("submitted Successfully");
  console.log("Add_Lecture_Notes", data)
    this.ngOnInit();
  });
  }

  lectureNote(addLtnFields:NgForm){
    this.addLect=false;
    this.view=true;
    addLtnFields.reset();
  }

  getLecNote(data){
    this.rowId = data.id
    this.editLecCour = data.course
    this.editLecDep = data.department
    this.editLecYear = data.year
    this.editLecSem = data.semester
    this.editLecSec = data.section
    this.editLecSub = data.subject
    this.editLesLec = data.lesson
    this.editLecTopic = data.topic
    this.editDesc = data.description
    }

  updateLectureNotes(){
    let lecfield = {
      id : this.rowId,
      course : this.editLecCour,
      department : this.editLecDep,
      year : this.editLecYear,
      subject : this.editLecSub,
      topic : this.editLecTopic,
      description : this.editDesc,
      professorId : this.professorId,
      semester : this.editLecSem,
      section : this.editLecSec,
      lesson : this.editLesLec,
      businessId :this.bId
    }
    console.log("before", lecfield);
    
    this.pService.updateLectureNotes(lecfield).subscribe((data:any) => {
    console.log("update", data);
    this.ngOnInit();     
    });
  }

    deleteNotes(id){
    this.pService.deleteNotes(id).subscribe((data:any) => {
      console.log("Del_Notes", data);
      alert("deleted");
      this.ngOnInit();
      // this.viewDetails(data);
    })
  }

  getNotificatn(data){
    this.rowId = data.id
    this.editNotiCour = data.course
    this.editNotiDep = data.department
    this.editNotiYear = data.year
    this.editNotiSem = data.semester
    this.editNotiSec = data.section
    this.editNotiSub = data.subject
    this.editChpt = data.chapter
    this.editNotify = data.notification
  }

  updateNotification(){
    let notiField = {
      id : this.rowId,
      course : this.editNotiCour,
      department : this.editNotiDep,
      year : this.editNotiYear,
      semester : this.editNotiSem,
      subject : this.editNotiSub,
      section : this.editNotiSec,
      chapter : this.editChpt,
      notification : this.editNotify,
      businessId :this.bId
    }
    console.log("before", notiField);
    
    this.pService.updateNotification(notiField).subscribe((data:any) => {
        console.log("Update_Lec", data);
        this.ngOnInit();
    });
  }


  deleteNotification(id){
    this.pService.deleteNotification(id).subscribe((data:any) => {
      console.log("Del_Noti", data);
      alert("deleted");
      this.ngOnInit();
      // this.viewDetails(data);
    })
  }


  viewNotifi:any=[]
  viewSub:any=[]
  depLst:any=[]
  ngOnInit() {

    this.pService.viewSubjects(this.professorId).subscribe((sub:any) => {
      this.viewSub = sub.data
      console.log("View_Subjects", this.viewSub)
    });

    this.pService.viewNotification(this.professorId).subscribe((notifi:any) => {
      this.viewNotifi = notifi.data
      console.log("View_Notification", this.viewNotifi)
      // this.getNoti(this.viewNotifi)
    });

    this.pService.viewDepartments().subscribe((data:any) =>{
      this.depLst=data.data
    })

  }

}
