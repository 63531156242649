import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
@Component({
  selector: 'app-parent',
  templateUrl: './parent.component.html',
  styleUrls: ['./parent.component.scss']
})
export class ParentComponent implements OnInit {


  constructor(private router: Router) { }

  back() {
    localStorage.removeItem('studentId');
    localStorage.removeItem('studentYear');
    localStorage.removeItem('studentSem');
    localStorage.removeItem('studentCour');
    localStorage.removeItem('studentDep');
    this.router.navigate(['/parent-home']);
  }

  ngOnInit() {
  }

}
