import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { StudentService } from '../student.service';
declare var $;

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  constructor(
    private router: Router,
    private service: StudentService) { }

  fname: any;
  lname: any;
  dob: any;
  cnum: any;
  email: any;
  gender: any;
  dep: any;
  year: any;
  sem: any;
  studentId = localStorage.getItem("userId")
  address: any;

  updateProfile() {
    let pFields = {
      userName : this.studentId,
      firstName : this.fname,
      lastName : this.lname,
      email : this.email,
      dob : this.dob,
      address : this.address,
      contactNumber :this.cnum
  
    }
    this.service.updateProfile(pFields).subscribe((data: any) => {
      if (data.success = true) {
        this.fname = data.data.firstName
        this.lname = data.data.lastName
        this.email = data.data.email
        this.dob = data.data.dob
        this.address = data.data.address
        this.cnum = data.data.contactNumber
        console.log("Update_Profile_Data", this.fname);
      }
    })
  }

  logOut() {
    localStorage.removeItem('token');
    localStorage.removeItem('year');
    localStorage.removeItem('userId');
    localStorage.removeItem('sem');
    localStorage.removeItem('course');
    localStorage.removeItem('department');
    localStorage.removeItem('busId');
    localStorage.removeItem('section');
    this.router.navigate(['/']);
  }

  edit = false;
  editProfile() {
    this.edit = true;
  }



  getDetails(data) {
    this.fname = data.firstName
    this.lname = data.lastname
    this.dob = data.dob
    this.gender = data.gender
    this.email = data.email
    this.cnum = data.contactNumber
    this.address = data.address
    this.year = data.year
    this.sem = data.semester
    this.dep = data.department
  }
  firstName;
  getprofiledetails;
  
  ngOnInit() {
    this.service.getprofile(this.studentId).subscribe((data: any) => {
      this.getprofiledetails = data.data
      this.firstName = data.data.firstName
      localStorage.setItem("year", data.data.year);
      localStorage.setItem("sem", data.data.semester);
      localStorage.setItem("course", data.data.course);
      localStorage.setItem("department", data.data.department);
      // localStorage.setItem("businessId", data.data.businessId);
      localStorage.setItem("section", data.data.section);

      console.log("getprofiledetails", this.getprofiledetails);
      this.getDetails(this.getprofiledetails);
    })


    // function readURL(input) {      
    //   if (input.files && input.files[0]) {
    //     var reader = new FileReader();

    //     reader.onload = function (e) {

    //       $('#profile-img-tag').attr('src', e.target.result);

    //       console.log("image", e.target.result);
    //     }
    //     reader.readAsDataURL(input.files[0]);
    //   }
    // }

    // $("#profile-img").change(function () {
    //   readURL(this);
    // });

  }


}

