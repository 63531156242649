import { Component, OnInit } from '@angular/core';
import { StudentService } from '../student.service';

import { ActivatedRoute, Router} from '@angular/router'

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor(
    private service: StudentService,
    private  activeRoute: ActivatedRoute
    ) { }

  userId =  localStorage.getItem("userId");
  busId = localStorage.getItem("busId");
  // externalResults:any = [];

  externalinfo = {
    label:  ['1-1', '1-2', '2-1', '2-2', '3-1', '3-2', '4-1','4-2'],
    dataset:[
      { data: [0,0], label: 'External Results Semester Wise' },
    ]

  };

  internalinfo = {
    label:  ['1-1', '1-2', '2-1', '2-2', '3-1', '3-2', '4-1','4-2'],
    dataset:[
      { data: [], label: 'Internal Results Semester Wise' },
    ]

  };

  attendanceinfo = {
    label:  ['1-1', '1-2', '2-1', '2-2', '3-1', '3-2', '4-1','4-2'],
    dataset:[
      { data: [0, 0, 0, 0, 0, 0, 0, 0], label: 'Attendance Semester Wise' },
    ]

  };


  feedback:any;
  ngOnInit() {

    // External Result graph APIs
    this.service.dashboardExternalResults(this.userId).subscribe((data:any) => {
      console.log("external graph", data);
      let externalgraph = data.data;
      let externaldata = {
        label:  [],
        dataset:[
          { data: [], label: 'External Results Semester Wise' },
        ]

      }
      for (let index = 0; index < externalgraph.length; index++) {
        let label = externalgraph[index].year + "-" +  externalgraph[index].semester;
        externaldata.label.push(label);

        let dataset = externalgraph[index].percentage          
        externaldata.dataset[0].data.push(dataset)
      }
      this.externalinfo = externaldata   
    });


    // Internal Result graph APIs
    this.service.dashboardInternalResults(this.userId).subscribe((data:any) => {
      console.log("internal", data);
      let internalgraph = data.data;
      let internaldata = {
        label:  [],
        dataset:[
          { data: [], label: 'Internal Results Semester Wise' },
        ]

      }
      for (let index = 0; index < internalgraph.length; index++) {
        let label = internalgraph[index].year + "-" +  internalgraph[index].semester;
        internaldata.label.push(label);

        let dataset = internalgraph[index].marks          
        internaldata.dataset[0].data.push(dataset)
      }
      this.internalinfo = internaldata   
    });
    

    // feedback given to students

    this.service.feedbackGivenToStudent(this.userId).subscribe((data:any) =>{
      this.feedback=data.data
      console.log("feedback given", this.feedback);
      
    })

  }

}
