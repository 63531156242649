import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Constant }  from '../config';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(
    private httpclient: HttpClient
  ) { }

  sign(data: any) {
    // let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.httpclient.post(Constant.API_ORIGIN_URL+'/user/login', data)
    // return this.httpclient.post('http://localhost:8080/collage-management/collage/student/viewProfile', data)
    // this.externalResults = this.commonurl
  }
  
  
}
