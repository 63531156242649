import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-timetable',
  templateUrl: './timetable.component.html',
  styleUrls: ['./timetable.component.scss']
})
export class TimetableComponent implements OnInit {

  constructor() { }

  search:any;
  
  viewList=true;
  addTt=false;

  addTimetb(){
    this.viewList=false;
    this.addTt=true;
  }
  ngOnInit() {
  }

}
