import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constant } from '../../config';
@Injectable({
  providedIn: 'root'
})
export class TransportService {

  constructor(private httpClient: HttpClient) { }

  // BUS

  addBus(data){
    return this.httpClient.post(Constant.API_ORIGIN_URL+ "/bus/addBus", data)
  }

  getAllBusses(id){
     return this.httpClient.get(Constant.API_ORIGIN_URL+ "/bus/getBusByBusinessId/"+id)
   }  
   
  updateBus(data){
    return this.httpClient.post(Constant.API_ORIGIN_URL+ "/bus/editBus", data)
  }

  deleteBus(id){
    return this.httpClient.delete(Constant.API_ORIGIN_URL+ "/bus/deleteBus/"+id)
  }

  //  private bus

  addPrivateBus(data){
    return this.httpClient.post(Constant.API_ORIGIN_URL+ "/bus/addBusOwner", data)
  }

  getPrivateBusList(id){
     return this.httpClient.get(Constant.API_ORIGIN_URL+ "/bus/getBusOwnerByBusinessId/"+id)
   }  
   
  updatePrivateBus(data){
    return this.httpClient.post(Constant.API_ORIGIN_URL+ "/bus/editBusOwner", data)
  }

  deletePrivateBus(id){
    return this.httpClient.delete(Constant.API_ORIGIN_URL+ "/bus/deleteBusOwner/"+id)
  }

  // BUS Stops

  addBusStops(data){
    return this.httpClient.post(Constant.API_ORIGIN_URL+ "/bus/addStops", data)
  }
  
  getAllBusStops(){
    return this.httpClient.get(Constant.API_ORIGIN_URL+ "/bus/viewStops")
  }  

  updateStops(data){
    return this.httpClient.post(Constant.API_ORIGIN_URL+ "/bus/editStops", data)
  }
  deleteStop(id){
    return this.httpClient.delete(Constant.API_ORIGIN_URL+ "/bus/deleteStops/"+id)
  }

  // BUS Drivers

  addDrivers(data){
    return this.httpClient.post(Constant.API_ORIGIN_URL+ "/bus/addBusFaculty", data)
  }
  
  viewDrivers(id){
    return this.httpClient.get(Constant.API_ORIGIN_URL+ "/bus/getBusFacultyByBusinessId/"+id)
  }  

  updateDrivers(data){
    return this.httpClient.post(Constant.API_ORIGIN_URL+ "/bus/editBusFaculty", data)
  }
  deleteDrivers(id){
    return this.httpClient.delete(Constant.API_ORIGIN_URL+ "/bus/deleteBusFaculty/"+id)
  }

// Student allocation

addStudentAllocate(data){
  return this.httpClient.post(Constant.API_ORIGIN_URL+ "/bus/addStudentBus", data)
}

getStudentAllocate(id){
  return this.httpClient.get(Constant.API_ORIGIN_URL+ "/bus/getStudentBusAllocationByBusinessId/"+id)
}

updateStudentAllocate(data){
  return this.httpClient.post(Constant.API_ORIGIN_URL+ "/bus/editStudentBus", data)
}

deleteStudentAllocate(id){
  return this.httpClient.delete(Constant.API_ORIGIN_URL+ "/bus/deleteStudentBus/"+id)
}

// Expenses

addExpenses(data){
  return this.httpClient.post(Constant.API_ORIGIN_URL+ "/bus/addBusService", data)
}

getExpenses(id){
  return this.httpClient.get(Constant.API_ORIGIN_URL+ "/bus/getBusServiceDetailsByBusinessId/" +id)
}  

updateExpenses(data){
  return this.httpClient.post(Constant.API_ORIGIN_URL+ "/bus/editBusService", data)
}

deleteExpenses(id){
  return this.httpClient.delete(Constant.API_ORIGIN_URL+ "/bus/deleteBusService/"+id)
}

//  Feedback

viewTransportFeedback(id){
  return this.httpClient.get(Constant.API_ORIGIN_URL+ "/bus/getTransportFeedbackByBusinessId/" +id)
}

// Tracking

addTracking(data){
  return this.httpClient.post(Constant.API_ORIGIN_URL+ "/bus/addBusTrack", data);
}

  // Email
  
  // Compose
  sendMail(mail){
    return this.httpClient.post(Constant.API_ORIGIN_URL+ "/email/compose", mail)
  }

  // sent
  mailsSent(id){
    return this.httpClient.get(Constant.API_ORIGIN_URL+ "/email/mails/" + id)
  }

  // inbox
  mailsReceive(id){
    return this.httpClient.get(Constant.API_ORIGIN_URL+ "/email/mails/" + id)
  }
  
  // delete mail
  mailsDelete(id){
    return this.httpClient.delete(Constant.API_ORIGIN_URL+ "/email/deleteMail/" + id)
  }
  
}
