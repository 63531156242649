import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constant }  from '../../config';

@Injectable({
  providedIn: 'root'
})
export class ParentService {

  constructor(private httpClient: HttpClient) { }

  // Children info API

  getChildrenInfo(id){
    return this.httpClient.get(Constant.API_ORIGIN_URL+'/parent/getStudentsForParent/' +id);
  }

  //academics API

  //getsubjects API
  getAcademicSubjects(id) {
    return this.httpClient.get(Constant.API_ORIGIN_URL+ '/student/viewSubjectsForStudent/' +id);
  }

  //viewsyllabus API
  viewSyllabus(subject) {
    return this.httpClient.get(Constant.API_ORIGIN_URL+ '/student/viewSyllabus/' + subject);
  }

  //viewLectureNotes API
  lectureNotes(subjectName) {
    return this.httpClient.get(Constant.API_ORIGIN_URL+ '/student/viewLectureNotesByStudent/' + subjectName);
  }

  //viewNotifications API
  viewNotify(id) {
    return this.httpClient.get(Constant.API_ORIGIN_URL+ '/student/viewNotificationsForStudent/' + id);
  }

   // Result

  //Internal Results

  semisterWise(id){
    return this.httpClient.get(Constant.API_ORIGIN_URL+ '/parent/viewSemeterWiseResults/' +id);
     }

  internals(exams){
    return this.httpClient.post(Constant.API_ORIGIN_URL+ '/parent/viewInternalResultsForParent' , exams)
  }

  externals(exam){
    return this.httpClient.post(Constant.API_ORIGIN_URL+ '/parent/viewExternalResultsForParent' ,exam)
  }

  // Attendance

  // Monthly attendance

  monthAttnd(month){
    return this.httpClient.post(Constant.API_ORIGIN_URL+ '/attendance/getStudentMonthlyGateAttendance', month)
  }
  weekAttnd(week){
    return this.httpClient.post(Constant.API_ORIGIN_URL+ '/attendance/getStudentWeeeklyGateAttendance', week)
  }
  dateAttnd(day){
    return this.httpClient.post(Constant.API_ORIGIN_URL+ '/attendance/getStudentDayGateAttendance', day)
  }
}
