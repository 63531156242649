import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MaterialModule } from './material.module';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StudentModule } from './components/modules/student/student.module';
import { HttpClientModule } from '@angular/common/http';
import { SuperAdminModule } from './components/modules/super-admin/super-admin.module';

import { ProfessorModule } from './components/modules/professor/professor.module';
import { AdminModule } from './components/modules/admin/admin.module';
import { ParentModule } from './components/modules/Parent/parent.module';
import { LibrarianModule } from './components/modules/Librarian/librarian.module';
import { CanteenModule } from './components/modules/canteen/canteen.module';
import { HostelModule } from './components/modules/hostel/hostel.module';
import { TransportModule } from './components/modules/transport/transport.module';
import { AccountsModule } from './components/modules/accounts/accounts.module';
import { HodModule } from './components/modules/hod/hod.module';



@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    StudentModule,
    ProfessorModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    SuperAdminModule,
    AdminModule,
    ParentModule,
    LibrarianModule,
    CanteenModule,
    HostelModule,
    TransportModule,
    AccountsModule,
    HodModule
    
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
