import { Component, OnInit } from '@angular/core';
import { HostelService } from '../hostel.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-fine',
  templateUrl: './fine.component.html',
  styleUrls: ['./fine.component.scss']
})
export class FineComponent implements OnInit {

  constructor(private hService:HostelService) { }

  search:any;
  
  viewFine=true;
  addFines=false;

  addFine(){
    this.viewFine=false;
    this.addFines=true;
  }
  back(addFields:NgForm){
    addFields.reset();
    this.viewFine=true;
    this.addFines=false;
  }

  studID
  rule
  actnTakn

  bid = localStorage.getItem("busId");

  stdId
  ruleNm
  actn
  id

  fineAdd(){
    let add = {
      studentId:this.studID,
      rule:this.rule,
      actionTobeTaken:this.actnTakn,
      businessId:this.bid
    }
    console.log("before", add);
    this.hService.addFine(add).subscribe((data:any) => {
      console.log("after", data);
      alert("Submitted Successfully");
      
      this.ngOnInit();
    })
  }

  
  completefields(addFields:NgForm){
    this.viewFine=true;
      this.addFines=false;
    addFields.reset();
  }
  
  editfine(data){
    this.stdId = data.studentId
    this.ruleNm = data.rule
    this.actn = data.actionTobeTaken
    this.id = data.id
  }

  updateFine(){
    let updt = {
      id:this.id,
      studentId:this.stdId,
      rule:this.ruleNm,
      actionTobeTaken:this.actn,
      businessId:this.bid
    }
    console.log("before", updt);
    this.hService.updateFine(updt).subscribe((data:any) => {
      console.log("after", data);
      this.ngOnInit();
    })
  }

  deleteFine(id){
    this.hService.deleteFine(id).subscribe((data:any) => {
      console.log("Deleted", data);
      alert("Deleted");
      this.ngOnInit();
    });
  }

  viewFineList:any = [];
  ngOnInit() {
    this.hService.viewFines().subscribe((data:any) =>{
      this.viewFineList = data.data
      console.log("View_Fines_list", this.viewFineList);
    })
  }

}
