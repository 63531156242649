import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LibrarianComponent } from './librarian.component';
import { LibrarianRoutingModule } from './librarian-routing.module';
import { LibraryBooksComponent } from './library-books/library-books.component';
import { MaterialModule } from 'src/app/material.module';
import { MatInputModule } from '@angular/material';
import { FormsModule } from '@angular/forms';
import { MailComponent } from './mail/mail.component';
import { LendbooksComponent } from './lendbooks/lendbooks.component';
import { LprofileComponent } from './lprofile/lprofile.component';
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { DueBooksComponent } from './due-books/due-books.component';


@NgModule({
  declarations: [
    LibrarianComponent,
    LibraryBooksComponent,
    MailComponent,
    LendbooksComponent,
    LprofileComponent,
    DueBooksComponent
  ],
  imports: [
    CommonModule,
    LibrarianRoutingModule,
    MatInputModule,
    MaterialModule,
    FormsModule,
    Ng2SearchPipeModule
  ]
})
export class LibrarianModule { }
