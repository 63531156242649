import { Component, OnInit } from '@angular/core';
import { SuperAdminService } from '../super-admin.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup,  FormsModule, NgForm } from '@angular/forms';
import {FormControl , Validators} from '@angular/forms';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-colleges',
  templateUrl: './colleges.component.html',
  styleUrls: ['./colleges.component.scss']
})
export class CollegesComponent implements OnInit {

  allColleges: any;
  search:any;

  email = new FormControl('', [Validators.required, Validators.email]);

  constructor(private Service: SuperAdminService, private router: Router) {
    // this.allColleges = [];
  }

  
  getErrorMessage() {
    return this.email.hasError('required') ? 'You must enter a value' :
        this.email.hasError('email') ? 'Not a valid email' :
            '';
  }

  cName: any
  code: any
  address: any
  branch: any
  number: any
  ema: any

  collegeId;

  cnam: any
  cod: any
  addr: any
  br: any
  num: any
  em: any



  add() {
    let collegeDetails = {
      name: this.cName,
      businessId: this.code,
      address: this.address,
      branchName: this.branch,
      contactNumber: this.number,
      email: this.ema,
      id: this.collegeId
    }
    console.log("added colleges", collegeDetails);
    this.Service.addCollege(collegeDetails).subscribe((details: any) => {
      // alert("college is added successfully..");
      console.log("colleges", details);
      if(details.success = true){
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Your work has been saved',
          showConfirmButton: false,
          timer: 1500
        })
        console.log("colleges", details);
        this.addColleges = false;
      this.viewColleges = true;
      this.ngOnInit();
      }
    })
  }

  completefields(addFields:NgForm){
    addFields.reset();
  }
  cancel(addFields:NgForm){
    addFields.reset();
  }

  updateCollege() {
    let details = {
      id: this.collegeId,
      name: this.cnam,
      address: this.addr,
      contactNumber: this.num,
      email: this.em,
      branchName: this.br,
      businessId: this.cod 
    }
    this.Service.editColleges(details).subscribe((college: any) => {
      console.log("edit college", college);
      this.ngOnInit();
      if (college.success = true) {
        this.collegeId = college.data.id
        this.cnam = college.data.name
        this.cod = college.data.businessId
        this.addr = college.data.address
        this.br = college.data.branchName
        this.num = college.data.contactNumber
        this.em = college.data.email
      }
    })
  }


  viewColleges = true;
  addColleges = false;
  backToAdd = false;
 

  colleges() {
    this.viewColleges = false;
    this.backToAdd = true;
    this.addColleges = true;
  }

  addC() {
    this.viewColleges = true;
    this.addColleges = false;
    this.backToAdd = false;
  }

  aboutCollege: any = [];
  // abtId;

  // getCollege(businssId) {
  //   this.viewColleges = false;
  //   this.backToAdd = true;
  //   this.addColleges = false;
  //   // console.log("id" ,businessId);   

  //   this.abtId = businssId;
  //   console.log("htgfhhhh", businssId);

  //   this.Service.getCollegeById(this.abtId).subscribe((colDetails: any) => {
  //     console.log("get college details", colDetails);
  //     this.aboutCollege = colDetails.data;

  //   })

  // }


  deleteCollege(id) {
    this.Service.deleteColleges(id).subscribe((delId: any) => {
      if(delId.success = true){
        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.value) {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
          }
          this.ngOnInit();
        });
      }
      console.log("delete college", delId);
    })

  }



  editClg(data) {
    this.collegeId = data.id
    this.cnam = data.name
    this.addr = data.address
    this.num = data.contactNumber
    this.em = data.email
    this.br = data.branchName
    this.cod = data.businessId
  }


  ngOnInit() {
    // this.updateCollege();
    this.Service.viewColleges().subscribe((totalColleges: any) => {
      // console.log("all", totalColleges);
      this.allColleges = totalColleges.data;
      // localStorage.setItem("collegeId" ,totalColleges.data.id);
      console.log("all colleges", this.allColleges);
      this.editClg(this.allColleges);
    })
  }

}
