import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ParentService } from '../parent.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
     private service: ParentService) { }

  id = localStorage.getItem("userId");

  logOut() {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('busId');
    localStorage.removeItem('studentId');
    localStorage.removeItem('studentYear');
    localStorage.removeItem('studentSem');
    localStorage.removeItem('studentCour');
    localStorage.removeItem('studentDep');
    this.router.navigate(['/login']);
  }

  childInfo:any = []

  students(data){
    
    this.router.navigate(['/parent/dashboard']);    
    console.log("fdfdf", data.StudentId);
    localStorage.setItem("studentId", data.StudentId);
    localStorage.setItem("studentYear", data.year);
    localStorage.setItem("studentSem", data.semester);
    localStorage.setItem("studentCour", data.course);
    localStorage.setItem("studentDep", data.department);
  }
  ngOnInit() {
    this.service.getChildrenInfo(this.id).subscribe((data:any) => {
      this.childInfo = data.data
      // localStorage.setItem("studentId", this.childInfo[length].StudentId);
      // console.log("student id",this.childInfo[length].StudentId);
      
      console.log("Childen Info", this.childInfo);            
    })
  }

}
