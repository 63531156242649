import { Component, OnInit } from '@angular/core';
import { SuperAdminService } from '../super-admin.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent implements OnInit {

  constructor(private service :SuperAdminService, private router: Router) { }

  mesgReceive=false;
  mesgSent=false;
  
    sender:any;
    receiver:any;
    subject:any;
    message:any;
  
    sentTo:any;
    receiveFrom:any;
    sentSubject:any;
    sentMessage:any;
  
  
  receiveMesg(data){
  this.mesgReceive=true;
  this.mesgSent=false;
  
  this.sender = data.sender
    this.receiver = data.receiver
    this.subject = data.subject
    this.message = data.message
  }
  
  sendMesg(data){
    this.mesgReceive=false;
    this.mesgSent=true; 
    this.sentTo = data.sender
      this.receiveFrom = data.receiver
      this.sentSubject = data.subject
      this.sentMessage = data.message
  }
  
  sendTo
  sentFrom
  sub
  msg
  
  id = localStorage.getItem("userId");
  bid = localStorage.getItem("businessId");
  
  sendMail(){
    let mail = {
      sender:this.sendTo,
      receiver:this.sentFrom,
      subject:this.sub,
      message:this.msg,
      // time:"",
      // label:"",
      businessId:this.bid,
      userName:this.id
    }
    this.service.sendMail(mail).subscribe((data:any) =>  {
      console.log("mail", data)
      this.mailsReceive();
    });
   
  }
  
  mailsSent(){
    // this.inbox=false;
    //   this.sent=true;
      this.mesgReceive=false;
      this.mesgSent=false;
  }
  
  mailsReceive(){
      // this.inbox=true;
      // this.sent=false;
      this.mesgReceive=false;
      this.mesgSent=false;
  
  }
  
  deleteSentMail(id){
    this.service.mailsDelete(id).subscribe((data:any) => {
      console.log("del", data);
      this.mailsSent();
    })
  }
  
  deleteRecMail(id){
    this.service.mailsDelete(id).subscribe((data:any) => {
      console.log("del", data);
      this.mailsReceive();
    })
  }
  
  receiveMail:any=[]
  sentMail:any=[]
  
    ngOnInit() {
  
      this.service.mailsSent(this.id).subscribe((data:any) => {
        this.sentMail = data.data.sentBox
        console.log("sent_mails", this.sentMail)
        this.sendMesg(this.sentMail);
      });
  
       
    this.service.mailsReceive(this.id).subscribe((data:any) => {
      this.receiveMail = data.data.inbox
      console.log("receive_mails", this.receiveMail)
      this.receiveMesg(this.receiveMail)
      })
      
    }
  
  }
  