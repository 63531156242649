import { Component, OnInit, Input, OnChanges,SimpleChanges } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { StudentService } from '../../modules/student/student.service';

@Component({
  selector: 'bar-chart',
  templateUrl: './my-bar-chart.component.html',
  styleUrls: ['./my-bar-chart.component.css']
})
export class MyBarChartComponent implements OnInit {

  @Input() Info: {};
  @Input() Datasets: ChartDataSets[];

  sampleLabels: Label[] = ['1-1', '1-2', '2-1', '2-2', '3-1', '3-2', '4-1','4-2'];
  sampleDatasets: ChartDataSets[] = [
    { data: [65, 59, 80, 81, 56, 55, 40, 50], label: 'External Semester Wise' },
    // { data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B' },
    // { data: [55, 49, 70, 31, 46, 45, 35], label: 'Series C' },
    // { data: [25, 39, 60, 21, 36, 35, 25], label: 'Series D' },
    // { data: [15, 29, 90, 31, 46, 65, 15], label: 'Series E' },
  ]; 

  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };
  public barChartLabels: Label[] = this.sampleLabels
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];

  public barChartData: ChartDataSets[] = this.sampleDatasets;

  public barChartColors: Color[] = [
    {
      backgroundColor: 'rgba(11,102,35,0.6)',
      borderColor: 'rgba(11,102,35,1)',
      pointBackgroundColor: 'rgba(11,102,35,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(11,102,35,0.8)'
    },
    { // dark grey
      backgroundColor: 'rgba(77,83,96,0.2)',
      borderColor: 'rgba(77,83,96,1)',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    }
  ];
 

  constructor(
    private service: StudentService,) { }

    userId =  localStorage.getItem("userId")

    externalResults:any = [];
    internalResults:any = []

  ngOnInit() {
    
    // this.service.dashboardExternalResults(this.userId).subscribe((data:any)=>{ 
    //   console.log("semwiseResult",data);
      
    //   if(data.success = true){
    //     this.externalResults = data.data   
    //     console.log("External_Results",this.externalResults);  
    //     console.log("External_Results semss",this.externalResults[length].semester);  
    //   }      
    // })
    
    // this.service.dashboardInternalResults(this.userId).subscribe((data:any)=>{ 
    //   if(data.success = true){
    //     this.internalResults = data.data   
    //     console.log("internal_Results",this.internalResults);  
    //   }      
    // })

  }

  ngOnChanges(changes: SimpleChanges){
    if(changes.Info.currentValue ){
       this.barChartLabels = changes.Info.currentValue.label;
       this.barChartData = changes.Info.currentValue.dataset;
      //  this.barChartOptions.scales = changes.Info.currentValue.scales;
    }
  //   if(changes.Datasets.currentValue ){
  //     this.barChartData = changes.Datasets.currentValue;
  //  }
    
  }

}
