import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { MatInputModule } from '@angular/material';

import { CommonModule } from '@angular/common';
import { ResultComponent } from './result/result.component';

import { AcademicsComponent } from './academics/academics.component';
import { AttendanceComponent } from './attendance/attendance.component';

import { LibraryComponent } from './library/library.component';
import { FeepayComponent } from './feepay/feepay.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StudentComponent } from './student.component';
import { StudentRoutingModule } from './student-routing.module';
import { CarouselComponent } from '../../home/carousel/carousel.component';
import { ContentComponent } from '../../home/content/content.component';
import { FooterComponent } from '../../home/footer/footer.component';
import { LoginComponent } from '../../home/login/login.component';
import { MainComponent } from '../../home/main/main.component';
import { NavComponent } from '../../home/nav/nav.component';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EmailComponent } from './email/email.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { GraphModule } from '../../graphs/graph.module';
import { ProfileComponent } from './profile/profile.component';
import { ConceptsComponent } from '../../home/concepts/concepts.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { GateAttendanceComponent } from './attendance/gate-attendance/gate-attendance.component';
import { ClassAttendanceComponent } from './attendance/class-attendance/class-attendance.component';


@NgModule({
  declarations: [
    ResultComponent,
    AcademicsComponent,
    AttendanceComponent,
    LibraryComponent,
    EmailComponent,
    FeepayComponent,
    FeedbackComponent,
    StudentComponent,
    CarouselComponent,
    ContentComponent,
    FooterComponent,
    LoginComponent,
    MainComponent,
    NavComponent,
    DashboardComponent,
    ProfileComponent,
    ConceptsComponent,
    GateAttendanceComponent,
    ClassAttendanceComponent
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    StudentRoutingModule,
    MaterialModule,
    FormsModule,
    MatInputModule,
    ReactiveFormsModule,
    HttpClientModule,
    GraphModule,
    Ng2SearchPipeModule
  ],
  exports:[
  ]
})
export class StudentModule { }
