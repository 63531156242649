import { Component, OnInit } from '@angular/core';
import { ProfessorService } from '../../professor.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-internals',
  templateUrl: './internals.component.html',
  styleUrls: ['./internals.component.scss']
})
export class InternalsComponent implements OnInit {

  constructor(private pService: ProfessorService, private router:Router) { }


  bId = localStorage.getItem("busId")
  professorId = localStorage.getItem("userId")

  sublist=true;
  viewInternals=false;
  addInternals=false;

  back(){
    this.router.navigate(['/professor/results']);   
  }

  backTo(){
    this.viewInternals=false;
    this.sublist=true;
  }
  backToInt(){
    this.viewInternals=true;
    this.addInternals=false;
  }
  
  viewList:any=[]
sub;
  viewDetails(sub,sec){
    this.viewInternals=true;
    this.sublist=false;
  this.sub=sub
    let add ={
      section:sec,
      subjectName:sub
    }

    this.pService.viewInternalMarks(add).subscribe((data:any) =>{
      this.viewList = data.data
      console.log("list", this.viewList);
      
    })
  }

view:any=[]
  add(){
    this.viewInternals=false;
    this.addInternals=true;
    let a ={
      userName:this.professorId,
      subject:this.sub
    }
    console.log("subj", a);
    
    this.pService.viewStudentListBySubjectName(a).subscribe((sub:any) => {
      this.view = sub.data
      console.log("view student list by subname", this.view)
    });
  }

  viewSub:any = []
  ngOnInit() {
    this.pService.viewSubjects(this.professorId).subscribe((sub:any) => {
      this.viewSub = sub.data
      console.log("View_Subjects", this.viewSub)
    });
       
  }

}
