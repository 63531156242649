import { Component, OnInit } from '@angular/core';
import { LibrarianService } from '../librarian.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-lprofile',
  templateUrl: './lprofile.component.html',
  styleUrls: ['./lprofile.component.scss'],
  providers: [
    DatePipe
  ]
})
export class LprofileComponent implements OnInit {

  constructor(
    private lService: LibrarianService,
    private datePipe: DatePipe,
    private router: Router
  ) { }


  fname: any;
  lname: any;
  dob: any;
  cnum: any;
  email: any;
  gender: any;
  yearJoined: any;
  address: any;
    
  getDetails(data) {
    this.fname = data.firstName
    this.lname = data.lastname
    this.dob = data.dob
    this.gender = data.gender
    this.email = data.email
    this.cnum = data.contactNumber
    this.address = data.address
    this.yearJoined = data.yearJoined
  }

  formatedDate:any;
  
  onSubmit(){
    this.formatedDate =  this.datePipe.transform(this.dob, "dd/MM/yyyy");
   }
  
  updateProfile() {
    let lFields = {
      userName : this.profileId,
      firstName : this.fname,
      lastName : this.lname,
      email : this.email,
      contactNumber :this.cnum,
      dob : this.dob,
      address : this.address,
      yearJoined :this.yearJoined,
      gender : this.gender
   }
   console.log("befor profile fields",lFields);
   
    this.lService.updateLprofile(lFields).subscribe((data: any) => { 
      console.log("after profile" , data);
      
      if (data.success = true) {
        this.fname = data.data.firstName
        this.lname = data.data.lastName
        this.email = data.data.email
        this.dob = data.data.dob
        this.address = data.data.address
        this.cnum = data.data.contactNumber
        this.yearJoined = data.data.yearJoined
        this.gender = data.data.gender
        console.log("Update_Profile_Data", this.fname);
      }
    })
  }
  

  logOut(){
    localStorage.removeItem('token');
    localStorage.removeItem('busId');
    localStorage.removeItem('userId');
    this.router.navigate(['/']);
  }



  firstName:any;
  profileId = localStorage.getItem("userId");
  profileDetails:any;
  ngOnInit() {
    this.lService.getLprofile(this.profileId).subscribe((data:any) => {
      if(data.success = true){
          this.profileDetails = data.data
          this.firstName = data.data.firstName
          console.log("profile",this.profileDetails);     
          this.getDetails(this.profileDetails);    
      }
    })

  }

}
