import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from '../admin.service';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-departments',
  templateUrl: './departments.component.html',
  styleUrls: ['./departments.component.scss']
})
export class DepartmentsComponent implements OnInit {

  constructor(private service : AdminService , private router : Router) { }
  
  search:any;

  depName : any
  // busId : any
  
  id : any
  // busnsId : any
  depN : any
 
  bId = localStorage.getItem("busId")

  add = true;
  viewDepartment = true;
  addDepartment = false;
  backToDept = false;

  departments(){
    this.addDepartment = true;
    this.viewDepartment = false;
  }

  backD(){
    this.viewDepartment = true;
    this.addDepartment = false;
  }

  addDept(){
    let dep = {
      name : this.depName,
     businessId : this.bId
    }

    this.service.addDepartments(dep).subscribe((dept : any) => {
      if(dept.success = true){
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Submitted Successfully',
          showConfirmButton: false,
          timer: 1500
        })
        
        this.ngOnInit();
        console.log("departments" ,dept);
      };
    })
  }

  completefields(addFields:NgForm){
    this.viewDepartment = true;
      this.addDepartment = false;
    addFields.reset();
  }
  
  editDept(depData){
    // this.bId = depData.businessId
    this.depN = depData.name
    this.id = depData.id
  }

  updateDept(){
    let depDetails =
    {
      businessId : this.bId,
      name : this.depN,
      id : this.id
    }

    this.service.editDepartment(depDetails).subscribe((deprts : any) =>{
      console.log("edit department" , deprts);
      this.ngOnInit();
      if (deprts.success = true){
        this.bId = deprts.data.businessId
        this.depN = deprts.data.name
        this.id = deprts.data.id
      }
    })
  }

  delDept(id){
    this.service.delDepart(id).subscribe((detDep : any) => {
      if(detDep.success = true){
        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.value) {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
          }
          this.ngOnInit();
        })
        console.log("delete department" , detDep);
      }
    })
  }

  depts : any;

  ngOnInit() {

    this.service.viewDepartments().subscribe((deps : any) =>{
      this.depts = deps.data;  
      console.log("all departments" , deps);
    })
  }

}
