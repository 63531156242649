import { Component, OnInit } from '@angular/core';
import { ProfessorService } from '../professor.service';
import { Router } from '@angular/router';
import { MatRadioButton } from '@angular/material';

@Component({
  selector: 'app-attendance',
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.scss']
})
export class AttendanceComponent implements OnInit {

  constructor(private pService: ProfessorService, private router:Router) { }

  searchPro;
  searchStd;
  searchCls;
  searchAtd;

  view=true;
  ViewProAtd=false;
  viewStudAttd=false;

  stdClsAttd=false;
  
  back(){
    this.view=true;
    this.ViewProAtd=false;
    this.viewStudAttd=false;
  }
  backToSub(){
    this.stdClsAttd=false;
  }

  bId = localStorage.getItem("busId")
  professorId = localStorage.getItem("userId")
  
  viewProAtdn:any;  
  proAttd(){
    this.view=false;
    this.ViewProAtd=true;
    this.viewStudAttd=false;

    let attd ={
      userName:this.professorId,
      businessId:this.bId
    }
    this.pService.viewProAttd(attd).subscribe((data:any) =>{
      this.viewProAtdn = data.data
      console.log("professor attendance", this.viewProAtdn);
    })
  }

  stdAttd(){
    this.router.navigate(['/professor/attendance/student-attendance']);   
  }


  classAtd(){
  this.router.navigate(['/professor/attendance/classroom-attendance']);   
  }


  ngOnInit() {
  }
}
