import { Component, OnInit } from '@angular/core';
import { ProfessorService } from '../../../professor.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-class',
  templateUrl: './class.component.html',
  styleUrls: ['./class.component.scss']
})
export class ClassComponent implements OnInit {

  constructor(private pService: ProfessorService, private router:Router) { }

  class=true;
  stdListClsAtd=false;
  month=false;
  date=false;
  monthTable = false;
    
  bId = localStorage.getItem("busId")
  professorId = localStorage.getItem("userId")

  backTo(){
    this.router.navigate(['/professor/attendance/student-attendance']);  
  }

  backToSub(){
    this.class=true;
    this.stdListClsAtd=false;
  }

  mon(){
    this.month = true
    this.date = false
    this.monthTable = false;
  }
  dt(){
    this.month = false
    this.date = true
    this.monthTable = false;
  }

  monthName=[
    "January","February", "March","April","May","June","July ","August","September","October","November","December"
  ]

  sLst:any =[]
  sub;
  viewDetails(data){
    this.stdListClsAtd=true;
    this.class=false;
    this.monthTable = false;
    let sub={
      userName:this.professorId,
      subject:data,
      businessId:this.bId
    }
    this.sub = data
    console.log("before", sub)
    
    this.pService.viewStudentList(sub).subscribe((data:any) =>{
      this.sLst = data.data
      console.log("student list in class attd",this.sLst);
    })
  }
  
  
  stdLst;
  monthT;

  weeklyAttendance:any=[]
  newWat:any;
  weeklyAtt:any;
  monthTab(){
  this.monthTable = true;

  let wkAtd = {
    userName: this.stdLst,
    month: this.monthT,
    subject:this.sub
  }
  console.log("w", wkAtd);
  
  this.pService.classroomWeeklyAttd(wkAtd).subscribe((data:any) => {
    this.weeklyAttendance = data.data
    let wAt = data.data
    this.newWat = []
    console.log("newWeeks-----", this.newWat);
    console.log("present absent",this.weeklyAtt);
    
    for (let obj of wAt) {      
      for(let day in obj){
        console.log("dayyyy-------",day);  
        var weeks = day.split('');
        console.log("Only Weeksss-------", weeks);     
        this.newWat.push(day)         
      }      
    }
    console.log("weekly attendance",this.weeklyAttendance);      
  })
  }

  viewSub:any = []
  ngOnInit() {
    this.pService.viewSubjects(this.professorId).subscribe((sub:any) => {
      this.viewSub = sub.data
      console.log("View_Subjects", this.viewSub)
    });
  }

}
