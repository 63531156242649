import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from '../../home/main/main.component';
import { LoginComponent } from '../../home/login/login.component';
import { HostelHomeComponent } from './hostel-home/hostel-home.component';
import { AllocationComponent } from './allocation/allocation.component';
import { DisciplinaryRuleComponent } from './disciplinary-rule/disciplinary-rule.component';
import { FeeDetailsComponent } from './fee-details/fee-details.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { FineComponent } from './fine/fine.component';
import { HostelListComponent } from './hostel-list/hostel-list.component';
import { MailComponent } from './mail/mail.component';
import { OutingPassComponent } from './outing-pass/outing-pass.component';
import { StudentDetailsComponent } from './student-details/student-details.component';


const routes: Routes = [
  { path:'', component: MainComponent },
  { path:'login', component: LoginComponent },
  { path:'hostel', component: HostelHomeComponent , children:[
    { path: 'allocation', component: AllocationComponent},
    { path: 'disciplinary-rule', component: DisciplinaryRuleComponent},
    { path: 'fee-details', component: FeeDetailsComponent},
    { path: 'feedback', component: FeedbackComponent},
    { path: 'fine', component: FineComponent},
    { path: 'hostel-list', component: HostelListComponent},
    { path: 'email', component: MailComponent},
    { path: 'outing-pass', component: OutingPassComponent},
    { path: 'student-details', component: StudentDetailsComponent}
  ]
}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HostelRoutingModule { }
