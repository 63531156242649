import { Component, OnInit } from '@angular/core';
import { HostelService } from '../hostel.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-fee-details',
  templateUrl: './fee-details.component.html',
  styleUrls: ['./fee-details.component.scss']
})
export class FeeDetailsComponent implements OnInit {

  constructor(private hService:HostelService) { }

  search:any;
  
  viewFeeDetails=true;
  addFeeDetails=false;

  addFee(){
    this.viewFeeDetails=false;
    this.addFeeDetails=true;
  }
  back(addFields:NgForm){
    addFields.reset();
    this.viewFeeDetails=true;
    this.addFeeDetails=false;
  }

  bdName
  cour
  yr
  rmShare
  rmFees
  msFees
  tFee
  term

  bid = localStorage.getItem("busId");

  bldName
  cours
  year
  rShare
  rFees
  mFees
  totFee
  terms
  id

  detailAdd(){
    let add = {
      buildingName:this.bdName,
      course:this.cour,
      year:this.yr,
      roomSharing:this.rmShare,
      roomFees:this.rmFees,
      messFees:this.msFees,
      totalFees:this.tFee,
      noOfTerms:this.term,
      businessId:this.bid
    }
    console.log("before", add);
    
    this.hService.addFeeDetails(add).subscribe((data:any) => {
      console.log("after", data);
      alert("Submitted Successfully");
      
      this.ngOnInit();
    })
  }

  
  completefields(addFields:NgForm){
    this.viewFeeDetails=true;
    this.addFeeDetails=false;
    addFields.reset();
  }
  
  editFeeDetails(data){
    this.bldName = data.buildingName
    this.cours = data.course
    this.year = data.year
    this.rShare = data.roomSharing
    this.rFees = data.roomFees
    this.mFees = data.messFees
    this.totFee = data.totalFees
    this.terms = data.noOfTerms
    this.id = data.id
  }

  updateDetails(){
    let updt = {
      buildingName:this.bldName,
      course:this.cours,
      year:this.year,
      roomSharing:this.rShare,
      roomFees:this.rFees,
      messFees:this.mFees,
      totalFees:this.totFee,
      noOfTerms:this.terms,
      businessId:this.bid,
      id:this.id
    }
    console.log("before", updt);
    this.hService.updateFeeDetails(updt).subscribe((data:any) => {
      // if(data.success = true){

      // }
      console.log("after", data);
      this.ngOnInit();
      
      
    })

  }
  deleteFeeDetails(id){
    this.hService.deleteFeeDetails(id).subscribe((data:any) => {
      console.log("Del_Details", data);
      alert("deleted");
      this.ngOnInit();
    });
  }

  viewDetails:any = [];
  ngOnInit() {
    this.hService.viewFeeDetails(this.bid).subscribe((data:any) => {
      this.viewDetails = data.data
      console.log("view_Fee_details", this.viewDetails);
    });
  }

}
