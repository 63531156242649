import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HodService } from '../hod.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  constructor(private service : HodService , private router : Router) { }

  adminName: any;
  dob: any;
  cnum: any;
  email: any;
  gender: any;
  year: any;
  address: any;
  desigtn:any

  // getDetails(data) {
  //   this.adminName = data.name
  //   this.dob = data.dob
  //   this.gender = data.gender
  //   this.email = data.email
  //   this.cnum = data.contactNumber
  //   this.address = data.address
  //   this.year = data.yearJoined
  //   this.desigtn = data.designation
  // }

  getprofiledetails;
  firstName;

  hodId = localStorage.getItem("userId")

  updateProfile() {
  //   let pFields = {
  //     userName : this.adminId,
  //     name : this.adminName,
  //     email : this.email,
  //     phoneNumber :this.cnum,
  //     dob : this.dob,
  //     address : this.address,
  //  }
  //  console.log("before", pFields);
   
  //   this.service.updateProfile(pFields).subscribe((data: any) => {
  //     if (data.success = true) {
  //       // this.adminId = data.data.userName
  //       this.adminName = data.data.name
  //       this.email = data.data.email
  //       this.dob = data.data.dob
  //       this.address = data.data.address
  //       this.cnum = data.data.phoneNumber
  //       console.log("Update_Profile_Data", this.adminName);
  //     }
  //   })
  }

  logOut() {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('busId');
    this.router.navigate(['/']);
  }

  profileName:any;
  ngOnInit() {
    // this.service.getprofile(this.adminId).subscribe((data: any) => {
    //   this.getprofiledetails = data.data
    //   this.profileName = data.data.name
    //   this.getDetails(this.getprofiledetails)
    //   console.log("getprofiledetails", this.getprofiledetails);     
    // })
  }

}
