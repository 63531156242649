import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CanteenComponent } from './canteen-home/canteen.component';
import { MenuComponent } from './menu/menu.component';
import { OrdersComponent } from './orders/orders.component';
import { MailComponent } from './mail/mail.component';
import { CanteenRoutingModule } from './canteen-routing.module';
import { MatInputModule } from '@angular/material';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule } from '@angular/forms';
import { SalesReportComponent } from './sales-report/sales-report.component';
import { Ng2SearchPipeModule } from "ng2-search-filter";

@NgModule({
  declarations: [
    CanteenComponent, 
    MenuComponent, 
    OrdersComponent, 
    MailComponent, SalesReportComponent
  ],
  imports: [
    CommonModule,
    CanteenRoutingModule,
    MatInputModule,
    MaterialModule,
    FormsModule,
    Ng2SearchPipeModule
  ]
})
export class CanteenModule { }
