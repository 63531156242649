import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HostelService } from '../hostel.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-hostel-list',
  templateUrl: './hostel-list.component.html',
  styleUrls: ['./hostel-list.component.scss']
})
export class HostelListComponent implements OnInit {

  constructor(private router:Router, private hService:HostelService) { }

  search:any;
  
  viewList=true;
  addlst=false;

  addList(){
    this.viewList=false;
    this.addlst=true;
  }
  back(addFields:NgForm){
    this.viewList=true;
    this.addlst=false;
    addFields.reset();
  }

  bdName
  floor
  room
  bed
  rmCapty
  bdCapty
  block

  bid = localStorage.getItem("busId");

  bldName
  flr
  rm
  bd
  rmCap
  bdCap
  blck
  id

  listAdd(){
    let list = {
      buildingName:this.bdName,
      noOfFloors:this.floor,
      noOfRooms:this.room,
      noOfBeds:this.bed,
      roomCapacity:this.rmCapty,
      totalBuildingCapacity:this.bdCapty,
      businessId:this.bid,
      blockName:this.block
    }
    console.log("before", list);
    
    this.hService.addHostelList(list).subscribe((data:any) => {
      alert("Submitted Successfully");
      console.log("after", data)
      
      this.ngOnInit();
    })
  }
  
  completefields(addFields:NgForm){
    this.viewList=true;
    this.addlst=false;
    addFields.reset();
  }
  
  editList(data){
    this.id = data.id
    this.bldName = data.buildingName
    this.flr = data.noOfFloors
    this.rm = data.noOfRooms
    this.bd = data.noOfBeds
    this.rmCap = data.roomCapacity
    this.bdCap = data.totalBuildingCapacity
    this.blck = data.blockName
  }

  updateList(){
    let updt = {
      buildingName:this.bldName,
      noOfFloors:this.flr,
      noOfRooms:this.rm,
      noOfBeds:this.bd,
      roomCapacity:this.rmCap,
      totalBuildingCapacity:this.bdCap,
      businessId:this.bid,
      blockName:this.blck,
      id:this.id
    }
    console.log("before", updt);
    
    this.hService.updateHostelList(updt).subscribe((data:any) => {
      if (data.success = true) {
        this.ngOnInit();
      console.log("Update_Hostel_List", data);
      }
      
    });
  }

  deleteList(id){
  this.hService.deleteHostelList(id).subscribe((data:any) => {
    console.log("Del_Hostel", data);
    alert("deleted");
    this.ngOnInit();
  });
}

viewHosList:any = [];
  ngOnInit() {
    this.hService.viewHostelList().subscribe((data:any) => {
      this.viewHosList = data.data
      console.log("View_Hostel_List", this.viewHosList) 
    });
  }

}
