 import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from '../../home/main/main.component';
import { LoginComponent } from '../../home/login/login.component';
import { ConceptsComponent } from '../../home/concepts/concepts.component';
import { PHomeComponent } from './p-home/p-home.component';
import { ProfileComponent } from './profile/profile.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AcademicsComponent } from './academics/academics.component';
import { AttendanceComponent } from './attendance/attendance.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { EmailComponent } from './email/email.component';
import { LibraryComponent } from './library/library.component';
import { ExamsComponent } from './exams/exams.component';
import { ResultsComponent } from './results/results.component';
import { StudentAttendanceComponent } from './attendance/student-attendance/student-attendance.component';
import { ClassroomAttendanceComponent } from './attendance/classroom-attendance/classroom-attendance.component';
import { InternalsComponent } from './results/internals/internals.component';
import { ExternalsComponent } from './results/externals/externals.component';
import { GateComponent } from './attendance/student-attendance/gate/gate.component';
import { ClassComponent } from './attendance/student-attendance/class/class.component';

const routes: Routes = [
  { path:'', component: MainComponent },
  { path:'concepts',component: ConceptsComponent},
  { path:'login', component: LoginComponent },
  { path:'profProfile', component: ProfileComponent },
  { path:'professor', component: PHomeComponent , children:[
    { path: 'dashboard', component: DashboardComponent},
    { path: 'academics', component: AcademicsComponent},
    { path: 'attendance', component: AttendanceComponent},
    { path: 'attendance/student-attendance', component: StudentAttendanceComponent},
    { path: 'attendance/classroom-attendance', component: ClassroomAttendanceComponent},
    { path: 'attendance/student-attendance/gate', component: GateComponent},
    { path: 'attendance/student-attendance/class', component: ClassComponent},
    { path: 'feedback', component: FeedbackComponent},
    { path: 'email', component: EmailComponent},
    { path: 'results', component: ResultsComponent},
    { path: 'results/internals', component: InternalsComponent},
    { path: 'results/externals', component: ExternalsComponent},
    { path: 'library', component: LibraryComponent},
    { path: 'exams', component: ExamsComponent},
    
  ]
}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PRoutingModule { }
