import { Component, OnInit } from '@angular/core';
import { AdminService } from '../admin.service';
import Swal from 'sweetalert2'
import { NgForm, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.scss']
})
export class EmployeesComponent implements OnInit {

  hide = true;
  hide1 = true;

  constructor(private service : AdminService) { }
  
  emailId = new FormControl('', [Validators.required, Validators.email]);
  
  getErrorMessage() {
    return this.emailId.hasError('required') ? 'Enter a valid Email' :
        this.emailId.hasError('email') ? 'Not a valid email' :
            '';
  }
  
  search:any;

  viewEmp = true;
  addEmp = false;

  bId = localStorage.getItem("busId")
  
  addEmployees(){
    this.addEmp = true;
    this.viewEmp = false;
  }

  backE(){
    this.viewEmp = true;
    this.addEmp = false;
  }

  fName:any;
  lName:any;
  dob:any;
  gender:any;
  ema:any;
  adrs:any;
  num:any;
  des:any;
  sal:any;
  amtPaid:any;
  empId:any;
  pwd:any;
  cPwd:any;
  yrJn:any;

  addEmpls(){
    let add = {
      firstName: this.fName,
      lastName: this.lName,
      contactNumber: this.num,
      email: this.ema,
      address: this.adrs,
      gender: this.gender,
      totalSalary: this.sal,
      yearJoined: this.yrJn,
      employeeId: this.empId,
      designation: this.des,
      businessId: this.bId,
      dob: this.dob,
      amountPaid: this.amtPaid,
      password: this.pwd,
      confirmPassword: this.cPwd,      
    }
    console.log("before", add);
    if(this.pwd === this.cPwd){

    this.service.addEmployeesList(add).subscribe((data:any) => {
      if(data.success = true){
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Submitted Successfully',
          showConfirmButton: false,
          timer: 1500
        })
        this.viewEmp = true;
        this.addEmp = false; 
        this.ngOnInit();
        console.log("after" ,data);
      }; 
    })
  }else{
    alert("password not matching")
  }
  }

   // Upload image 

   img:any;
   url = "./assets/images/1.png"
   onselectFile(e){
     // console.log("upload----",e);
     
     if(e.target.files){
       var reader = new FileReader();
       reader.readAsDataURL(e.target.files[0]);
       reader.onload = (e:any) => {
         this.url = e.target.result;
         console.log("Uploaded Image-------->",this.url);
         
       }
     }
   }

  completefields(addFields:NgForm){
    addFields.reset();
  }

  emp:any;
  firstN:any;
  lastN:any;
  email:any;
  gen:any;
  date:any;
  address:any;
  numbr:any;
  salary:any;
  yearJoin:any;
  desgn:any;
  id:any;

  editStaff(account){
    this.emp = account.employeeId
    this.firstN = account.firstName
    this.lastN = account.lastName
    this.ema = account.email
    this.gen = account.gender
    this.date = account.dob
    this.address = account.address
    this.numbr = account.contactNumber
    this.salary = account.totalSalary
    this.yearJoin = account.yearJoined
    this.desgn = account.designation
    this.id = account.id
  }

  updateAcctnt(){
    let update = {
      id:this.id,
      employeeId: this.emp,
      firstName: this.firstN,
      lastName: this.lastN,
      contactNumber: this.numbr,
      email: this.ema,
      address: this.address,
      gender: this.gen,
      totalSalary: this.salary,
      yearJoined: this.yearJoin,
      dob: this.date,
      designation: this.desgn,
      businessId: this.bId
    }
    console.log("before", update);
    this.service.updateEmployeesList(update).subscribe((data:any) => {
      console.log("after", data);
      this.ngOnInit();
    })
  }

  delStaff(id){
    this.service.deleteEmployeesList(id).subscribe((data:any) =>{
      if(data.success = true){
        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.value) {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
          }
          this.ngOnInit();
        })
        console.log("deleted", data);
      }
    })
  }
  
  viewList:any=[]
  ngOnInit() {
    this.service.viewEmployeesList(this.bId).subscribe((data:any) => {
      this.viewList = data.data
      console.log("other" , this.viewList);
      
    })
  }

}
