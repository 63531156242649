import { Component, OnInit } from '@angular/core';
import { AdminService } from './admin.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  constructor(private service : AdminService, private router:Router) { }
 
  logOut() {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('busId');
    this.router.navigate(['/']);
  }

  adminId = localStorage.getItem("userId")
 
  profileName;
  ngOnInit() {
    this.service.getprofile(this.adminId).subscribe((data: any) => {
      this.profileName = data.data.name
      console.log("Profile_Name", this.profileName);
    })
  }

}
