import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { LibrarianService } from '../librarian.service';

@Component({
  selector: 'app-due-books',
  templateUrl: './due-books.component.html',
  styleUrls: ['./due-books.component.scss']
})
export class DueBooksComponent implements OnInit {

  search:any;
  
  constructor(
    private lService:LibrarianService
  ) { }


  view=true;
  addDueBook=false;

  add(){
    this.view=false;
    this.addDueBook=true;
  }
  back(){
    this.view=true;
    this.addDueBook=false;
  }

  completefields(addFields:NgForm){
    addFields.reset();
  }
  cancel(addFields:NgForm){
    addFields.reset();
  }
  ngOnInit() {
  }

}
