import { Component, OnInit } from '@angular/core';
import { AccountsService } from '../accounts.service';

@Component({
  selector: 'app-employee-salary',
  templateUrl: './employee-salary.component.html',
  styleUrls: ['./employee-salary.component.scss']
})
export class EmployeeSalaryComponent implements OnInit {

  constructor(private aService:AccountsService) { }

  viewList=true;
  addList=false;

  addLists(){
    this.viewList=false;
    this.addList=true;
  }
  back(){
    this.viewList=true;
    this.addList=false;
  }

  ngOnInit() {
  }

}
