import { Component, OnInit} from '@angular/core';
import { StudentService } from '../student.service';
import { Router } from '@angular/router';
import {FormControl , Validators, NgForm} from '@angular/forms';
import Swal from 'sweetalert2'


@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
  
  searchPro:any;
  searchTran:any;
  searchHos:any;

  feedback=true;
  rating=false;
  professor=false;
  transport=false;
  hostel=false;

  proName
  brating
  trating
  dname
  dID
  dbrating
  drating
  cond
  timing
  comt
  toverall
  live
  hname
  foodRate
  roomRate
  WashrmRate
  factRate
  comments
  
  myform
  selectedDep

  feedbk(){
    this.feedback=false;
    this.rating=true;
  }
  prof(){
    this.professor=true;
    this.transport=false;
    this.hostel=false;
  }
  trans(){
    this.professor=false;
    this.transport=true;
    this.hostel=false;
  }
  hos(){
    this.professor=false;
    this.transport=false;
    this.hostel=true;
   }
  back(){
    this.feedback=true;
    this.rating=false;
    this.professor=false;
    this.transport=false;
    this.hostel=false;

  }

  constructor(private service:StudentService, private router:Router) { }


  studentId = localStorage.getItem("userId")
  viewfd = []
  viewTransfb=[]
  viewHostelFb=[]
  

  year = localStorage.getItem("year");
  dep = localStorage.getItem("department");
  sem = localStorage.getItem("sem");
  sec = localStorage.getItem("section");
  id = localStorage.getItem("userId");
  course = localStorage.getItem("course");
  bid = localStorage.getItem("busId");
  
  subName;
  subjec;
 
  viewSubject(data){
    this.subName="";
    let dropDownData = this.prolst.find((list:any) =>
    list.lecturerName === data);
  
    if (dropDownData){
      this.subjec = dropDownData.subject;
      // console.log("aa", this.subjec);
      
      if(this.subjec){
        this.subName=this.subjec;
      }
      else{
        this.subjec;
      }
    }
  }

  addfbk;
  addFeedback(addProFields:NgForm){
    let afb = { 
      department:this.dep,
      year:this.year,
      semester:this.sem,
      course:this.course,
      lecturerName:this.proName,
      subject:this.subName,
      behaviour:this.brating,
      teaching:this.trating,
      userName:this.id,
      liveExamples:this.live,
      businessId:this.bid
    }
    this.service.addFeedback(afb).subscribe((data:any)=>{
      // this.addfbk = data.data
      alert("submitted successfully");
      addProFields.reset();
      this.feedback=true;
      this.rating=false;
      this.professor=false;
      this.transport=false;
      this.hostel=false;
      this.ngOnInit();
      console.log("add_Professor_Feedback",data);
    })
  }

  addTransportFdbk(addTranFields:NgForm){
    let tfb = { 
      driverName:this.dname,
      driverId:this.dID,
      behaviour:this.dbrating,
      driving:this.drating,
      condition:this.cond,
      timing:this.timing,
      comments:this.comt,
      overAllFeedBack:this.toverall,
      userName:this.id,
      studentId:this.id,
      businessId:this.bid
    }
    this.service.addTransportFdbk(tfb).subscribe((data:any)=>{
      alert("submitted successfully");
      addTranFields.reset();
      this.feedback=true;
      this.rating=false;
      this.professor=false;
      this.transport=false;
      this.hostel=false;
      this.ngOnInit();
      console.log("add_Transport_Feedback", data);
      
    });
    // this.myform.reset();

  }
  addHostelFdbk(addHosFields:NgForm){
    let hfb = { 
      buildingName:this.hname,
      food:this.foodRate,
      rooms:this.roomRate,
      washRooms:this.WashrmRate,
      facilities:this.factRate,
      comments:this.comments,
      userName:this.id,
      businessId:this.bid
    }
    this.service.addHostelFdbk(hfb).subscribe((data:any)=>{
      alert("submitted successfully");
      addHosFields.reset();
      this.feedback=true;
      this.rating=false;
      this.professor=false;
      this.transport=false;
      this.hostel=false;
      this.ngOnInit();
      console.log("Add_Hostel_Feedback", data);
    });
  }

  prolst;
  viewHosList:any = []
  ngOnInit() {
 
  
  this.service.getProfessorfeedbackDetails(this.studentId).subscribe((fbdetails:any) =>{
    this.viewfd = fbdetails.data
    console.log("View_Professor_feedback", this.viewfd);
  });

  this.service.professorlst(this.studentId).subscribe((data:any) =>  {
    this.prolst = data.data
    console.log("pro_list", this.prolst)
});

this.service.transportFeedback(this.studentId).subscribe((transportfb:any)=>{
  this.viewTransfb = transportfb.data
  console.log("View_Transport_feedback", this.viewTransfb);
});

this.service.hostelFeedback(this.studentId).subscribe((hosFb:any)=>{
  this.viewHostelFb = hosFb.data
  console.log("View_Hostel_feedback", this.viewHostelFb);
});

this.service.viewHostelList().subscribe((data:any) => {
  this.viewHosList = data.data
  console.log("View_Hostel_List", this.viewHosList) 
});

  }

}
