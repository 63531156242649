import { Component, OnInit } from '@angular/core';
import { ParentService } from '../parent.service';

@Component({
  selector: 'app-academics',
  templateUrl: './academics.component.html',
  styleUrls: ['./academics.component.scss']
})
export class AcademicsComponent implements OnInit {

  constructor(private service: ParentService) { }

  searchSyl:any;
  searchNot:any;
  searchNotif:any;

  
  syllabus = false;
  sear = true;
  backBtnFalse = false;

  subJectDetails(subject) {
    this.syllabus = true;
    this.sear = false;
    this.backBtnFalse = true;

    this.service.viewSyllabus(subject).subscribe((syllabus: any) => {
      this.viewLessions = syllabus.data;
      console.log("viewS", this.viewLessions);
    })

    this.service.lectureNotes(subject).subscribe((notes: any) => {
      this.notesLecture = notes.data;
      console.log("viewN", this.notesLecture);
    })
  }  

  back() {
    this.sear = true;
    this.syllabus = false;
    this.backBtnFalse = false;
  }

  viewLessions = []
  notesLecture = []
  viewNotifications = []

  subjects: any = []

  id = localStorage.getItem("studentId");

  ngOnInit() {
    
    this.service.getAcademicSubjects(this.id).subscribe((totalSubjects: any) => {
      this.subjects = totalSubjects.data;
      console.log("allSubjects", this.subjects);
    })

    this.service.viewNotify(this.id).subscribe((notifctn: any) => {
      this.viewNotifications = notifctn.data;
      console.log("notifications", this.viewNotifications);
    })

  }
}
