import { Component, OnInit } from '@angular/core';
import {FormControl , Validators, NgForm} from '@angular/forms';
import { Router } from '@angular/router';
import { AdminService } from '../admin.service';
import Swal from 'sweetalert2'
import { HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-students',
  templateUrl: './students.component.html',
  styleUrls: ['./students.component.scss']
})
export class StudentsComponent implements OnInit {
  hide = true;
  hide1 = true;
  constructor(private service : AdminService) { }

  emailId = new FormControl('', [Validators.required, Validators.email]);
  
  getErrorMessage() {
    return this.emailId.hasError('required') ? 'Enter a valid Email' :
        this.emailId.hasError('email') ? 'Not a valid email' :
            '';
  }

  name = new FormControl('', [Validators.required]);
  getMessage(){
    return this.name.hasError('required') ? 'Field is Required': ''
  }

  search:any;
  
  viewStudents = true;
  addStudent = false;

  students(){
    this.addStudent = true;
    this.viewStudents = false;
  }

  backS(){
    this.viewStudents = true;
    this.addStudent = false;
  }

  stdId
  fName
  lName
  gender
  pName
  adrs
  des
  cour
  dep
  sem
  sec
  yr
  curYear
  curPrd
  dob
  ema
  con
  fee
  pass
  cPass
  pUser
  pNum
  paEma
  curPay
  nextPay
  terms
  amtPaid
  sclTyp
  sclAmt

  bId = localStorage.getItem("busId")

  addStud(){
    let add = {
      studentId:this.stdId,
      firstName:this.fName,
      lastName:this.lName,
      gender:this.gender,
      email:this.ema,
      contactNumber:this.con,
      dob:this.dob.getTime(),
      yearPeriod:this.curPrd,
      section:this.sec,
      fee:this.fee,
      address:this.adrs,
      password:this.pass,
      designation:this.des,
      confirmPassword:this.cPass,
      businessId:this.bId,
      noOfTerms:this.terms,
      department:this.dep,
      course:this.cour,
      year:this.yr,
      semester:this.sem,
      amountPaid:this.amtPaid,
      currentPaymentDate:this.curPay,
      nextPaymentDate:this.nextPay,
      parentName:this.pName,
      scholarshipAmount:this.sclAmt,
      parentUserName:this.pUser,
      parentPhoneNumber:this.pNum,
      parentEmail:this.paEma,
      scholarshipType:this.sclTyp,
      profilePic:this.img,
      currentYear:this.curYear
    }
    console.log("before", add);
    console.log("profilePic-----", add.profilePic);
     if(this.pass === this.cPass){

    this.service.addStudentList(add).subscribe((data:any) => {
      if(data.success = true){
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Submitted Successfully',
          showConfirmButton: false,
          timer: 1500
        })
        this.ngOnInit();
        console.log("after", data);
      };
    })
   }else{
     alert("password not matching")
   }
  }


  // Upload image 

  img:any;
  url = "./assets/images/1.png"
  onselectFile(e){
    // console.log("upload----",e);
    
    if(e.target.files){
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (e:any) => {
        this.url = e.target.result;
        console.log("Uploaded Image-------->",this.url);
        
      }
    }
  }

  completefields(addFields:NgForm){
    this.viewStudents = true;
        this.addStudent = false;
    addFields.reset();
  }

  studId
  fstName
  ltName
  email
  course
  adrss
  dept
  semtr
  year
  sectn
  gen
  desgn
  curntYear
  curntPrd
  date
  numbr
  fees
  prName
  prNum

  editStud(data){
    this.studId = data.studentId
    this.fstName = data.firstName
    this.ltName = data.lastName
    this.email = data.email
    this.course = data.course
    this.adrss = data.address
    this.dept = data.department
    this.semtr = data.semester
    this.year = data.year
    this.sectn = data.section
    this.gen = data.gender
    this.desgn = data.designation
    this.curntYear = data.currentYear
    this.curntPrd = data.yearPeriod
    this.date = data.dob
    this.numbr = data.contactNumber
    this.fees = data.fee
    this.prName = data.parentName
    this.prNum = data.parentPhoneNumber
    }

    updateStudent(){
      let updt = {
        studentId:this.studId,
        firstName:this.fstName,
        lastName:this.ltName,
        gender:this.gen,
        email:this.email,
        contactNumber:this.numbr,
        dob:this.date,
        yearPeriod:this.curntPrd,
        section:this.sectn,
        fee:this.fees,
        address:this.adrss,
        designation:this.desgn,
        department:this.dept,
        course:this.course,
        year:this.year,
        semester:this.semtr,
        parentName:this.prName,
        parentPhoneNumber:this.prNum,
        businessId:this.bId
      }
      console.log("before", updt);
      this.service.updateStudentList(updt).subscribe((data:any) => {
        this.ngOnInit();
        console.log("after", data);
      })
    }

  deleteStud(id){
    this.service.deleteStudentList(id).subscribe((data:any) => {
      if(data.success = true){
        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.value) {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
          }
          this.ngOnInit();
        })
        console.log("deleted", data);
      }
    })
  }
  
fileData;
fileUploadProgress = 0;
// Upload file
selectFile(fileInput:any){
  this.fileData = <File>fileInput.target.files[0];  
  console.log('fileProgress-----------', this.fileData, this.fileData.name);
}
upload(){
this.fileUploadProgress = 0;
			let formData = new FormData();
			formData.append('file', this.fileData);
			console.log('upload File-------------------', this.fileData.name);
			this.service.uploadfile(formData)
				.subscribe((res) => {
					if (res.type == HttpEventType.UploadProgress) {
						this.fileUploadProgress = Math.round(res.loaded / res.total * 100);
            console.log('Upload progress: ', Math.round(res.loaded / res.total * 100), res);
            console.log('Upload Saccess', res);
            
					} else if (res.type === HttpEventType.Response) {
						if (res.body) {
						} else {
						}
          }
          this.ngOnInit();
				});       
      }

  studList:any = [];
  depLst:any = []
  ngOnInit() {
    this.service.viewStudentList(this.bId).subscribe((data : any) =>{
      this.studList = data.data;
      console.log("Student_List", this.studList);
  })
  
  this.service.viewDepartments().subscribe((data:any) =>{
    this.depLst=data.data
  })
  }

}
