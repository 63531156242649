import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'custom'
})
export class CustomPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!args) {
      return value;
    }
    return value.filter((val:any) => {
      let rVal = (val.employeeId.toLowerCase().includes(args)) || (val.name.toLowerCase().includes(args)) ||  (val.branchName.toLowerCase().includes(args));
      return rVal;
    })   

  }

  transforms(value1: any, args1?: any): any {
    if (!args1) {
      return value1;
    }
    return value1.filter((val1:any) => {
      let rVal1 = (val1.name.toLowerCase().includes(args1)) || (val1.branchName.toLowerCase().includes(args1));
      return rVal1;
    })   

  }


  transforms1(value2: any, args2?: any): any {
    if (!args2) {
      return value2;
    }
    return value2.filter((val2:any) => {
      let rVal2 = (val2.name.toLowerCase().includes(args2)) || (val2.businessId.toLowerCase().includes(args2));
      return rVal2;
    })   

  }
}
