import { Component, OnInit } from '@angular/core';
import { SuperAdminService } from '../super-admin.service';
import { Router } from '@angular/router';
import {FormControl , Validators, NgForm} from '@angular/forms';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-schools',
  templateUrl: './schools.component.html',
  styleUrls: ['./schools.component.scss']
})
export class SchoolsComponent implements OnInit {

  search:any;

  email = new FormControl('', [Validators.required, Validators.email]);

  constructor(private service: SuperAdminService, private router: Router) {

  }
  getErrorMessage() {
    return this.email.hasError('required') ? 'Enter a valid Email id' :
        this.email.hasError('email') ? 'Not a valid email' :
            '';
  }

  code: any
  sName: any
  addrs: any
  cNum: any
  ema: any

  snam: any
  addr: any
  cnum: any
  em: any
  cod: any

  schoolId;

  addScl() {
    let sclDetails = {
      name: this.sName,
      address: this.addrs,
      contactNumber: this.cNum,
      email: this.ema,
      businessId: this.code,
      id: this.schoolId
    }      
    console.log("ssss", sclDetails);
    this.service.addSchool(sclDetails).subscribe((school: any) => {
      console.log("added Schools", school);
      // alert("School is added successfully...");
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Your work has been saved',
        showConfirmButton: false,
        timer: 1500
      })
      this.addSchools = false;
      this.viewSchools = true;
      this.ngOnInit();
    })
  }
  completefields(addFields:NgForm){
    addFields.reset();
  }
  cancel(addFields:NgForm){
    addFields.reset();

  }

  updateSchool() {
    let details = {
      name: this.snam,
      address: this.addr,
      contactNumber: this.cnum,
      email: this.em,
      id: this.schoolId,
      businessId: this.cod
    }
    console.log("de", details);
    this.service.editSchool(details).subscribe((sclEdit: any) => {
      console.log("edit", sclEdit);
      this.ngOnInit();
      if (sclEdit.success = true) {
        this.schoolId = sclEdit.data.id
        this.snam = sclEdit.data.name
        this.cod = sclEdit.data.businessId
        this.addr = sclEdit.data.address
        this.cnum = sclEdit.data.contactNumber
        this.em = sclEdit.data.email
      }
    })
  }

  deleteScl(id) {
    this.service.deleteSchool(id).subscribe((delId: any) => {
      if(delId.success = true){
        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.value) {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
          }
          this.ngOnInit();
        })
        
        console.log("deleted school", delId);
      }

    })
  }

  addSchools = false;
  viewSchools = true;
  back = false;

  schools() {
    this.addSchools = true;
    this.viewSchools = false;
    this.back = true;
  }

  addS() {
    this.addSchools = false;
    this.viewSchools = true;
    this.back = false;
  }

  editScl(data) {
    this.snam = data.name
    this.addr = data.address
    this.cnum = data.contactNumber
    this.em = data.email
    this.cod = data.businessId
    this.schoolId = data.id
  }

  allSchools: any = [];

  ngOnInit() {
    this.service.viewSchols().subscribe((sclData: any) => {
      this.allSchools = sclData.data;
      console.log("all schoools", this.allSchools);
    });


  }

}
