import { Injectable } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Constant }  from '../../config';

@Injectable({
  providedIn: 'root'
})
export class HodService {

  constructor(private httpClient: HttpClient) { }

  // Email

  // Compose
  sendMail(mail){
    return this.httpClient.post(Constant.API_ORIGIN_URL+'/email/compose', mail)
  }

  // sent
  mailsSent(id){
    return this.httpClient.get(Constant.API_ORIGIN_URL+'/email/mails/' + id)
  }

  // inbox
  mailsReceive(id){
    return this.httpClient.get(Constant.API_ORIGIN_URL+'/email/mails/' + id)
  }
  
  // delete mail
  mailsDelete(id){
    return this.httpClient.delete(Constant.API_ORIGIN_URL+'/email/deleteMail/' + id)
  }

  //library APIs

  //getBooks API
  getBooks(id){
    return this.httpClient.get(Constant.API_ORIGIN_URL+'/library/getBooksByBusinessId/' + id);
  }

  //View books status  API
  bookStatus(id){
    return this.httpClient.get(Constant.API_ORIGIN_URL+'/student/bookStatus/' + id);
  }
}
