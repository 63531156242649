import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminComponent } from './admin.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { StudentsComponent } from './students/students.component';
import { ProfessorsComponent } from './professors/professors.component';
import { EmployeesComponent } from './employees/employees.component';
import { AccountantsComponent } from './accountants/accountants.component';
import { DepartmentsComponent } from './departments/departments.component';
import { CoursesComponent } from './courses/courses.component';
import { SubjectsComponent } from './subjects/subjects.component';
import { LibraryComponent } from './library/library.component';
import { AttendanceComponent } from './attendance/attendance.component';
import { MatInputModule, MatDatepicker } from '@angular/material';
import { MatDatepickerModule } from '@angular/material';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminRoutingRoutingModule } from './admin-routing/admin-routing-routing.module';
import { SectionsComponent } from './sections/sections.component';
import { HolidaysComponent } from './holidays/holidays.component';
import { ProfileComponent } from './profile/profile.component';
import { MailComponent } from './mail/mail.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { InfrastructureComponent } from './infrastructure/infrastructure.component';
import { LabAssistantComponent } from './lab-assistant/lab-assistant.component';
import { GraphModule } from '../../graphs/graph.module';
import { StaffMappingComponent } from './staff-mapping/staff-mapping.component';
import {MatProgressBarModule} from '@angular/material/progress-bar'


@NgModule({
  declarations: [
    AdminComponent,
   DashboardComponent, 
   StudentsComponent, 
   ProfessorsComponent, 
   EmployeesComponent, 
   AccountantsComponent, 
   DepartmentsComponent, 
   CoursesComponent, 
   SubjectsComponent, 
   LibraryComponent, 
   AttendanceComponent, 
   SectionsComponent, 
   HolidaysComponent,
   ProfileComponent,
   MailComponent,
   InfrastructureComponent,
   LabAssistantComponent,
   StaffMappingComponent
  ],
  imports: [
    CommonModule,
    MatInputModule,
    MaterialModule,
    MatProgressBarModule,
    FormsModule,
    ReactiveFormsModule,
    AdminRoutingRoutingModule,
    MatDatepickerModule,
    Ng2SearchPipeModule,
    GraphModule
  ]
})
export class AdminModule { }
