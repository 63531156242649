import { Component, OnInit } from '@angular/core';
import { AdminService } from '../admin.service';
import Swal from 'sweetalert2'
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-infrastructure',
  templateUrl: './infrastructure.component.html',
  styleUrls: ['./infrastructure.component.scss']
})
export class InfrastructureComponent implements OnInit {

  constructor(private service : AdminService) { }

  search:any;
  
  bId = localStorage.getItem("busId")

  view=true;
  addInfra=false;

  add(){
    this.view=false;
    this.addInfra=true;
  }
  back(){
    this.view=true;
    this.addInfra=false;
  }

  blkName
  rmName
  frnName
  count

  addInfratr(){

  }
  
  completefields(addFields:NgForm){
    this.view=true;
    this.addInfra=false;
    addFields.reset();
  }

  blckName
  romName
  frntrName
  cont

  
  viewInfra:any =[];
  ngOnInit() {
    this.service.viewInfrastructure(this.bId).subscribe((data:any) => {
      this.viewInfra = data.data
      console.log("Infra list", this.viewInfra);
      
    })
  }

}
