import { Component, OnInit } from '@angular/core';
import { AdminService } from '../admin.service';
import Swal from 'sweetalert2'
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-subjects',
  templateUrl: './subjects.component.html',
  styleUrls: ['./subjects.component.scss']
})
export class SubjectsComponent implements OnInit {

  constructor(private service : AdminService) { }

  search:any;

  course
  dept
  year
  semtr
  subName

  cours
  deprt
  years
  semstr
  subjtNm
  id

  bId = localStorage.getItem("busId")

  view=true;
  addSubject=false;

  add(){
    this.view=false;
    this.addSubject=true;
  }
  back(){
    this.view=true;
    this.addSubject=false;
  }
  
  addSubjects(){
    let sub = {
      year:this.year,
      semester:this.semtr,
      course:this.course,
      department:this.dept,
      subjectName:this.subName,
      businessId:this.bId,
    }
    this.service.addSubject(sub).subscribe((data : any) =>{
      if(data.success = true){
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Submitted Successfully',
          showConfirmButton: false,
          timer: 1500
        })
        
        this.ngOnInit();
        console.log("Add_Subjects", data);
      };  
    })
  }

  completefields(addFields:NgForm){
    this.view=true;
        this.addSubject=false;
    addFields.reset();
  }

  editSub(data){
    this.cours = data.course
    this.deprt = data.department
    this.years = data.year
    this.semstr = data.semester
    this.subjtNm = data.subjectName
    this.id = data.id
  }

  updateSub(){
    let sub = {
      year:this.years,
      semester:this.semstr,
      course:this.cours,
      department:this.deprt,
      subjectName:this.subjtNm,
      businessId:this.bId,
      id:this.id
    }
    console.log("before", sub);
    this.service.updateSubjects(sub).subscribe((data : any) =>{
      console.log("af", data);
      this.ngOnInit();
    })
  }

  deleteSub(id){
    this.service.deleteSubjects(id).subscribe((data : any) =>{
      if(data.success = true){
        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.value) {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
          }
          this.ngOnInit();
        })
        console.log("delete_sub", data);
      }
    })
  }

  subList:any=[];
  depLst:any=[]
  ngOnInit() {
    this.service.viewSubjectList(this.bId).subscribe((data : any) =>{
      this.subList = data.data;
      console.log("Subjects_List", this.subList);
  })

  this.service.viewDepartments().subscribe((data:any) =>{
    this.depLst=data.data
  })
  }

}
