import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from '../admin.service';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss']
})
export class CoursesComponent implements OnInit {

  constructor(private service : AdminService , private router : Router) { }
  
  search:any;

  // busId : any
  // coId : any
  cName : any
  depName : any

  // busnsId : any
  courseN : any
  depN : any
  courId : any

  bId = localStorage.getItem("busId")
  
  viewCo = true;
  addCourses = false;

  courses(){
    this.addCourses = true;
    this.viewCo = false;
  }

  backC(){
    this.viewCo = true;
    this.addCourses = false;
  }

  addCourse(){
    let courseData = {
      // id : this.coId,
      businessId : this.bId,
      course : this.cName,
      department : this.depName
    }

    this.service.addCourseData(courseData).subscribe((courses : any) => {
      if(courses.success = true){
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Submitted Successfully',
          showConfirmButton: false,
          timer: 1500
        })
        
        this.ngOnInit();
        console.log("add course" ,courses );
      }; 
    })
  }
  
  completefields(addFields:NgForm){
    this.viewCo = true;
        this.addCourses = false;
    addFields.reset();
  }
  
  totalCourses:any;

  editCourse(details){
    this.courId = details.id
    // this.bId = details.businessId
    this.courseN = details.course
    this.depN = details.department
  }

  updateCourse(){
    let updateC = {    
      businessId : this.bId,
      id : this.courId,  
      course : this.courseN,
      department : this.depN
    }

    console.log("update" , updateC);

    this.service.editCourseData(updateC).subscribe((edit : any) => {
      console.log("edit course", edit);
      this.ngOnInit();
      if (edit.success = true) {
        this.courId = edit.id
         this.bId = edit.businessId
         this.courseN = edit.course
         this.depN = edit.department
      }
    })
  }

  delCourse(id){
    this.service.delCourse(id).subscribe((data:any) =>{
      if(data.success = true){
        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.value) {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
          }
          this.ngOnInit();
        })
        console.log("delete course" ,data);
      }
    })
  }

  depLst:any = []
  ngOnInit() {
    this.service.viewCourses().subscribe((data : any) =>{     
      this.totalCourses = data;
      console.log("all courses" , data);
    })

    this.service.viewDepartments().subscribe((data:any) =>{
      this.depLst=data.data
    })
  }

}
