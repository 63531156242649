import { Component, OnInit } from '@angular/core';
import { TransportService } from '../transport.service';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-expenses',
  templateUrl: './expenses.component.html',
  styleUrls: ['./expenses.component.scss']
})
export class ExpensesComponent implements OnInit {

  constructor(
    private service:TransportService
  ) { }
  
  search:any;
  
  viewExpense=true;
  addExpenses=false;

  addExpense(){
    this.viewExpense=false;
    this.addExpenses=true;
  }
  back(addFields){
    this.viewExpense=true;
    this.addExpenses=false;
    addFields.reset();
  }

  busNmbr
  busTyp
  srvDate
  srvNxtDate
  amt
  servDetail

  expenseAdd(){
    let add = {
      busno:this.busNmbr,
      busType:this.busTyp,
      serviceDate:this.srvDate,
      nextServiceDate:this.srvNxtDate,
      cost:this.amt,
      serviceDetails:this.servDetail,
      businessId:this.bussId
    }
    console.log("before", add);
    this.service.addExpenses(add).subscribe((data:any) => {
      console.log("after", data);
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Submitted Successfully',
        showConfirmButton: false,
        timer: 1500
      })
      this.viewExpense=true;
    this.addExpenses=false;
      this.ngOnInit();
    })
  }

  completefields(addFields:NgForm){
    addFields.reset();
  }

  busNum
  busTp
  srvDt
  srvNtDt
  amount
  serviceDetail
  id

  editExpense(data){
    this.busNum = data.busno
    this.busTp = data.busType
    this.srvDt = data.serviceDate
    this.srvNtDt = data.nextServiceDate
    this.amount = data.cost
    this.serviceDetail = data.serviceDetails
    this.id = data.id
  }

  updateExpenses(){
    let up = {
      busno:this.busNum,
      busType:this.busTp,
      serviceDate:this.srvDt,
      nextServiceDate:this.srvNtDt,
      cost:this.amount,
      serviceDetails:this.serviceDetail,
      businessId:this.bussId,
      id:this.id
    }
    console.log("before", up);
    this.service.updateExpenses(up).subscribe((data:any) => {
      console.log("after", data);
      this.ngOnInit();
    })
  }

  deleteExpense(id){
    this.service.deleteExpenses(id).subscribe((data:any) => {
      alert("Deleted");
      this.ngOnInit();
      console.log("deleted", data);    
    })
  }

  bussId = localStorage.getItem("busId")
  viewExpenses:any = []

  ngOnInit() {
    this.service.getExpenses(this.bussId).subscribe((data:any) =>{
      this.viewExpenses = data.data
      console.log("view expenses", this.viewExpenses);
    })
  }

}
