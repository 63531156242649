import { Component, OnInit } from '@angular/core';
import { AccountsService } from '../accounts.service';

@Component({
  selector: 'app-student-fees',
  templateUrl: './student-fees.component.html',
  styleUrls: ['./student-fees.component.scss']
})
export class StudentFeesComponent implements OnInit {

  constructor(private aService:AccountsService) { }

  viewList=true;
  addList=false;

  addLists(){
    this.viewList=false;
    this.addList=true;
  }
  back(){
    this.viewList=true;
    this.addList=false;
  }

  ngOnInit() {
  }

}
