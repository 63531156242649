import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TransportRoutingModule } from './transport-routing.module';
import { BusesComponent } from './buses/buses.component';
import { DriversComponent } from './drivers/drivers.component';
import { StopsComponent } from './stops/stops.component';
import { AllocationComponent } from './allocation/allocation.component';
import { EmailComponent } from './email/email.component';
import { TransportHomeComponent } from './transport-home/transport-home.component';
import { MatInputModule } from '@angular/material';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule} from '@angular/forms';
import { ExpensesComponent } from './expenses/expenses.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { TrackingComponent } from './tracking/tracking.component';
import { Ng2SearchPipeModule } from "ng2-search-filter";


@NgModule({
  declarations: [
    BusesComponent,
    DriversComponent,
    StopsComponent,
    AllocationComponent,
    EmailComponent,
    TransportHomeComponent,
    ExpensesComponent,
    FeedbackComponent,
    TrackingComponent   
  ],
  imports: [
    CommonModule,
    TransportRoutingModule,
    MatInputModule,
    MaterialModule,
    FormsModule,
    Ng2SearchPipeModule,
  ]
})
export class TransportModule { }
