import { Component, OnInit } from '@angular/core';
import { AdminService } from '../admin.service';
import Swal from 'sweetalert2'
import { NgForm, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-library',
  templateUrl: './library.component.html',
  styleUrls: ['./library.component.scss']
})
export class LibraryComponent implements OnInit {

  hide = true;
  hide1 = true;
  
  constructor(private adminService : AdminService) { }

  search:any;

  emailId = new FormControl('', [Validators.required, Validators.email]);
  
  getErrorMessage() {
    return this.emailId.hasError('required') ? 'Enter a valid Email' :
        this.emailId.hasError('email') ? 'Not a valid email' :
            '';
  }
  
  viewLibrararians = true;
  addLib = false;

  addLibrararians(){
    this.addLib = true;
    this.viewLibrararians = false;
  }

  backL(){
    this.viewLibrararians = true;
    this.addLib = false;
  }

  
  bId = localStorage.getItem("busId")

  empId:any;
  fName:any;
  lName:any;
  gender:any;
  adrs:any;
  email:any;
  sal:any;
  num:any;
  amtPaid:any;
  pwd:any;
  cPwd:any;
  yrJn:any;
  des:any;
  dob:any;

  addLibrararian(){
    let add = {
      employeeId: this.empId,
      firstName: this.fName,
      lastName: this.lName,
      contactNumber: this.num,
      email: this.email,
      address: this.adrs,
      gender: this.gender,
      totalSalary: this.sal,
      amountPaid: this.amtPaid,
      yearJoined: this.yrJn,
      dob: this.dob,
      password: this.pwd,
      confirmPassword: this.cPwd,
      designation: this.des,
      businessId: this.bId
    }
    console.log("before", add);
    if(this.pwd === this.cPwd){

    this.adminService.addLibrarian(add).subscribe((data:any) => {
      if(data.success = true){
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Submitted Successfully',
          showConfirmButton: false,
          timer: 1500
        })
        
        this.ngOnInit();
        console.log("after", data);
      };
    })
  }else{
    alert("password not matching")
  }
  }

   // Upload image 

   img:any;
   url = "./assets/images/1.png"
   onselectFile(e){
     // console.log("upload----",e);
     
     if(e.target.files){
       var reader = new FileReader();
       reader.readAsDataURL(e.target.files[0]);
       reader.onload = (e:any) => {
         this.url = e.target.result;
         console.log("Uploaded Image-------->",this.url);
         
       }
     }
   }

  completefields(addFields:NgForm){
    this.viewLibrararians = true;
        this.addLib = false;
    addFields.reset();
  }

  emp
  firstN
  lastN
  ema
  address
  numbr
  salary
  date
  desigtn
  yearJoin
  id
  
  editLib(data){
    this.emp = data.employeeId
    this.firstN = data.firstName
    this.lastN = data.lastName
    this.ema = data.email
    this.address = data.address
    this.numbr = data.contactNumber
    this.salary = data.totalSalary
    this.date = data.dob
    this.desigtn = data.designation
    this.yearJoin = data.yearJoined
    this.id = data.id
  }

  updatelibran(){
    let lib = {
      id : this.id,
      employeeId : this.emp,
      firstName : this.firstN,
      lastName : this.lastN,
      email : this.ema,
      address : this.address,
      contactNumber : this.numbr,
      totalSalary : this.salary,
      dob : this.date,
      designation : this.desigtn,
      yearJoined : this.yearJoin,      
    }
    console.log("before", lib);
    
    this.adminService.updateLibrarian(lib).subscribe((data:any) => {
      this.ngOnInit();
      console.log("after", data);      
    });
  }

  deleteLib(id){
    this.adminService.deleteLibrarian(id).subscribe((data:any) =>{
      if(data.success = true){
        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.value) {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
          }
          this.ngOnInit();
        })
        console.log("deleted", data);
      }
    })
  }

  viewLib:any = [];

  ngOnInit() {
    this.adminService.viewLibrarian(this.bId).subscribe((data : any) =>{
      this.viewLib = data.data;
      console.log("Librarian_List", this.viewLib);
  })

  }

}
