import { Component, OnInit } from '@angular/core';
import { HostelService } from '../hostel.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-disciplinary-rule',
  templateUrl: './disciplinary-rule.component.html',
  styleUrls: ['./disciplinary-rule.component.scss']
})
export class DisciplinaryRuleComponent implements OnInit {

  constructor(private hService:HostelService) { }

  search:any;
  
  viewRule=true;
  addRules=false;

  addRule(){
    this.viewRule=false;
    this.addRules=true;
  }
  back(addFields:NgForm){
    addFields.reset();
    this.viewRule=true;
    this.addRules=false;
  }

  rule
  action

  bid = localStorage.getItem("busId");

  rl
  actn
  id

  ruleAdd(){
    let add = {
      rules:this.rule,
      action:this.action,
      businessId:this.bid
    }
    console.log("before", add);
    this.hService.addRules(add).subscribe((data:any) => {
      console.log("after", data);
      alert("Submitted Successfully");
     
      this.ngOnInit();
    })
  }

  
  completefields(addFields:NgForm){
    this.viewRule=true;
    this.addRules=false;
    addFields.reset();
  }
  
  editRule(data){
    this.rl = data.rules
    this.actn = data.action
    this.id = data.id
  }

  updateRule(){
    let updt = {
      id:this.id,
      rules:this.rl,
      action:this.actn,
      businessId:this.bid
    }
    console.log("before", updt);
    this.hService.updateRules(updt).subscribe((data:any) => {
      console.log("after", data);
      this.ngOnInit();
    })
  }

  deleteRule(id){
    this.hService.deleteRules(id).subscribe((data:any) => {
      console.log("deleted", data);
      alert("Deleted");
      this.ngOnInit();
    })
  }

  viewRuleList:any = [];
  ngOnInit() {
    this.hService.viewRulesList().subscribe((data:any) => {
      this.viewRuleList = data.data
      console.log("View_List", this.viewRuleList);
    })
  }

}
