import { Component, OnInit } from '@angular/core';
import { AccountsService } from '../accounts.service';

@Component({
  selector: 'app-store-expenses',
  templateUrl: './store-expenses.component.html',
  styleUrls: ['./store-expenses.component.scss']
})
export class StoreExpensesComponent implements OnInit {

  constructor(private aService:AccountsService) { }

  viewList=true;
  addList=false;

  addLists(){
    this.viewList=false;
    this.addList=true;
  }
  back(){
    this.viewList=true;
    this.addList=false;
  }

  ngOnInit() {
  }

}
