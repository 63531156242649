import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {

  constructor() { }

  viewOrder=true;
  addOrdr=false;

  
  addOrder(){
    this.viewOrder=false;
    this.addOrdr=true;
  }
  back(){
    this.viewOrder=true;
    this.addOrdr=false;
  }

  ngOnInit() {
  }

}
