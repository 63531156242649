import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from '../admin.service';
import Swal from 'sweetalert2'
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-sections',
  templateUrl: './sections.component.html',
  styleUrls: ['./sections.component.scss']
})
export class SectionsComponent implements OnInit {

  constructor(private service : AdminService , private router : Router) { }

  search:any;
  
  // secId : any
  secName : any
  course : any
  // busId : any
  sem : any
  year : any
  dep : any



  idSec : any
  nameSec : any
  cou : any
  // idBus : any
  semt : any
  yr : any
  dept : any

  bId = localStorage.getItem("busId")

  add = true;
  viewSections = true;
  addSection = false;
  backToSec = false;

  sections(){
    this.addSection = true;
    this.viewSections = false;
  }

  backS(){
    this.viewSections = true;
    this.addSection = false;
  }

  addSec(){
      let details = {
        // id : this.secId ,
        sectionName : this.secName ,
        course : this.course ,
        businessId : this.bId ,
        semester : this.sem ,
        year : this.year ,
        department : this.dep
      }

      this.service.addSects(details).subscribe((secData : any) => {
        if(secData.success = true){
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Submitted Successfully',
            showConfirmButton: false,
            timer: 1500
          })
          this.ngOnInit();
          console.log("add section",secData);
        };   
      })
    }

    completefields(addFields:NgForm){
      this.viewSections = true;
          this.addSection = false;
      addFields.reset();
    }
    
  editSec(data){
    this.idSec = data.id 
    this.nameSec = data.sectionName
    this.cou = data.course
    this.bId = data.businessId
    this.semt = data.semester
    this.yr = data.year
    this.dept = data.department
  }


  updateSect(){
    let update = {
      id : this.idSec ,
      sectionName : this.nameSec ,
      course : this.cou ,
      businessId : this.bId ,
      semester : this.semt ,
      year : this.yr ,
      department : this.dept
    }

    this.service.editSecData(update).subscribe((editData : any) => {
      console.log("edit section" , editData);
      this.ngOnInit();
    })
  }


  delSect(id){
    this.service.deleteSect(id).subscribe((delId : any) => {
      if(delId.success = true){
        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.value) {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
          }
          this.ngOnInit();
        })
        console.log("delete section" ,delId);
      }
    })
  }

  allSections : any
  depLst:any=[]
  ngOnInit() {

    this.service.viewSections().subscribe((sect : any) =>{
      this.allSections = sect.data;
      console.log("all sections" , sect);
    })

    this.service.viewDepartments().subscribe((data:any) =>{
      this.depLst=data.data
    })
  }

}
