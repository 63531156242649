import { Component, OnInit } from '@angular/core';
import { StudentService } from '../../student.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-gate-attendance',
  templateUrl: './gate-attendance.component.html',
  styleUrls: ['./gate-attendance.component.scss']
})
export class GateAttendanceComponent implements OnInit {

  constructor(private service:StudentService, private router:Router) { }

  monthlyAt;
  weeklyAt;
  dayAtnd;

  monthlyAtd =true;
  weeklyAtd = false;
  dateAtd = false;

  id = localStorage.getItem("userId");
  
  backToAtd(){
    this.router.navigate(['/student/attendance']);  
  }

  backToMonthly(){
    this.monthlyAtd = true;
    this.weeklyAtd = false;
  }
  backToWeekly(){
    this.dateAtd = false;
    this.weeklyAtd = true;
  }
  
  monthlyAttendance:any =[]
  gateAtd(){
    let userId = {
      userName: this.id
  }
  this.service.monthAttnd(userId).subscribe((data:any) => {
    this.monthlyAttendance = data.data;
    console.log("monthly Attendance", this.monthlyAttendance);
  })
  }

  newWat:any;
  weeklyAtt:any;
  weeklyAttendance: any =[]
  
  weekly(month){
    this.monthlyAtd = false;
    this.weeklyAtd = true;
    
    let wkAtd = {
      userName: this.id,
      month:month
    }

    this.service.weekAttnd(wkAtd).subscribe((data:any) => {
      this.weeklyAttendance = data.data
      let wAt = data.data
      this.newWat = []
      // this.weeklyAtt = []
      console.log("newWeeks-----", this.newWat);
      console.log("present absent",this.weeklyAtt);
      
      for (let obj of wAt) {      
        // console.log("weekkkk----------", obj);    
        // this.weeklyAtt.push(obj)
        for(let day in obj){
          console.log("dayyyy-------",day);  
          var weeks = day.split('');
          console.log("Only Weeksss-------", weeks);     
          this.newWat.push(day)         
        }      
      }
      console.log("weekly attendance",this.weeklyAttendance);      
    })
  }

  date(){
    this.dateAtd = true;
    this.weeklyAtd = false;
  }

  ngOnInit() {
    this.gateAtd();
  }

}
