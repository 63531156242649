import { Component, OnInit } from '@angular/core';
import { ProfessorService } from '../../professor.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-classroom-attendance',
  templateUrl: './classroom-attendance.component.html',
  styleUrls: ['./classroom-attendance.component.scss']
})
export class ClassroomAttendanceComponent implements OnInit {

  constructor(private pService: ProfessorService, private router:Router) { }

  bId = localStorage.getItem("busId")
  professorId = localStorage.getItem("userId")

  clsAttd=true;
  stdClsAttd=false;

  studentList:any=[]
  subj;

  searchAtd;
  
  
  back(){
    this.router.navigate(['/professor/attendance']);   
  }

  backToSub(){
    this.stdClsAttd=false;
    this.clsAttd=true;
  }

  viewDetails(data){
    this.stdClsAttd=true;
    this.clsAttd=false;
    let sub={
      userName:this.professorId,
      subject:data,
      businessId:this.bId
    }
    console.log("before", sub)
    this.subj=sub.subject
    console.log("s",this.subj);
    
    this.pService.viewStudentList(sub).subscribe((data:any) =>{
      this.studentList=data.data
      console.log("student list",this.studentList);
    })
  }

  attd;
  date;
  addAttd;
  options= ["P", "A"];
 filter;
  // radioChange(event:MatRadioButton,studentId){    
  //   this.filter = event.value;
  //   console.log("sending value", this.filter,studentId);    
  // }
  addAttendance(event,studentId){
    console.log("sending value",studentId); 
    let add={
      studentIdList:[studentId],
      subject:this.subj,
      date:this.date,
      attendance:event.value
    }
    console.log("before", add);
    this.pService.addStudentAttd(add).subscribe((data:any)=> {
      console.log("add attendance", data);
    })
  }

  viewSub:any =[]
  ngOnInit() {
    this.pService.viewSubjects(this.professorId).subscribe((sub:any) => {
      this.viewSub = sub.data
      console.log("View_Subjects", this.viewSub)
    });
  }

}
