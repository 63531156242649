import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuperAdminComponent } from './super-admin.component';
import { SchoolsComponent } from './schools/schools.component';
import { CollegesComponent } from './colleges/colleges.component';
import { AdminsComponent } from './admins/admins.component';
import { EmailComponent } from './email/email.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SuperAdminRoutingRoutingModule } from './routing/super-admin-routing.module';
import { MatInputModule } from '@angular/material';
import { MaterialModule } from 'src/app/material.module';
import {MatDatepickerModule, MatDatepicker} from '@angular/material/datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomPipe } from './custom.pipe';
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { GraphModule } from '../../graphs/graph.module';

@NgModule({
  declarations: [
    SuperAdminComponent,
    SchoolsComponent,
    CollegesComponent,
    AdminsComponent,
    EmailComponent,
    DashboardComponent,
    CustomPipe
  ],
  imports: [
    CommonModule,
    SuperAdminRoutingRoutingModule,
    MatInputModule,
    MaterialModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    Ng2SearchPipeModule,
    GraphModule
  ]
  
})
export class SuperAdminModule { }
