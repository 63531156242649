import { Component, OnInit } from '@angular/core';
import { CanteenService } from '../canteen.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  constructor(private cService:CanteenService) { }

  viewMenu=true;
  addMnu=false;

  restId:any;
  mainMenu
  subMenu
  itemName
  order
  price

  addMenu(){
    this.viewMenu=false;
    this.addMnu=true;
  }
  back(){
    this.viewMenu=true;
    this.addMnu=false;
  }

  
  menuAdd(){
    let menu = {
      name:this.mainMenu,
      restaurantId:this.restId,
      subMenu:{
        items:{
          itemName:this.itemName,
          orderFor:this.order,
          price:this.price
        },
        name:this.subMenu,
      },
    }
    console.log("before", menu);
    
    this.cService.menuAdd(menu).subscribe((data:any) => {
      console.log("menuuuuu", data);
      localStorage.setItem('restntId', data.data.restaurantId);
      
      // if (data.success = true) {
      //   console.log("Add_Menu", data);
      //   localStorage.setItem('restntId', data.data.restaurantId);
      // }    
    })
  }
  restntMenuId = localStorage.getItem('restntId');
  vMenu:any= [];
  ngOnInit() {
    this.cService.menuView(this.restntMenuId).subscribe((data:any) => {
      console.log("vvvvv",data);
      
      if(data.success =true){
        this.vMenu = data.data[length]['DINEINList ']
        console.log("View_Menu", this.vMenu);     
      }
    });
  }

}
