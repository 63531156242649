import { Component, OnInit } from '@angular/core';
import { AdminService } from '../admin.service';
import Swal from 'sweetalert2'
import { NgForm, FormControl, Validators } from '@angular/forms';
import { HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-professors',
  templateUrl: './professors.component.html',
  styleUrls: ['./professors.component.scss']
})
export class ProfessorsComponent implements OnInit {
  hide = true;
  hide1 = true;
  constructor(private service : AdminService) { }

  emailId = new FormControl('', [Validators.required, Validators.email]);
  
  getErrorMessage() {
    return this.emailId.hasError('required') ? 'Enter a valid Email' :
        this.emailId.hasError('email') ? 'Not a valid email' :
            '';
  }
  
  name = new FormControl('', [Validators.required]);
  getMessage(){
    return this.name.hasError('required') ? 'Field is Required': ''
  }

  search:any;
  
  viewProf = true;
  addprof = false;

  bId = localStorage.getItem("busId")
  userid = localStorage.getItem("userId")
  
  addProfessors(){
    this.addprof = true;
    this.viewProf = false;
  }

  backP(){
    this.viewProf = true;
    this.addprof = false;
  }

  empId:any;
  fName:any;
  lName:any;
  gender:any;
  adrs:any;
  ema:any;
  num:any;
  sal:any;
  amtPaid:any;
  paswd:any;
  cpaswd:any;
  yearJn:any;
  dob:any;
  // yearTeh:any;
  // sub:any;
  dep:any;
  // doc:any;
  des:any;

  addProf(){
    let add = {
      firstName: this.fName,
      lastName: this.lName,
      contactNumber: this.num,
      email: this.ema,
      address: this.adrs,
      gender: this.gender,
      totalSalary: this.sal,
      yearJoined: this.yearJn,
      // yearToTeach: this.yearTeh,
      // subjects: this.sub,
      department: this.dep,
      dob: this.dob,
      employeeId: this.empId,
      // documents:this.doc,
      businessId:this.bId,
      designation:this.des,
      password:this.paswd,
      confirmPassword:this.cpaswd,
      profilePic:this.img,
    }
    console.log("before", add);
    console.log("profilePic-----", add.profilePic);
    if(this.paswd === this.cpaswd){

    this.service.addProfessorList(add).subscribe((data:any) =>{
      if(data.success = true){
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Submitted Successfully',
          showConfirmButton: false,
          timer: 1500
        })
        this.ngOnInit();
        console.log("after", data);
      };
    })
  }else{
    alert("password not matching")
  }
  }

  // Upload image 

  img:any;
  url = "./assets/images/1.png"
  onselectFile(e){
    // console.log("upload----",e);
    
    if(e.target.files){
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (e:any) => {
        this.url = e.target.result;
        console.log("Uploaded Image-------->",this.url);
        
      }
    }
  }


  completefields(addFields:NgForm){
    this.viewProf = true;
    this.addprof = false;
    addFields.reset();
  }

  emId:any;
  fNm:any;
  lNm:any;
  gen:any;
  email:any;
  addr:any;
  numbr:any;
  desgn:any;
  saly:any;
  // amtPd:any;
  date:any;
  yrJn:any;
  // yrTch:any;
  dept:any;
  // subjt:any;
  id:any;

  editList(professor){
    this.emId = professor.employeeId
    this.fNm = professor.firstName
    this.lNm = professor.lastName
    this.gen = professor.gender
    this.email = professor.email
    this.addr = professor.address
    this.numbr = professor.contactNumber
    this.desgn = professor.designation
    this.saly = professor.totalSalary
    this.date = professor.dob
    this.yrJn = professor.yearJoined
    // this.yrTch = professor.yearToTeach
    this.dept = professor.department
    // this.subjt = professor.subjects
    this.id = professor.id
  }
  updateProf(){
    let updt = {
      employeeId:this.emId,
      firstName: this.fNm,
      lastName: this.lNm,
      contactNumber: this.numbr,
      email: this.email,
      address: this.addr,
      gender: this.gen,
      totalSalary: this.saly,
      yearJoined: this.yrJn,
      // yearToTeach: this.yrTch,
      // subjects: this.subjt,
      department: this.dept,
      dob: this.date,
      designation:this.desgn,
      id:this.id
    }
    console.log("before", updt);
    this.service.updateProfessorList(updt).subscribe((data:any) =>{
      this.ngOnInit();
      console.log("after", data);
    })
  }

  deleteList(id){
    this.service.deleteProfessorList(id).subscribe((data:any) => {
      if(data.success = true){
        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.value) {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
          }
          this.ngOnInit();
        })
        console.log("delete", data);
      }
    })
  }

  fileData;
fileUploadProgress = 0;
// Upload file
selectFile(fileInput:any){
  this.fileData = <File>fileInput.target.files[0];  
  console.log('fileProgress-----------', this.fileData, this.fileData.name);
}
upload(){
this.fileUploadProgress = 0;
			let formData = new FormData();
			formData.append('file', this.fileData);
			console.log('upload File-------------------', this.fileData.name);
			this.service.importProfessorList(formData)
				.subscribe((res) => {
					if (res.type == HttpEventType.UploadProgress) {
						this.fileUploadProgress = Math.round(res.loaded / res.total * 100);
            console.log('Upload progress: ', Math.round(res.loaded / res.total * 100), res);
            console.log('Upload Saccess', res);
            
					} else if (res.type === HttpEventType.Response) {
						if (res.body) {
						} else {
						}
          }
          this.ngOnInit();
				});       
      }


  viewProfList:any = [];
  depLst:any=[]
  ngOnInit() {
    this.service.viewProfessorList(this.bId).subscribe((data:any) => {
      this.viewProfList = data.data
      console.log("View_Professors_List", this.viewProfList);
    })

    this.service.viewDepartments().subscribe((data:any) =>{
      this.depLst=data.data
    })
  }
}
