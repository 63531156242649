import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccountsRoutingModule } from './accounts-routing.module';
import { AHomeComponent } from './a-home/a-home.component';
import { StudentFeesComponent } from './student-fees/student-fees.component';
import { EmployeeSalaryComponent } from './employee-salary/employee-salary.component';
import { SchoolExpensesComponent } from './school-expenses/school-expenses.component';
import { StoreExpensesComponent } from './store-expenses/store-expenses.component';
import { MailComponent } from './mail/mail.component';
import { MatInputModule } from '@angular/material';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    AHomeComponent,
    StudentFeesComponent,
    EmployeeSalaryComponent,
    SchoolExpensesComponent,
    StoreExpensesComponent,
    MailComponent
  ],
  imports: [
    CommonModule,
    AccountsRoutingModule,
    MatInputModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class AccountsModule { }
