import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-transport-home',
  templateUrl: './transport-home.component.html',
  styleUrls: ['./transport-home.component.scss']
})
export class TransportHomeComponent implements OnInit {

  constructor(private router:Router) { }

  profileName = localStorage.getItem("userId")

  logOut() {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('busId');
    this.router.navigate(['/']);
  }

  ngOnInit() {
  }

}
