import { Component, OnInit } from '@angular/core';
import { LibrarianService } from '../librarian.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-mail',
  templateUrl: './mail.component.html',
  styleUrls: ['./mail.component.scss']
})
export class MailComponent implements OnInit {

  constructor(
    private lService: LibrarianService
  ) { }

  mesgReceive = false;
  mesgSent = false;

  sender: any;
  receiver: any;
  subject: any;
  message: any;

  sentTo: any;
  receiveFrom: any;
  sentSubject: any;
  sentMessage: any;

  receiveMesg(data) {
    this.mesgReceive = true;
    this.mesgSent = false;

    this.sender = data.sender
    this.receiver = data.receiver
    this.subject = data.subject
    this.message = data.message

  }

  sendMesg(data) {
    this.mesgReceive = false;
    this.mesgSent = true;

    this.sentTo = data.sender
    this.receiveFrom = data.receiver
    this.sentSubject = data.subject
    this.sentMessage = data.message
  }

  sendTo
  sentFrom
  sub
  msg

  id = localStorage.getItem("userId");
  // bid = localStorage.getItem("busId");

  sendMail() {
    let mail = {
      sender: this.sendTo,
      receiver: this.sentFrom,
      subject: this.sub,
      message: this.msg,
      // businessId:this.bid,
      userName: this.id
    }
    this.lService.sendMail(mail).subscribe((data:any) =>  {
      console.log("mail", data)
      alert("Mail Sent Successfully");
      this.ngOnInit();
    });

  }
  // cancel(addFields){
  //   addFields.reset();
  // }
  mailsSent() {
    this.mesgReceive = false;
    this.mesgSent = false;
  }

  mailsReceive() {
    this.mesgReceive = false;
    this.mesgSent = false;

  }


  deleteSentMail(id) {
    // this.lService.mailsDelete(id).subscribe((data:any) => {
    //   console.log("del", data);
    //   this.mailsSent();
    // })
  }

  deleteRecMail(id) {
    // this.lService.mailsDelete(id).subscribe((data:any) => {
    //   console.log("del", data);
    //   this.mailsReceive();
    // })
  }

  receiveMail: any = []
  sentMail: any = []

  ngOnInit() {
    this.lService.mailsSent(this.id).subscribe((data:any) => {
      this.sentMail = data.data.sentBox
      console.log("Library Sent mails", this.sentMail)
      this.sendMesg(this.sentMail);
    });

    this.lService.mailsReceive(this.id).subscribe((data:any) => {
      this.receiveMail = data.data.inbox
      console.log("receive_mails", this.receiveMail)
      this.receiveMesg(this.receiveMail)
      })


  }

}
