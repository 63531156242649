import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';

@Component({
  selector: 'line-chart',
  templateUrl: './my-line-chart.component.html',
  styleUrls: ['./my-line-chart.component.css']
})
export class MyLineChartComponent implements OnInit, OnChanges {

  @Input() Info: {};
  @Input() Datasets: ChartDataSets[];

  public lineChartData: ChartDataSets[] = [
    { data: [60, 66, 60, 69, 60, 65, 65, 70], label: 'Series A' },
    // { data: [55, 59, 90, 71, 46, 45, 50], label: 'Series B' },
    // { data: [45, 49, 100, 61, 36, 35, 30], label: 'Series C' },
    // { data: [35, 39, 110, 51, 26, 25, 60], label: 'Series D' },
  ];
  public lineChartLabels: Label[] = ['1-1', '1-2', '2-1', '2-2', '3-1', '3-2', '4-1','4-2'];
  public lineChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false
  };
  public lineChartColors: Color[] = [
    {
      backgroundColor: 'rgba(11,102,35,0.2)',
      borderColor: 'rgba(30,10,35,1)',
      pointBackgroundColor: 'rgba(11,102,35,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(11,102,35,0.8)'
    },
    { // dark grey
      backgroundColor: 'rgba(77,83,96,0.2)',
      borderColor: 'rgba(77,83,96,0.4)',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    }
  ];
  public lineChartLegend = true;
  public lineChartType: ChartType = 'line';
  public lineChartPlugins = [];

  constructor() { }

  ngOnInit() {
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.Info.currentValue) {
      this.lineChartLabels = changes.Info.currentValue.label;
      this.lineChartData = changes.Info.currentValue.dataset;
      // this.lineChartOptions.scales = changes.Info.currentValue.scales;
      // console.log("linechart",this.lineChartOptions );
    }
    // if(changes.Datasets.currentValue) {
    //   this.lineChartData = changes.Datasets.currentValue
    // }
  }

}
