import { Component, OnInit } from '@angular/core';
import { StudentService } from './student.service';

import { Router } from "@angular/router";
import { FormControl, Validators } from '@angular/forms';
@Component({
  selector: 'app-student',
  templateUrl: './student.component.html',
  styleUrls: ['./student.component.scss']
})
export class StudentComponent implements OnInit {
  [x: string]: any;

  constructor(
    private service: StudentService,
    private router: Router
  ) { }

  
  year = localStorage.getItem("year");
  sem = localStorage.getItem("sem");
  course = localStorage.getItem("course");
  department = localStorage.getItem("department");
  businessId = localStorage.getItem("busId");
  // subjectName = localStorage.getItem("sub");
  id = localStorage.getItem("userId");



  logOut() {
    localStorage.removeItem('token');
    localStorage.removeItem('year');
    localStorage.removeItem('userId');
    localStorage.removeItem('sem');
    localStorage.removeItem('course');
    localStorage.removeItem('department');
    localStorage.removeItem('busId');
    localStorage.removeItem('section');
    this.router.navigate(['/']);
  }
  
  studentId = localStorage.getItem("userId")
  // firstName = this.getprofileName.firstName
  getprofileName
  getprofile
  ngOnInit() {
    this.service.getprofile(this.studentId).subscribe((data: any) => {
      this.getprofileName = data.data.firstName;
      this.getprofile = data.data;      
      localStorage.setItem("year", data.data.year)
      localStorage.setItem("sem", data.data.semester)
      localStorage.setItem("course", data.data.course) 
      localStorage.setItem("department", data.data.department)
      // localStorage.setItem("businessId", data.data.businessId)
      localStorage.setItem("section", data.data.section)
      console.log("getprofileName", this.getprofileName);
    });
  }


}
