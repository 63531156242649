import { Component, OnInit } from '@angular/core';
import { ParentService } from '../parent.service';

@Component({
  selector: 'app-fee-payment',
  templateUrl: './fee-payment.component.html',
  styleUrls: ['./fee-payment.component.scss']
})
export class FeePaymentComponent implements OnInit {

  constructor(private service: ParentService) { }

  ngOnInit() {
  }

}
