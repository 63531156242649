import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from '../../home/main/main.component';
import { LoginComponent } from '../../home/login/login.component';
import { TransportHomeComponent } from './transport-home/transport-home.component';
import { EmailComponent } from './email/email.component';
import { AllocationComponent } from './allocation/allocation.component';
import { BusesComponent } from './buses/buses.component';
import { DriversComponent } from './drivers/drivers.component';
import { ExpensesComponent } from './expenses/expenses.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { StopsComponent } from './stops/stops.component';
import { TrackingComponent } from './tracking/tracking.component';


const routes: Routes = [
  { path:'', component: MainComponent },
  { path:'login', component: LoginComponent },
  { path:'transport', component: TransportHomeComponent , children:[
    { path: 'allocation', component: AllocationComponent},
    { path: 'buses', component: BusesComponent},
    { path: 'drivers', component: DriversComponent},
    { path: 'expenses', component: ExpensesComponent},
    { path: 'feedback', component: FeedbackComponent},
    { path: 'stops', component: StopsComponent},
    { path: 'email', component: EmailComponent},
    { path: 'tracking', component: TrackingComponent}
  ]
}

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TransportRoutingModule { }
