import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { StudentService } from '../student.service';
import { MatPaginatorModule, MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-academics',
  templateUrl: './academics.component.html',
  styleUrls: ['./academics.component.scss']
})
export class AcademicsComponent implements OnInit {

  searchSyl:any;
  searchNot:any;
  searchNotif:any;

  constructor(private service: StudentService) { }

  year = localStorage.getItem("year");
  sem = localStorage.getItem("sem");
  course = localStorage.getItem("course");
  department = localStorage.getItem("department");
  businessId = localStorage.getItem("busId");
  subjectName = localStorage.getItem("sub");
  id = localStorage.getItem("userId");


  subjects: any = []

  // allSubjects() {
  //   let subFields = {
  //     year: this.year,
  //     semester: this.sem,
  //     course: this.course,
  //     department: this.department,
  //     businessId: this.businessId
  //   }

  //   this.service.getAcademicSubjects(subFields).subscribe((totalSubjects: any) => {
  //     this.subjects = totalSubjects.data;
  //     console.log("allSubjects", this.subjects);
  //   })
  // }


  syllabus = false;
  sear = true;
  backBtnFalse = false;

  subJectDetails(subject) {
    this.syllabus = true;
    this.sear = false;
    this.backBtnFalse = true;

    this.service.viewSyllabus(subject).subscribe((syllabus: any) => {
      this.viewLessions = syllabus.data;
      console.log("viewS", this.viewLessions);
    })


    this.service.lectureNotes(subject).subscribe((notes: any) => {
      this.notesLecture = notes.data;
      console.log("viewN", this.notesLecture);
    })
  }
  

  back() {
    this.sear = true;
    this.syllabus = false;
    this.backBtnFalse = false;
  }



  viewLessions = []
  notesLecture = []
  viewNotifications = []

  ngOnInit() {
    // this.allSubjects();    

    this.service.getAcademicSubjects(this.id).subscribe((totalSubjects: any) => {
      this.subjects = totalSubjects.data;
      console.log("allSubjects", this.subjects);
    })

    this.service.viewNotify(this.id).subscribe((notifctn: any) => {
      this.viewNotifications = notifctn.data;
      console.log("notifications", this.viewNotifications);
    })


  }




}
