import { Component, OnInit } from '@angular/core';
import { ProfessorService } from '../professor.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-exams',
  templateUrl: './exams.component.html',
  styleUrls: ['./exams.component.scss']
})
export class ExamsComponent implements OnInit {

  constructor(private pService:ProfessorService) { }

  search:any;
  
  viewExams=true;
  addExam=false;

  course:any;
  department:any;
  year:any;
  semester:any;
  sec:any;
  exName:any;
  subName:any;
  questn:any = [];
  totalMark:any;
  passMark:any;
  date:any;

  cours:any;
  deprt:any;
  years:any;
  semstr:any;
  sectn:any;
  subjtNm:any;
  exmName:any;
  qstn:any;
  tMark:any;
  pMark:any;
  dt:any;


  id = localStorage.getItem("userId")
  bId = localStorage.getItem("busId")

  addExams(){
    this.viewExams=false;
    this.addExam=true;
  }
  back(addFields:NgForm){
    this.viewExams=true;
    this.addExam=false;
      addFields.reset();
  }

  addExms(addFields:NgForm){
    let exm = {
      department:this.department,
      course:this.course,
      year:this.year,
      questions:this.questn,
      semester:this.semester,
      subject:this.subName,
      section:this.sec,
      examName:this.exName,
      totalMarks:this.totalMark,
      passMarks:this.passMark,
      businessId:this.bId,
      date:this.date,
    }
    console.log("before", exm);
    
    this.pService.addExams(exm).subscribe((data : any) =>{
      console.log("Add_Exams", data);
      alert("Submitted");
      addFields.reset();
      this.viewExams=true;
    this.addExam=false;
    this.ngOnInit();

  })
  }

  editExm(data){
  this.cours = data.course
  this.deprt = data.department
  this.years = data.year
  this.semstr = data.semester
  this.sectn = data.section
  this.subjtNm = data.subject
  this.exmName = data.examName
  this.qstn = data.questions
  this.tMark = data.totalMarks
  this.pMark = data.passMarks
  // this.dt = data.course
  }
  
  updateExam(){
    let exam = {
      id : this.id,
      course : this.cours,
      department : this.deprt,
      year : this.years,
      semester : this.semstr,
      section : this.sectn,
      subject : this.subjtNm,
      examName : this.exmName,
      questions : this.qstn,
      totalMarks : this.tMark,
      passMarks : this.pMark,
      businessId :this.bId
    }
    console.log("before", exam);
    
    this.pService.updateExams(exam).subscribe((data:any) => {
      if (data.success = true) {
        console.log("af", data);
        this.cours = data.data.course
        this.deprt = data.data.department
        this.years = data.data.year
        this.semstr = data.data.semester
        this.sectn = data.data.section
        this.subjtNm = data.data.subject
        this.exmName = data.data.examName
        this.qstn = data.data.questions
        this.tMark = data.data.totalMarks
        this.pMark = data.data.passMarks
        this.bId = data.data.businessId
       
        // console.log("Update_Exams", this.cour);
      }
    });
  }
  
  deleteExm(id){
    this.pService.deleteExams(id).subscribe((data : any) =>{
      console.log("Deleted", data)
  })
  }

  viewList=[];
  viewDetails(sub){
    this.pService.viewSubjectByDepartment(sub).subscribe((subj:any) => {
      this.viewList = subj.data
      console.log("View_Subjects_list", this.viewList)
    });
  


    // this.cour="";
    // this.dep="";
    // this.yr="";
    // this.sem="";
    
    // let dropDownData = this.viewList.find((list:any) =>
    // list.subjectName === sub);
  
    // if (dropDownData){
    //   this.course = dropDownData.course;
    //   console.log("view", this.course);
      
      // this.dept = dropDownData.department;
      // this.semst = dropDownData.semester;
      // this.year = dropDownData.year;

    //   if(this.cour){
    //     this.cour=this.course;
    //     // this.dep=this.dept;
    //     // this.yr=this.year;
    //     // this.sem=this.semst;
    //   }
    //   else{
    //     this.cour;
    //   }
    // }
  }



  viewExm:any = [];
  viewSub:any
  ngOnInit() {
    this.pService.viewExams(this.id).subscribe((data : any) =>{
      this.viewExm = data.data
      console.log("Exams_List", this.viewExm)
  })

  this.pService.viewSubjects(this.id).subscribe((sub:any) => {
    this.viewSub = sub.data
    console.log("View_Subjects", this.viewSub)
  });

  
  }

}
