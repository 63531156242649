import { Component, OnInit } from '@angular/core';
import { ProfessorService } from '../professor.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-p-home',
  templateUrl: './p-home.component.html',
  styleUrls: ['./p-home.component.scss']
})
export class PHomeComponent implements OnInit {

  constructor(private pService: ProfessorService, private router: Router) { }


  logOut() {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('busId');
    this.router.navigate(['/']);
  }

  professorId = localStorage.getItem("userId");
  firstName;
  
  ngOnInit() {
    this.pService.getprofile(this.professorId).subscribe((data: any) => {
      this.firstName = data.data.firstName

      // localStorage.setItem("businessId", data.data.businessId)
      console.log("Profile_Name", this.firstName);
    })
  }

}
