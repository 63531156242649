import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { ChartsModule } from 'ng2-charts';
import { FusionChartsModule } from 'angular-fusioncharts';

// Import FusionCharts library and chart modules
import * as FusionCharts from 'fusioncharts';
import * as FusionMaps from 'fusioncharts/fusioncharts.maps';
import * as World from 'fusionmaps/maps/fusioncharts.world';
import * as FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';

import { MyBarChartComponent } from './my-bar-chart/my-bar-chart.component';
import { MyLineChartComponent } from './my-line-chart/my-line-chart.component';
import { MyPieChartComponent } from './my-pie-chart/my-pie-chart.component';




@NgModule({
  declarations: [
    MyBarChartComponent,
    MyLineChartComponent,
    MyPieChartComponent
  ],
  imports: [
    CommonModule,
    ChartsModule,
    FusionChartsModule,
  ],
  exports: [
    MyBarChartComponent,
    MyLineChartComponent,
    MyPieChartComponent
  ]
})
export class GraphModule { }
