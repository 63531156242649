import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from '../../home/main/main.component';
import { LoginComponent } from '../../home/login/login.component';
import { AHomeComponent } from './a-home/a-home.component';
import { EmployeeSalaryComponent } from './employee-salary/employee-salary.component';
import { MailComponent } from './mail/mail.component';
import { SchoolExpensesComponent } from './school-expenses/school-expenses.component';
import { StoreExpensesComponent } from './store-expenses/store-expenses.component';
import { StudentFeesComponent } from './student-fees/student-fees.component';


const routes: Routes = [
  { path:'', component: MainComponent },
  { path:'login', component: LoginComponent },
  { path:'accounts', component: AHomeComponent  , children:[
    { path: 'employee-salary', component: EmployeeSalaryComponent},
    { path: 'email', component: MailComponent},
    { path: 'school-expenses', component: SchoolExpensesComponent},
    { path: 'store-expenses', component: StoreExpensesComponent},
    { path: 'student-fee', component: StudentFeesComponent},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountsRoutingModule { }
