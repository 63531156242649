import { Component, OnInit } from '@angular/core';
import { AdminService } from '../admin.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  
  constructor(
    private service: AdminService,

  ) { }
  
  userId =  localStorage.getItem("userId");
  busId = localStorage.getItem("busId");

  externalinfo = {
    label:  ['1-1', '1-2', '2-1', '2-2', '3-1', '3-2', '4-1','4-2'],
    dataset:[
      { data: [0, 0, 0, 0, 0, 0, 0, 0], label: 'External Semester Wise' },
    ]

  };

  internalinfo = {
    label:  ['1-1', '1-2', '2-1', '2-2', '3-1', '3-2', '4-1','4-2'],
    dataset:[
      { data: [0, 0, 0, 0, 0, 0, 0, 0], label: 'Internal Semester Wise' },
    ]

  };
  
  feedbackinfo = {
    label:  ['1-1', '1-2', '2-1', '2-2', '3-1', '3-2', '4-1','4-2'],
    dataset:[
      { data: [0, 0, 0, 0, 0, 0, 0, 0], label: '' },
    ]

  };

  // feedback(){
  //   let bid = {
  //     businessId: this.busId
  //   }
  //   console.log("bsddddddd",bid);
    
  //   this.service.feedback(bid).subscribe((data:any) => {
      
  //     let feedbackGraph = data.data;
  //     console.log("totalfeedbacks", feedbackGraph);
  //     let feedbackdata = {
  //       label:[],
  //       dataset:[
  //         {data:[], label:''}
  //       ]
  //     }
  //     for (let index = 0; index < feedbackGraph.length; index++) {
  //       let label = feedbackGraph[index].hostelFeedbackTotal;
  //       feedbackdata.label.push(label);
  //       let dataset = feedbackGraph[index].hostelFeedbackTotal;
  //       feedbackdata.dataset[0].data.push(dataset);
        
  //     }
  //   })
  // }

  ngOnInit() {    
    // External Result graph APIs
    this.service.getExternalGraph(this.busId).subscribe((data:any) => {
      console.log("exnternal Grapss", data);
      let externalgraph = data.data;
      let externaldata = {
        label:  [],
        dataset:[
          { data: [], label: 'External Semester Wise' },
        ]

      }
      for (let index = 0; index < externalgraph.length; index++) {
        let label = externalgraph[index].year + "-" +  externalgraph[index].semester;
        externaldata.label.push(label);

        let dataset = externalgraph[index].percentage          
        externaldata.dataset[0].data.push(dataset)
      }
      this.externalinfo = externaldata   
    });

    // InternalResults graph
    this.service.getInternalGraph(this.busId).subscribe((data:any) => {
      
      let internalgraph = data.data;
      let internaldata = {
        label:  [],
        dataset:[
          { data: [], label: 'internal Semester Wise' },
        ]
      }
      for (let index = 0; index < internalgraph.length; index++) {
        let label = internalgraph[index].year + "-" + internalgraph[index].semester;
        internaldata.label.push(label);
        
        let dataset = internalgraph[index].percentage
        internaldata.dataset[0].data.push(dataset);
      }
       this.internalinfo = internaldata;

    })

      // Feed Back Graph API

      this.service.feedback(this.busId).subscribe((data:any) => {
        let feedbackGraph = data.data;
        let feedbackdata = {
          label:[],
          dataset:[
            {data:[], label:''}
          ]
        }
        for (let index = 0; index < feedbackGraph.length; index++) {
          let label = feedbackGraph;
          feedbackdata.label.push(label);
          let dataset = feedbackGraph;
          feedbackdata.dataset[0].data.push(dataset);
        }
        this.feedbackinfo = feedbackdata;
      })
  }


}
