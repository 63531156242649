import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParentComponent } from './parent.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ParentRoutingModule } from './parent-routing.module';
import { AcademicsComponent } from './academics/academics.component';
import { AttendanceComponent } from './attendance/attendance.component';
import { HomeComponent } from './home/home.component';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material';
import { HttpClientModule } from '@angular/common/http';
import { ResultComponent } from './result/result.component';
import { LiveTrackingComponent } from './live-tracking/live-tracking.component';
import { FeePaymentComponent } from './fee-payment/fee-payment.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { GraphModule } from '../../graphs/graph.module';


@NgModule({
  declarations: [
    ParentComponent,
    DashboardComponent,
    AcademicsComponent,
    AttendanceComponent,
    HomeComponent,
    ResultComponent,
    LiveTrackingComponent,
    FeePaymentComponent

  ],

  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    MatInputModule,
    HttpClientModule,
    ParentRoutingModule,
    Ng2SearchPipeModule,
    GraphModule
    
  ]
})
export class ParentModule { }
