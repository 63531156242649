import { Component, OnInit } from '@angular/core';
import { ProfessorService } from '../professor.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss']
})
export class ResultsComponent implements OnInit {

  constructor(private pService: ProfessorService, private router:Router) { }

  result=true;
  internal=false;
  external=false;
  addext=false;
  addInt=false;

  intExam(){
    this.router.navigate(['/professor/results/internals']);   
  }

  extExam(){
    this.router.navigate(['/professor/results/externals']);
  }

  ngOnInit() {
  }

}
