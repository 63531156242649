import { Component, OnInit } from '@angular/core';
import { ProfessorService } from '../professor.service';

@Component({
  selector: 'app-library',
  templateUrl: './library.component.html',
  styleUrls: ['./library.component.scss']
})
export class LibraryComponent implements OnInit {

  constructor(private pService: ProfessorService) { }

  searchBk:any;
  searchAvlBk:any;
  
  books = true;
  availability = false;
  backBtnFalse = false;
  allBtnTrue = true;
  all() {
    this.books = false;
    this.availability = true;
    this.backBtnFalse = true;
    this.allBtnTrue = false;
  }
  avail() {
    this.books = true;
    this.availability = false;
    this.backBtnFalse = false;
    this.allBtnTrue = true;
  }

  libraryBooks = []
  professorBooks = []
  id = localStorage.getItem("userId");
  bId = localStorage.getItem("busId");
  
  ngOnInit() {
    this.pService.getBooks(this.bId).subscribe((books: any) => {
      this.libraryBooks = books.data;
      console.log("books", this.libraryBooks);

    })

    this.pService.bookStatus(this.id).subscribe((stdBooks: any) => {
      this.professorBooks = stdBooks.data;
      console.log("yourbooks", this.professorBooks);
    })
  }

}
