import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from '../../home/main/main.component';
import { ConceptsComponent } from '../../home/concepts/concepts.component';
import { LoginComponent } from '../../home/login/login.component';
import { LibrarianComponent } from './librarian.component';
import { LprofileComponent } from './lprofile/lprofile.component';
import { DueBooksComponent } from './due-books/due-books.component';
import { LendbooksComponent } from './lendbooks/lendbooks.component';
import { LibraryBooksComponent } from './library-books/library-books.component';
import { MailComponent } from './mail/mail.component';


const routes: Routes = [
  { path:'', component: MainComponent },
  { path:'concepts',component: ConceptsComponent},
  { path:'login', component: LoginComponent },
  { path:'librarian', component: LibrarianComponent , children:[
    { path: 'due', component: DueBooksComponent},
    { path: 'lend', component: LendbooksComponent},
    { path: 'books', component: LibraryBooksComponent},
    { path: 'email', component: MailComponent},
  ]
},
  { path:'lprofile', component: LprofileComponent },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LibrarianRoutingModule { }
