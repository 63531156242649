import { Component, OnInit } from '@angular/core';
import { ParentService } from '../parent.service';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss']
})
export class ResultComponent implements OnInit {

  constructor(private service: ParentService) { }

  searchInt:any;
  searchExt:any;
  
  semister = true;
  result = false;
  internal = false;
  external = false;
  btn1 = false;
  btn2 = false;

  backToExam() {
    this.result = true;
    this.semister = false;
    this.internal = false;
    this.external = false;
    this.btn1 = true;

  }
  backToSem() {
    this.internal = false;
    this.semister = true;
    this.btn1 = false;
    this.btn2 = false;
    this.result = false;
  }

  year = localStorage.getItem("studentYear");
  dep = localStorage.getItem("studentDep");
  sem = localStorage.getItem("studentSem");
  id = localStorage.getItem("studentId");

  semResults: any = []

  semIntExtRes() {
    this.service.semisterWise(this.id).subscribe((data: any) => {
      this.semResults = data.data;
      console.log("semisterWise", this.semResults);
    })
    this.semister = false;
    this.result = true;
    this.btn1 = true;  
    this.internal = false;
    this.external = false;
  }

  intResults: any = [];
  extResults: any = [];

  sliptest(exam) {
    let datap = {
      studentId: this.id,
      year: this.year,
      semester: this.sem,
      internalName: exam,
      externalName: exam
    }
    console.log("dddd", datap);
    if(exam=="EXTERNAL"){
      this.service.externals(datap).subscribe((extrnl: any) => {
        this.extResults = extrnl.data;
        console.log("externals", this.extResults);
        this.btn1= false;
        this.external = true;
        this.internal = false;
        this.result = false;
        this.btn2 = true;
      })

    }else{
      this.service.internals(datap).subscribe((result: any) => {
        this.intResults = result.data;
        console.log("sliptestResults", this.intResults);
        this.btn2 = true;
        this.internal = true;
        this.external = false;
        this.result = false;
        this.btn1 = false;
      })

    }
  }
  ngOnInit() {
  }
}
