import { Component, OnInit } from '@angular/core';
import { LibrarianService } from '../librarian.service';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2'
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-library-books',
  templateUrl: './library-books.component.html',
  styleUrls: ['./library-books.component.scss'],
  providers: [
    DatePipe
  ]
})
export class LibraryBooksComponent implements OnInit {

  search:any;
  
  constructor(
    private lService:LibrarianService,
    private datePipe: DatePipe
  ) { }

  
  view=true;
  addBooks=false;

  add(){
    this.view=false;
    this.addBooks=true;
  }
  back(addFields:NgForm){
    this.view=true;
    this.addBooks=false;
    addFields.reset();
  }

  authorName:any;
  bkid:any;
  bknme:any;
  publisedyear:any;
  location:any;
  cource:any;
  entryDate:any;
  pages:any;
  price:any;
  lbsid = localStorage.getItem("busId")

  formatedDate:any;
  
  onSubmit(){
    this.formatedDate =  this.datePipe.transform(this.entryDate, "dd/MM/yyyy");
   }
  addbook(){
    let add = {
      businessId:this.lbsid,
      bookName:this.bknme,
      location: this.location,
      bookId:this.bkid,
      author:this.authorName,
      publishedYear:this.publisedyear,
      noOfPages:this.pages,
      course:this.cource,
      entryDate:this.formatedDate,
      price:this.price,
      lendBookId:'',
      userName:'',
      materialName:'',
      filePath: '',
      profilePic:'',
      rfidTag: this.bkid
    }
  //  console.log("library add book",add);
   this.lService.addBook(add).subscribe((data:any) => {
     if(data.success = true){ 
       console.log("Library Book Added", data);      
       alert("Submited Successfully");
       this.view=true;
       this.addBooks=false;
     this.ngOnInit(); 
     }
     
   })
  }

  completefields(addFields:NgForm){
    addFields.reset();
  }
  cancel(addFields:NgForm){
    addFields.reset();
  }
  
  lbookName:any;
  lbookId:any;
  lauthor:any;
  publishedYear:any;
  nofpages:any;
  lprice:any;
  lbookRackfilePath:any;
  editBook(data){
    this.lbookName = data.bookName
    this.lbookId = data.bookId
    this.lauthor = data.author
    this.publishedYear = data.publishedYear
    this.nofpages = data.noOFPages
    this.lprice = data.price
    this.lbookRackfilePath = data.filePath
    console.log("libraty editBooks",data);    
  }

  updateLibraryBook(){
     let lbooks = {        
            bookName: this.lbookName,
            bookId:this.lbookId,
            author:this.lauthor,
            publishedYear:this.publishedYear,
            filePath:this.lbookRackfilePath,
            noOfPages:this.nofpages,
            price:this.lprice
     }
     console.log("befor update book",lbooks);
     
      this.lService.updateLibraryBooks(lbooks).subscribe(data => {
        console.log("updated bookssss",data);
        
      })

  }
  
  deleteBook(id){    
      this.lService.deleteLibraryBook(id).subscribe((data : any) =>{
          console.log("delete Library Book", data);
    })
  }


   totalLibraryBooks:any = []
  ngOnInit() {
    this.lService.getAllLibraryBooks(this.lbsid).subscribe((data:any) => {
      if(data.success=true){
        this.totalLibraryBooks = data.data;
        console.log("Total Library Books",this.totalLibraryBooks);        
      }
    })
  }

}
