import { Component, OnInit } from '@angular/core';
import { AdminService } from '../admin.service';
import Swal from 'sweetalert2'
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-holidays',
  templateUrl: './holidays.component.html',
  styleUrls: ['./holidays.component.scss']
})
export class HolidaysComponent implements OnInit {

  constructor(private service : AdminService) { }

  search:any;
  
  hName:any;
  fDate:any;
  tDate:any;
  days:any;

  hosName:any;
  fmDate:any;
  toDate:any;
  numDays:any;

  add=false;
  viewHolidays=true;

  addHoli(){
    this.add = true;
    this.viewHolidays = false;
  }
  back(){
    this.add = false;
    this.viewHolidays = true;
  }

  
  bId = localStorage.getItem("busId")
  viewHoli:any=[];
  id;

  addHolidays(){
    let holidy = {
      toDate:this.tDate,
      fromDate:this.fDate,
      reason:this.hName,
      numberOfDays:this.days,
      businessId:this.bId
    }
    console.log("before", holidy)

    this.service.addHolidays(holidy).subscribe((data:any) => {
      if(data.success = true){
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Submitted Successfully',
          showConfirmButton: false,
          timer: 1500
        })
        
        this.ngOnInit();
        console.log("Add_Holiday", data)
      };
    });    
  }

  completefields(addFields:NgForm){
    this.add = false;
        this.viewHolidays = true;
    addFields.reset();
  }
  
  editHolidy(holidyData){
    this.bId = holidyData.businessId
    this.hosName = holidyData.reason
    this.fmDate = holidyData.fromDate
    this.toDate = holidyData.toDate
    this.numDays = holidyData.numberOfDays
    this.id= holidyData.id
  }

  updateHolidays(){
    let holiday = {
      id : this.id,
      reason : this.hosName,
      fromDate : this.fmDate,
      toDate : this.toDate,
      numberOfDays : this.numDays,
      businessId:this.bId
    }
    console.log("before", holiday);
    
    this.service.updateHoliday(holiday).subscribe((data:any) => {
      console.log("af", data);
      this.ngOnInit();
      
    });
  }

  
  delHolidy(id){
    this.service.deleteHoliday(id).subscribe((delHol : any) => {
      if(delHol.success = true){
        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.value) {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
          }
          this.ngOnInit();
        })
        console.log("Delete_Holiday" , delHol)
      }
    })
  }

  ngOnInit() {
    
    this.service.viewHolidays(this.bId).subscribe((data : any) =>{
      this.viewHoli = data.data
      console.log("View_Holidays", this.viewHoli)
      // this.editHolidy(this.viewHoli)
    });
  }

}
