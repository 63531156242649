import { Component, OnInit } from '@angular/core';
import { ParentService } from '../parent.service';

@Component({
  selector: 'app-attendance',
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.scss']
})
export class AttendanceComponent implements OnInit {

  monthlyAt;
  weeklyAt;
  dayAtnd;
  
  tab = false;
  butn = true;
  tabWeek=false;
  tabDay=false;

  attd(){
    this.tab=true;
    this.butn=false;
  }

  month(){
    this.tabWeek=true;
    this.tab=false;
  }
  week(){
    this.tabWeek=false;
    this.tabDay=true;
  }
  bck(){
    this.tab=false;
    this.butn=true;
  }
  bckTomonth(){
    this.tabWeek=false;
    this.tab=true;
  }
  bckToweek(){
    this.tabDay=false;
    this.tabWeek=true;
  }

  constructor(private service:ParentService){}
  attnd = true;
  gtAtd = false;
  clAtd = false;

  weeklyAtd = false;
  dateAtd = false;

  id = localStorage.getItem("studentId");

  classAtd(){
    this.gtAtd = false;
    this.clAtd = true;
    this.attnd = false;
  }
  backToMonthly(){
    this.gtAtd = true;
    this.dateAtd = false;
    this.weeklyAtd = false;
  }
  backToWeekly(){
    this.gtAtd = false;
    this.dateAtd = false;
    this.weeklyAtd = true;
  }
  backToAtd(){
    this.gtAtd = false;
    this.clAtd = false;
    this.attnd = true;
  }
  monthlyAttendance: any = []
  weeklyAttendance: any =[]

  
  gateAtd(){
    this.gtAtd = true;
    this.clAtd = false;
    this.attnd = false;

    let userId = {
      userName: this.id
  }
  this.service.monthAttnd(userId).subscribe((data:any) => {
    this.monthlyAttendance = data.data;
    console.log("monthly Attendance", this.monthlyAttendance);
  })
  }
  

weekly(month){
  this.attnd = false;
  this.gtAtd = false;
  this.dateAtd = false;
  this.weeklyAtd = true;
  
  let wkAtd = {
    userName: this.id,
    month:month
  }

  this.service.weekAttnd(wkAtd).subscribe((data:any) => {
    this.weeklyAttendance = data.data
    console.log("weekly attendance",this.weeklyAttendance);    
  })
}

date(){
  this.attnd = false;
  this.gtAtd = false;
  this.dateAtd = true;
  this.weeklyAtd = false;
}
  ngOnInit() {
  }
}
