import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constant } from '../../config';

@Injectable({
  providedIn: 'root'
})
export class HostelService {

  constructor(private httpClient : HttpClient) { }

  // Email
  
  // Compose
  sendMail(mail){
    return this.httpClient.post(Constant.API_ORIGIN_URL+ "/email/compose", mail)
  }

  // sent
  mailsSent(id){
    return this.httpClient.get(Constant.API_ORIGIN_URL+ "/email/mails/" + id)
  }

  // inbox
  mailsReceive(id){
    return this.httpClient.get(Constant.API_ORIGIN_URL+ "/email/mails/" + id)
  }
  
  // delete mail
  mailsDelete(id){
    return this.httpClient.delete(Constant.API_ORIGIN_URL+ "/email/deleteMail/" + id)
  }

  // Hostel List
  viewHostelList(){
    return this.httpClient.get(Constant.API_ORIGIN_URL+ '/hostel/viewHostelBuildings')
  }
  addHostelList(data){
    return this.httpClient.post(Constant.API_ORIGIN_URL+ '/hostel/addHostelBuilding', data)
  }
  updateHostelList(data){
    return this.httpClient.post(Constant.API_ORIGIN_URL+ '/hostel/updateHostelBuilding', data)
  }
  deleteHostelList(id){
    return this.httpClient.delete(Constant.API_ORIGIN_URL+ '/hostel/deleteHostelBuilding/' + id)
  }

  // Hostel students
  viewHostelStudent(id){
    return this.httpClient.get(Constant.API_ORIGIN_URL+ '/hostel/getHostelStudentDetailsByBusinessId/'+ id)
  }
  addHostelStudent(data){
    return this.httpClient.post(Constant.API_ORIGIN_URL+ '/hostel/addHostelStudentDetails', data);
  }
  updateHostelStudent(data){
    return this.httpClient.post(Constant.API_ORIGIN_URL+ '/hostel/updateHostelStudentDetails', data);
  }
  deleteHostelStudent(id){
    return this.httpClient.delete(Constant.API_ORIGIN_URL+ '/hostel/deleteHostelStudentDetails/' + id)
  }

  // Student Allotment
  viewStudentAllotmnt(id){
    return this.httpClient.get(Constant.API_ORIGIN_URL+ '/hostel/getStudentRoomAllotmentByBusinessId/'+ id)
  }
  addStudentAllotmnt(data){
    return this.httpClient.post(Constant.API_ORIGIN_URL+ '/hostel/addStudentToRoom', data);
  }
  updateStudentAllotmnt(data){
    return this.httpClient.post(Constant.API_ORIGIN_URL+ '/hostel/updateStudentToRoom', data);
  }
  deleteStudentAllotmnt(id){
    return this.httpClient.delete(Constant.API_ORIGIN_URL+ '/hostel/deleteStudentToRoom/' + id)
  }

  // Fee Details

  viewFeeDetails(id){
    return this.httpClient.get(Constant.API_ORIGIN_URL+ '/hostel/getFeesDetailsBusinessId/' + id)
  }
  addFeeDetails(data){
    return this.httpClient.post(Constant.API_ORIGIN_URL+ '/hostel/addHostelFeesDetails', data);
  }
  updateFeeDetails(data){
    return this.httpClient.post(Constant.API_ORIGIN_URL+ '/hostel/updateHostelFeesDetails', data);
  }
  deleteFeeDetails(id){
    return this.httpClient.delete(Constant.API_ORIGIN_URL+ '/hostel/deleteHostelFeesDetails/' + id)
  }

  // Outing Pass

  viewOutingPass(id){
    return this.httpClient.get(Constant.API_ORIGIN_URL+ '/hostel/getStudentTrackByBusinessId/' + id)
  }
  addOutingPass(data){
    return this.httpClient.post(Constant.API_ORIGIN_URL+ '/hostel/addHostelStudentTrack', data);
  }
  updateOutingPass(data){
    return this.httpClient.post(Constant.API_ORIGIN_URL+ '/hostel/updateHostelStudentTrack', data);    
  }
  deleteOutingPass(id){
    return this.httpClient.delete(Constant.API_ORIGIN_URL+ '/hostel/deleteHostelStudentTrack/' + id)
  }

  // View Feedback

  viewFeedback(id){
    return this.httpClient.get(Constant.API_ORIGIN_URL+ '/hostel/viewHostelFeedbackByBusinessId/' + id)
  }

  // Disciplinary Rule

  viewRulesList(){
    return this.httpClient.get(Constant.API_ORIGIN_URL+ '/hostel/viewDisciplinaryRules')
  }
  addRules(data){
    return this.httpClient.post(Constant.API_ORIGIN_URL+ '/hostel/addDisciplinaryRules', data);
  }
  updateRules(data){
    return this.httpClient.post(Constant.API_ORIGIN_URL+ '/hostel/updateDisciplinaryRules', data);
  }
  deleteRules(id){
    return this.httpClient.delete(Constant.API_ORIGIN_URL+ '/hostel/deleteDisciplinaryRules/' + id)
  }

  // Fine

  viewFines(){
    return this.httpClient.get(Constant.API_ORIGIN_URL+ '/hostel/viewAllDisciplinaryLogs')
  }
  addFine(data){
    return this.httpClient.post(Constant.API_ORIGIN_URL+ '/hostel/addDisciplinaryLogs', data);
  }
  updateFine(data){
    return this.httpClient.post(Constant.API_ORIGIN_URL+ '/hostel/updateDisciplinaryLogs', data);
  }
  deleteFine(id){
    return this.httpClient.delete(Constant.API_ORIGIN_URL+ '/hostel/deleteDisciplinaryLogs/' + id)
  }
}
