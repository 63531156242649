import { Component, OnInit } from '@angular/core';
import { AdminService } from '../admin.service';
import { NgForm, FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-accountants',
  templateUrl: './accountants.component.html',
  styleUrls: ['./accountants.component.scss']
})
export class AccountantsComponent implements OnInit {

  hide = true;
  hide1 = true;
  
  constructor(private adminService: AdminService) { }

  emailId = new FormControl('', [Validators.required, Validators.email]);
  
  getErrorMessage() {
    return this.emailId.hasError('required') ? 'Enter a valid Email' :
        this.emailId.hasError('email') ? 'Not a valid email' :
            '';
  }
  
  search:any;
  
  viewAcct = true;
  addAcct = false;

  addAccountants() {
    this.addAcct = true;
    this.viewAcct = false;
  }

  backA() {
    this.viewAcct = true;
    this.addAcct = false;
  }

  accountantBusId = localStorage.getItem('busId')

  empId:any;
  fName:any;
  lName:any;
  gender:any;
  adrs:any;
  email:any;
  num:any;
  sal:any;
  amtPaid:any;
  pwd:any;
  cPwd:any;
  yrJn:any;
  date:any;
  des:any;

  addAcctnt(){
    let add = {
      employeeId: this.empId,
      firstName: this.fName,
      lastName: this.lName,
      contactNumber: this.num,
      email: this.email,
      address: this.adrs,
      gender: this.gender,
      totalSalary: this.sal,
      amountPaid: this.amtPaid,
      yearJoined: this.yrJn,
      dob: this.date,
      password: this.pwd,
      confirmPassword: this.cPwd,
      designation: this.des,
      businessId: this.accountantBusId
    }
    console.log("before", add);
    if(this.pwd === this.cPwd){

    this.adminService.addAccountants(add).subscribe((data:any) => {
      if(data.success = true){
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Submitted Successfully',
          showConfirmButton: false,
          timer: 1500
        })
        this.viewAcct = true;
        this.addAcct = false; 
        this.ngOnInit();
        console.log("after" ,data);
      }; 
    })
  }else{
    alert("password not matching")
  }
  }

   // Upload image 

   img:any;
   url = "./assets/images/1.png"
   onselectFile(e){
     // console.log("upload----",e);
     
     if(e.target.files){
       var reader = new FileReader();
       reader.readAsDataURL(e.target.files[0]);
       reader.onload = (e:any) => {
         this.url = e.target.result;
         console.log("Uploaded Image-------->",this.url);
         
       }
     }
   }

  completefields(addFields:NgForm){
    this.viewAcct = true;
    this.addAcct = false;
    addFields.reset();
  }

  // clear(addFields:NgForm){
  //   addFields.reset();
  // }

  emp:any;
  firstN:any;
  lastN:any;
  ema:any;
  gen:any;
  dt:any;
  address:any;
  numbr:any;
  salary:any;
  yearJoin:any;
  desgn:any;
  id:any;

  editAcct(account){
    this.emp = account.employeeId
    this.firstN = account.firstName
    this.lastN = account.lastName
    this.ema = account.email
    this.gen = account.gender
    this.dt = account.dob
    this.address = account.address
    this.numbr = account.contactNumber
    this.salary = account.totalSalary
    this.yearJoin = account.yearJoined
    this.desgn = account.designation
    this.id = account.id
  }

  updateAcctnt(){
    let update = {
      id:this.id,
      employeeId: this.emp,
      firstName: this.firstN,
      lastName: this.lastN,
      contactNumber: this.numbr,
      email: this.ema,
      address: this.address,
      gender: this.gen,
      totalSalary: this.salary,
      yearJoined: this.yearJoin,
      dob: this.dt,
      designation: this.desgn,
      businessId: this.accountantBusId
    }
    console.log("before", update);
    this.adminService.updateAccountants(update).subscribe((data:any) => {
      console.log("after", data);
      this.ngOnInit();
    })
  }

  delAcct(id){
    this.adminService.deleteAccountants(id).subscribe((data:any) =>{
      if(data.success = true){
        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.value) {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
          }
          this.ngOnInit();
        })
        console.log("deleted", data);
      }
    })
  }

  
  
  accountPersons:any = []
  ngOnInit() {
    this.adminService.getAccountants(this.accountantBusId).subscribe((data: any) => {
      this.accountPersons = data.data
      console.log("Total Accountants",  this.accountPersons);      
    });

  }

}
