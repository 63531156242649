import { Injectable } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Constant } from '../../config';

@Injectable({
  providedIn: 'root'
})
export class SuperAdminService {

  constructor(private httpClient: HttpClient) { }

  //colleges API's

  //Add Colleges API
   addCollege(details : any){
     return this.httpClient.post(Constant.API_ORIGIN_URL+ "/superAdmin/addCollege" ,details);
   }

   //get colleges API
   viewColleges(){
     return this.httpClient.get(Constant.API_ORIGIN_URL+ '/superAdmin/viewColleges');
   }

   //edit college details API
   editColleges(data :any){
     return this.httpClient.post(Constant.API_ORIGIN_URL+ '/superAdmin/editCollege' , data);
   }
  
   //delete college API
   deleteColleges(id : any){
     return this.httpClient.delete(Constant.API_ORIGIN_URL+ '/superAdmin/deleteCollege/' +id);
   }

   //get college details by Id API
   getCollegeById(data : any){
       return this.httpClient.post(Constant.API_ORIGIN_URL+ '/superAdmin/getCollegeByBusinessId' , data);
   }



   //School APIs

   //add schools API
   addSchool(data: any){
     return this.httpClient.post(Constant.API_ORIGIN_URL+ '/superAdmin/addSchool' , data);
   }

   //view schools API
   viewSchols(){
     return this.httpClient.get(Constant.API_ORIGIN_URL+ '/superAdmin/viewSchool');
   }

   //edit school API
   editSchool(data : any){
             return this.httpClient.post(Constant.API_ORIGIN_URL+ '/superAdmin/editSchool' , data);
   }

   //delete school API
   deleteSchool(id : any){
     return this.httpClient.delete(Constant.API_ORIGIN_URL+ '/superAdmin/school/' +id);

   }



  //admin APIs

  //add admin API
  addAdmin(data : any){
    return this.httpClient.post(Constant.API_ORIGIN_URL+ '/superAdmin/addAdmin' , data);
  }

  //view admin API
  viewAdmins(){
    return this.httpClient.get(Constant.API_ORIGIN_URL+ '/superAdmin/viewAdmin');
  }

  //edit admin API
  editAdmin(data : any){
       return this.httpClient.post(Constant.API_ORIGIN_URL+ '/superAdmin/editAdmin' ,data);
  }

  //delete admin API
  deleteAdmin(id : any){
    return this.httpClient.delete(Constant.API_ORIGIN_URL+ '/superAdmin/removeAdmin'+ id);
  }

  
  // Email
  
  // Compose
  sendMail(mail){
    return this.httpClient.post(Constant.API_ORIGIN_URL+ "/email/compose", mail)
  }

  // sent
  mailsSent(id){
    return this.httpClient.get(Constant.API_ORIGIN_URL+ "/email/mails/" + id)
  }

  // inbox
  mailsReceive(id){
    return this.httpClient.get(Constant.API_ORIGIN_URL+ "/email/mails/" + id)
  }
  
  // delete mail
  mailsDelete(id){
    return this.httpClient.delete(Constant.API_ORIGIN_URL+ "/email/deleteMail/" + id)
  }



  //Dashboard API's
   
  //External Percentage API
  externalPercentage(){
    return this.httpClient.get(Constant.API_ORIGIN_URL+ '/superAdmin/getCollegeWiseHighestExternalsPercentage');
  }

  //Internal Percentage API
  internalPercentage(){
    return this.httpClient.get(Constant.API_ORIGIN_URL+ '/superAdmin/getCollegeWiseHighestInternalsPercentage');
  }

  // GraphAPS 

  getexternalresult(){
    return this.httpClient.get(Constant.API_ORIGIN_URL+ '/superAdmin/getCollegesExternalsGraphPercentage');
  }
  
  getInternalresult(){
    return this.httpClient.get(Constant.API_ORIGIN_URL+ '/superAdmin/getCollegesInternalsGraphPercentage');
  }
}
