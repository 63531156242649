import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PHomeComponent } from './p-home/p-home.component';
import { AcademicsComponent } from './academics/academics.component';
import { AttendanceComponent } from './attendance/attendance.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { LibraryComponent } from './library/library.component';
import { ResultsComponent } from './results/results.component';
import { EmailComponent } from './email/email.component';
import { ExamsComponent } from './exams/exams.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PRoutingModule } from './p-routing.module';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule } from '@angular/forms';

import { HttpClientModule } from '@angular/common/http';

import { MatInputModule } from '@angular/material';
import { ProfileComponent } from './profile/profile.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { GraphModule } from '../../graphs/graph.module';
import { StudentAttendanceComponent } from './attendance/student-attendance/student-attendance.component';
import { ClassroomAttendanceComponent } from './attendance/classroom-attendance/classroom-attendance.component';
import { InternalsComponent } from './results/internals/internals.component';
import { ExternalsComponent } from './results/externals/externals.component';
import { GateComponent } from './attendance/student-attendance/gate/gate.component';
import { ClassComponent } from './attendance/student-attendance/class/class.component';


@NgModule({
  declarations: [
    PHomeComponent, 
    AcademicsComponent, 
    AttendanceComponent, 
    FeedbackComponent, 
    LibraryComponent, 
    ResultsComponent, 
    EmailComponent, 
    ExamsComponent, 
    DashboardComponent, 
    ProfileComponent, StudentAttendanceComponent, ClassroomAttendanceComponent, InternalsComponent, ExternalsComponent, GateComponent, ClassComponent
  ],
  imports: [
    CommonModule,
    PRoutingModule,
    FormsModule,
    MaterialModule,
    HttpClientModule,
    MatInputModule,
    Ng2SearchPipeModule,
    GraphModule
  ]
})
export class ProfessorModule { }
