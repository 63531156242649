import { Component, OnInit } from '@angular/core';
import { TransportService } from '../transport.service';

@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.scss']
})
export class TrackingComponent implements OnInit {

  constructor(
    private service:TransportService
  ) { }

  viewTracking=true;
  addTrack=false;

  addTracking(){
    this.viewTracking=false;
    this.addTrack=true;
  }
  back(){
    this.viewTracking=true;
    this.addTrack=false;
  }
  search:any;
  
  ngOnInit() {
    
  }

}
