import { Component, OnInit } from '@angular/core';
import { AccountsService } from '../accounts.service';

@Component({
  selector: 'app-school-expenses',
  templateUrl: './school-expenses.component.html',
  styleUrls: ['./school-expenses.component.scss']
})
export class SchoolExpensesComponent implements OnInit {

  constructor(private aService:AccountsService) { }

  viewList=true;
  addList=false;

  addLists(){
    this.viewList=false;
    this.addList=true;
  }
  back(){
    this.viewList=true;
    this.addList=false;
  }

  merchntNm:any;
  invicNm:any;
  serv:any;
  cheqNm:any;
  bill:any;
  chckSts:any;
  dt:any;

  bId = localStorage.getItem('busId')
  
  listAdd(){
    let add = {
      merchantName:this.merchntNm,
      typeOfService:this.serv,
      invoiceNumber:this.invicNm,
      date:this.dt,
      chequeNumber:this.cheqNm,
      bill:this.bill,
      checkStatus:this.chckSts,
      businessId:this.bId
    }
    console.log("before", add);
    this.aService.addSchoolExpenses(add).subscribe((data:any) => {
      console.log("after", data);
    })
  }

  ngOnInit() {
  }

}
