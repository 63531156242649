import { Component, OnInit } from '@angular/core';
import { ProfessorService } from '../professor.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor(private pService: ProfessorService) { }

  professorId = localStorage.getItem("userId")

  highestInternalMarks(){
    
  }
  getProfessorFeedback:any;

  ngOnInit() {

    this.pService.viewProfessorFeedback(this.professorId).subscribe((data:any) => {
      this.getProfessorFeedback = data.data
      console.log("Get_Professor_Details", this.getProfessorFeedback);
      
    });
  }

}
