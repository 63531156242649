import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sales-report',
  templateUrl: './sales-report.component.html',
  styleUrls: ['./sales-report.component.scss']
})
export class SalesReportComponent implements OnInit {

  constructor() { }

  viewSales=true;
  addSale=false;

  
  addSales(){
    this.viewSales=false;
    this.addSale=true;
  }
  back(){
    this.viewSales=true;
    this.addSale=false;
  }

  ngOnInit() {
  }

}
