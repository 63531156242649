import { Component, OnInit } from '@angular/core';
import { TransportService } from '../transport.service';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-allocation',
  templateUrl: './allocation.component.html',
  styleUrls: ['./allocation.component.scss']
})
export class AllocationComponent implements OnInit {

  constructor(
    private service:TransportService
  ) { }
  
  // p: number =1;
  search:any;

  viewAllocate=true;
  addAlloctn=false;

  addAllocate(){
    this.viewAllocate=false;
    this.addAlloctn=true;
  }
  back(addFields:NgForm){
    this.viewAllocate=true;
    this.addAlloctn=false;
    addFields.reset();
  }

  studID
  studName
  busNmbr
  busPasNmbr
  bustyp
  route
  fare
  prtNumbr

  allocteAdd(){
    let add ={
      name:this.studName,
      student_id:this.studID,
      fare:this.fare,
      pass_number:this.busPasNmbr,      
      bus_no:this.busNmbr,
      route:this.route,
      businessId:this.bussId,
      parent_number:this.prtNumbr,
      busType:this.bustyp
    }
    console.log("before", add);
    
    this.service.addStudentAllocate(add).subscribe((data:any) => {
      console.log("after", data);
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Submitted Successfully',
        showConfirmButton: false,
        timer: 1500
      })
      this.viewAllocate=true;
    this.addAlloctn=false;
      this.ngOnInit();
    })
  }

  completefields(addFields:NgForm){
    addFields.reset();
  }

  
  studId
  studNm
  busNm
  busPassNmbr
  busType
  rout
  fre
  prtNum
  id
  bussId = localStorage.getItem("busId")

  editAllocate(data){
    this.studId = data.student_id
    this.studNm = data.name
    this.busNm = data.bus_no
    this.busPassNmbr = data.pass_number
    this.rout = data.route
    this.fre = data.fare
    this.prtNum = data.parent_number
    this.busType = data.busType
    this.id = data.id
}

  updateAllocate(){
    let updt = {
      name:this.studNm,
      student_id:this.studId,
      fare:this.fre,
      pass_number:this.busPassNmbr,      
      bus_no:this.busNm,
      route:this.rout,
      businessId:this.bussId,
      parent_number:this.prtNum,
      busType:this.busType,
      id:this.id
    }
    console.log("before",updt);
    this.service.updateStudentAllocate(updt).subscribe((data:any) => {
      console.log("after", data);
      this.ngOnInit();
    })
    
  }

  deleteAllocate(id){
    this.service.deleteStudentAllocate(id).subscribe(data => {
      alert("Deleted")
      console.log("delete Private buss",data);
      this.ngOnInit();
    })
   }

  viewStudentAllocate:any = [];

  ngOnInit() {
    this.service.getStudentAllocate(this.bussId).subscribe((data:any) => {
      this.viewStudentAllocate = data.data
      console.log("View Allocation list", this.viewStudentAllocate);
    })
  }

}
