import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from '../../home/main/main.component';
import { LoginComponent } from '../../home/login/login.component';
import { CanteenComponent } from './canteen-home/canteen.component';
import { MailComponent } from './mail/mail.component';
import { MenuComponent } from './menu/menu.component';
import { OrdersComponent } from './orders/orders.component';
import { SalesReportComponent } from './sales-report/sales-report.component';


const routes: Routes = [
  { path:'', component: MainComponent },
  { path:'login', component: LoginComponent },
  { path:'canteen', component: CanteenComponent , children:[
    { path: 'menu', component: MenuComponent},
    { path: 'orders', component: OrdersComponent},
    { path: 'sales-report', component: SalesReportComponent},
    { path: 'email', component: MailComponent}
  ]
}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CanteenRoutingModule { }
