import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HodRoutingModule } from './hod-routing.module';

import { MatInputModule } from '@angular/material';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HodHomeComponent } from './hod-home/hod-home.component';
import { StudentListComponent } from './student-list/student-list.component';
import { ProfessorListComponent } from './professor-list/professor-list.component';
import { LibraryComponent } from './library/library.component';
import { ResultsComponent } from './results/results.component';
import { MailComponent } from './mail/mail.component';
import { ExamsComponent } from './exams/exams.component';
import { AttendanceComponent } from './attendance/attendance.component';
import { LeaveComponent } from './leave/leave.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProfileComponent } from './profile/profile.component';
import { GraphModule } from '../../graphs/graph.module';
import { TimetableComponent } from './timetable/timetable.component';


@NgModule({
  declarations: [
    HodHomeComponent, 
    StudentListComponent, 
    ProfessorListComponent, 
    LibraryComponent, 
    ResultsComponent, 
    MailComponent, 
    ExamsComponent, 
    AttendanceComponent, 
    LeaveComponent, 
    DashboardComponent, 
    ProfileComponent, TimetableComponent],

  imports: [
    CommonModule,
    HodRoutingModule,
    MatInputModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    GraphModule
  ]
})
export class HodModule { }
