import { Component, OnInit } from '@angular/core';
import { AdminService } from '../admin.service';
import Swal from 'sweetalert2'
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-staff-mapping',
  templateUrl: './staff-mapping.component.html',
  styleUrls: ['./staff-mapping.component.scss']
})
export class StaffMappingComponent implements OnInit {

  constructor(private adminService : AdminService) { }

  search:any;
  
  viewStaff = true;
  addStaff = false;

  staff(){
    this.addStaff = true;
    this.viewStaff = false;
  }

  backS(){
    this.viewStaff = true;
    this.addStaff = false;
  }

  bId = localStorage.getItem("busId")

  staffId:any;
  yr:any;
  cour:any;
  dep:any;
  sem:any;
  sec:any;
  subName:any;

  addStaffList(){
    let add = {
      employeeId : this.staffId,
      department : this.dep,
      course : this.cour,
      year : this.yr,
      semester : this.sem,
      subject : this.subName,
      section:this.sec,
      businessId:this.bId
    }

    this.adminService.addStaffList(add).subscribe((data : any) => {
      if(data.success = true){
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Submitted Successfully',
          showConfirmButton: false,
          timer: 1500
        })
        this.ngOnInit();
        console.log("add Staff",data);
      }; 
    })
  }

  completefields(addFields:NgForm){
    this.viewStaff = true;
        this.addStaff = false;
    addFields.reset();
  }

  stfId:any;
  year:any;
  course:any;
  dept:any;
  semtr:any;
  sectn:any;
  sub:any;
  id:any;

  editStaff(data){
  this.stfId = data.employeeId 
  this.year = data.year
  this.course = data.course
  this.dept = data.department
  this.semtr = data.semester
  this.sectn = data.section
  this.sub = data.subject
  this.id = data.id
}

updateStaff(){
  let update = {
    id : this.id,
    employeeId : this.stfId,
    department : this.dept,
    course : this.course,
    year : this.year,
    semester : this.semtr,
    subject : this.sub,
    section :this.sectn,
    businessId:this.bId
  }
  console.log("before", update);
  this.adminService.updateStaffList(update).subscribe((editData : any) => {
    console.log("after" , editData);
    this.ngOnInit();    
  })
}

delStaff(id){
  this.adminService.deleteStaffList(id).subscribe((data : any) => {
    if(data.success = true){
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          )
        }
        this.ngOnInit();
      })
      console.log("deleted",data);
    }
  })
}

viewStaffLst:any =[];
viewStaffList(){
  let view = {
    businessId:this.bId
  }
  this.adminService.viewStaffList(view).subscribe((data : any) => {
    this.viewStaffLst = data.data
    console.log("view Staff", this.viewStaffLst);
  })
}

depLst:any=[]
subList:any=[]
viewProfList:any=[]
  ngOnInit() {
    this.viewStaffList();

    this.adminService.viewDepartments().subscribe((data:any) =>{
      this.depLst=data.data
    })

    this.adminService.viewSubjectList(this.bId).subscribe((data : any) =>{
      this.subList = data.data;
  })

  this.adminService.viewProfessorList(this.bId).subscribe((data:any) => {
    this.viewProfList = data.data
  })

  }

}
