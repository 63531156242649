import { Component, OnInit } from '@angular/core';
import { TransportService } from '../transport.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {

  constructor(
    private service:TransportService
  ) { }

  search:any;

  bussId = localStorage.getItem("busId")
  viewFeedback:any =[];
  ngOnInit() {
    this.service.viewTransportFeedback(this.bussId).subscribe((data:any) => {
      this.viewFeedback = data.data
      console.log("view feedback", this.viewFeedback);     
    })
  }

}
