import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AcademicsComponent } from './academics/academics.component';
import { StudentComponent } from './student.component';
import { MainComponent } from '../../home/main/main.component';
import { LoginComponent } from '../../home/login/login.component';
import { ProfileComponent } from './profile/profile.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AttendanceComponent } from './attendance/attendance.component';
import { EmailComponent } from './email/email.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { FeepayComponent } from './feepay/feepay.component';
import { LibraryComponent } from './library/library.component';
import { ResultComponent } from './result/result.component';
import { ClassAttendanceComponent } from './attendance/class-attendance/class-attendance.component';
import { GateAttendanceComponent } from './attendance/gate-attendance/gate-attendance.component';



const routes: Routes = [
  // { path:'',component: StudentComponent },
  { path:'', component: MainComponent },
  { path:'login', component: LoginComponent },
  { path:'profile', component: ProfileComponent },
  { path:'student', component: StudentComponent , children:[
    { path: 'dashboard', component: DashboardComponent},
    { path: 'academics', component: AcademicsComponent},
    { path: 'attendance', component: AttendanceComponent},
    { path: 'attendance/class-attendance', component: ClassAttendanceComponent},
    { path: 'attendance/gate-attendance', component: GateAttendanceComponent},
    { path: 'feedback', component: FeedbackComponent},
    { path: 'email', component: EmailComponent},
    { path: 'feePay', component: FeepayComponent},
    { path: 'library', component: LibraryComponent},
    { path: 'results', component: ResultComponent},
  ]
}

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StudentRoutingModule { }
