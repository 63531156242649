import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from '../../home/main/main.component';
import { LoginComponent } from '../../home/login/login.component';
import { HodHomeComponent } from './hod-home/hod-home.component';
import { ProfileComponent } from '../hod/profile/profile.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AttendanceComponent } from './attendance/attendance.component';
import { MailComponent } from './mail/mail.component';
import { ResultsComponent } from './results/results.component';
import { LibraryComponent } from './library/library.component';
import { ExamsComponent } from './exams/exams.component';
import { LeaveComponent } from './leave/leave.component';
import { StudentListComponent } from './student-list/student-list.component';
import { TimetableComponent } from './timetable/timetable.component';
import { ProfessorListComponent } from './professor-list/professor-list.component';


const routes: Routes = [
  { path:'', component: MainComponent },
  { path:'login', component: LoginComponent },
  { path:'hod', component: HodHomeComponent , children:[
    { path: 'dashboard', component: DashboardComponent},
    { path: 'attendance', component: AttendanceComponent},
    { path: 'email', component: MailComponent},
    { path: 'results', component: ResultsComponent},
    { path: 'library', component: LibraryComponent},
    { path: 'exams', component: ExamsComponent},
    { path: 'leave', component: LeaveComponent},
    { path: 'student-list', component: StudentListComponent},
    { path: 'professor-list', component: ProfessorListComponent},
    { path: 'timetable', component: TimetableComponent}
  ]
},
  { path:'hodProfile', component: ProfileComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HodRoutingModule { }
